import { createSelector } from 'reselect'
import { getQuery, QueryState } from 'redux-saga-requests'

import { UserRecord } from '@monorepo/core/records'
import { FETCH_USER } from './constants'
import { UserType } from '@monorepo/interfaces'

const selectState = getQuery<UserRecord>({
  type: FETCH_USER,
  defaultData: UserRecord(),
})

export const userRecordSelector = createSelector<
  any,
  any,
  QueryState<UserRecord>
>(selectState, ({ data, ...state }) => ({
  ...state,
  data, //: data || User(),
}))

export const userStateSelector = getQuery<UserRecord>({
  type: FETCH_USER,
  defaultData: UserRecord(),
})

export const userSelector = createSelector(
  getQuery<UserRecord>({
    type: FETCH_USER,
    defaultData: UserRecord(),
  }),
  ({ data, ...state }) => ({ ...state, data: data.toJS() as UserType })
)
