import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

import '../../initializers'
import {
  initializeAction,
  useAuthCompability,
} from '@monorepo/core/features/auth'
import { Util } from '@monorepo/hooks'

import { App } from 'App'

function useInit() {
  const dispatch = useDispatch()

  const initAuth = useCallback(
    bindActionCreators(initializeAction, dispatch),
    []
  )

  useEffect(() => {
    initAuth()
  }, [initAuth])
}

export default (props) => {
  useInit()
  useAuthCompability()
  Util.useAutoScrollToTop()
  return <App {...props} />
}
