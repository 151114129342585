import React from 'react'

import { App } from '@monorepo/hooks'
import { VehiclesButton } from './VehiclesButton'
import { DepartamentsButton } from './DepartamentsButton'
import { Navigation } from './Navigation'
import './style.scss'

type PropsType = {
  //
}

export const BottomSection: React.FC<PropsType> = () => {
  const { menu: mainMenu } = App.useMenu('main')
  const { menu: cabinetMenu } = App.useMenu('cabinet')

  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1553775467259 vc_row-has-fill vc-row-960818">
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <VehiclesButton menu={cabinetMenu} />
              <DepartamentsButton />
              <Navigation menu={mainMenu} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
