// import { AxiosInstance } from 'axios'

import { useClient } from '@monorepo/app_module'
import { IUser } from '@monorepo/interfaces'

type FetchUserResponseType = IUser

// class Api {
//   constructor(private readonly client: AxiosInstance) {}

//   async fetchUser() {
//     const { data } = await this.client.get<FetchUserResponseType>('/user')

//     return data
//   }
// }

export function useApi() {
  const client = useClient()
  const api = {
    async fetchUser() {
      const { data } = await client.get<FetchUserResponseType>('/user')

      return data
    },
  }

  return api

  // return new Api(client, isAuthenticated)
}
