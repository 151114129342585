import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import { find, first } from 'lodash'

import { useCatalogsList } from './useCatalogsList'

export function useCatalog() {
  const { name } = useParams<{ name: string }>()
  const { catalogs } = useCatalogsList()
  const dispatch = useDispatch()

  const currentCatalog = find(catalogs, { id: name })

  useEffect(() => {
    if (!currentCatalog) {
      const firstCatalog = first(catalogs)
      if (firstCatalog) {
        dispatch(push(firstCatalog.mountPoint))
      }
    }
  })

  return { currentCatalog }
}
