import { /*List,*/ Record, RecordOf } from 'immutable'
import { isNil } from 'lodash'

import { UserType, UserProfileType } from '@monorepo/interfaces'
// import { makeListNew } from '../CollectionFactory'
import { UserProfileRecord } from './UserProfileRecord'

type UserRecordPropsType = UserType & {}

const defaultValues: UserRecordPropsType = {
  id: undefined,
  profile: UserProfileRecord(),
  isGuest: true,
  isAdmin: false,
  isImpersonated: false,
  roles: [],
}

export type UserRecord = RecordOf<UserRecordPropsType>
// export type UserRecordsCollection = List<UserRecord>

const makeUserRecord: Record.Factory<UserRecordPropsType> = Record(
  defaultValues,
  'UserRecord'
)
// }: Partial<UserType>): Partial<UserRecordPropsType> => ({
type MapperProps = Partial<UserType & UserProfileType>
const mapper = ({ id, isAdmin, isImpersonated, ...props }: MapperProps) => ({
  id,
  isAdmin,
  isImpersonated,
  profile: UserProfileRecord(props),
  isGuest: isNil(id),
  roles: isAdmin ? ['admin'] : [],
})

export const UserRecord = (props: MapperProps = {}): UserRecord =>
  makeUserRecord(mapper(props || {}))

// export const UserRecordsCollection = makeListNew<UserRecord, UserType>(
//   UserRecord
// )
