import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => [
  {
    path: '/goods',
    component: lazyPage(() =>
      import('App/View/Goods').then(({ Brands }) => Brands)
    ),
    exact: true,
  },
  {
    path: '/goods/:brandName',
    component: lazyPage(() =>
      import('App/View/Goods').then(({ Goods }) => Goods)
    ),
    exact: true,
  },
  {
    path: '/goods/:brandName/:number',
    component: lazyPage(() =>
      import('App/View/Goods').then(({ GoodDetails }) => GoodDetails)
    ),
    exact: true,
  },
]
