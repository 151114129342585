import { useCallback, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import {
  postsListSelector,
  clearPostsAction,
  fetchPostsListAction,
} from '@monorepo/core/features/BlogPost'

export function usePostsList(nextFilter: any = {}) {
  const [filter, setFilter] = useState(nextFilter)

  const dispatch = useDispatch()

  const fetchPostsList = useCallback(
    bindActionCreators(fetchPostsListAction, dispatch),
    []
  )

  const clearPosts = useCallback(
    bindActionCreators(clearPostsAction, dispatch),
    []
  )

  const {
    loading,
    data: { items: posts, meta },
  } = useSelector(postsListSelector)

  useEffect(() => {
    !isEqual(nextFilter, filter) && setFilter(nextFilter)
  }, [filter, nextFilter])

  useEffect(() => {
    fetchPostsList(filter)
    return () => {
      clearPosts()
    }
  }, [clearPosts, fetchPostsList, filter])

  return {
    posts,
    meta,
    loading,
  }
}
