import { useClient } from '@monorepo/core/api_module'
import { IOrder, OrderIdType } from '@monorepo/hooks/Order'

interface IRegisterOrderParams {
  orderId: OrderIdType
  returnUrl: string
  failUrl?: string
}

interface IRegisterOrder {
  formUrl: string
  orderId: OrderIdType
}

export function useApi() {
  const client = useClient()

  async function registerOrder(
    order: IOrder,
    returnUrl: string,
    failUrl?: string
  ) {
    const params: IRegisterOrderParams = {
      orderId: order.id,
      returnUrl,
      failUrl,
    }

    try {
      const { data } = await client.post<IRegisterOrder>(
        '/payment/sberbank/register.do',
        null,
        {
          params,
        }
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ?? 'Произошла непредвиденная ошибка'
      )
    }
  }

  return { registerOrder }
}
