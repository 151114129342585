import { CompanyType } from '@monorepo/interfaces'

export const initialState: CompanyType = {
  name: 'Автосеть',
  address: {
    country: 'РФ',
    // region: 'Московская область',
    city: 'г. Москва',
    street: 'Центральный проспект',
    building: 'д. 1',
    // additional: '',
  },
  phones: [
    {
      number: '+7 495 000-00-00',
      name: '',
      isMain: true,
    },
  ],
  socialNetworks: [
    // {
    //   name: 'VK',
    //   icon: 'vk',
    //   link: 'https://vk.com/',
    // },
    // {
    //   name: 'Instagram',
    //   icon: 'instagram',
    //   link: 'https://www.instagram.com/',
    // },
  ],
  messagers: {
    // whatsApp: [],
  },
  email: 'info@autoset.itonix.ru',
  site: 'autoset.itonix.ru',
  businessHours: [
    [8, 18],
    [8, 18],
    [8, 18],
    [8, 18],
    [8, 18],
    [8, 18],
    [8.5, 17],
  ],
  mapPlace: {
    center: { lon: 55.724446, lat: 37.767165 },
    zoom: 17,
    url: 'https://yandex.ru/maps/-/CCQhMBTSoC',
  },
  offices: [
    {
      id: 1,
      isSalesPoint: true,
      name: 'Острогожск',
      address: {
        country: 'РФ',
        // region: 'Московская область',
        city: 'г. Острогожск',
        street: 'ул. Садовая',
        building: 'д. 86',
        // additional: '',
      },
      phones: [
        {
          number: '+7 (920) 450-40-50',
          name: '',
          isMain: true,
        },
      ],
      socialNetworks: [
        // {
        //   name: 'VK',
        //   icon: 'vk',
        //   link: 'https://vk.com/',
        // },
        // {
        //   name: 'Instagram',
        //   icon: 'instagram',
        //   link: 'https://www.instagram.com/',
        // },
      ],
      messagers: {
        // whatsApp: [],
      },
      email: 'info@autoset.itonix.ru',
      businessHours: [
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8.5, 17],
      ],
      mapPlace: {
        center: { lon: 55.724446, lat: 37.767165 },
        zoom: 17,
        url: 'https://yandex.ru/maps/-/CCQhMBTSoC',
      },
    },
    {
      id: 2,
      isSalesPoint: true,
      name: 'Центр',
      address: {
        country: 'РФ',
        // region: 'Московская область',
        city: 'г. Лиски',
        street: 'ул. Коммунистическая',
        building: 'д. 7',
        // additional: '',
      },
      phones: [
        {
          number: '+7 (906) 677-69-91',
          name: '',
          isMain: true,
        },
        {
          number: '+7 (47391) 22-555',
          name: '',
          isMain: false,
        },
      ],
      socialNetworks: [
        // {
        //   name: 'VK',
        //   icon: 'vk',
        //   link: 'https://vk.com/',
        // },
        // {
        //   name: 'Instagram',
        //   icon: 'instagram',
        //   link: 'https://www.instagram.com/',
        // },
      ],
      messagers: {
        // whatsApp: [],
      },
      email: 'info@autoset.itonix.ru',
      businessHours: [
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8, 18],
        [8.5, 17],
      ],
      mapPlace: {
        center: { lon: 55.724446, lat: 37.767165 },
        zoom: 17,
        url: 'https://yandex.ru/maps/-/CCQhMBTSoC',
      },
    },
  ],
}

export default initialState
