import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { OfferType } from '@monorepo/interfaces'
import {
  priceInfoSelector,
  clearPriceInfoAction,
  fetchPriceInfoAction,
} from '@monorepo/core/features/PriceCalculator'

export function useCalculatorInfo(offer?: OfferType) {
  const { data: steps, loading } = useSelector(priceInfoSelector)

  const dispatch = useDispatch()
  const clear = useCallback(
    bindActionCreators(clearPriceInfoAction, dispatch),
    []
  )
  const fetch = useCallback(
    (offer: OfferType) => {
      dispatch(fetchPriceInfoAction({ offer }))
    },
    [dispatch]
  )

  useEffect(() => {
    offer && fetch(offer)
    return () => {
      // clear()
    }
  }, [clear, fetch, offer])

  return { steps, loading, fetch, clear }
}
