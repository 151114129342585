import { createElement } from 'react'
import { Link } from 'react-router-dom'
import { domToReact } from 'html-react-parser'
import attributesToProps from 'html-react-parser/lib/attributes-to-props'

import { isExternalLink } from '@monorepo/core/helpers'
import { parserOptions } from '../../htmlParser'

export default function renderLink(domNode) {
  const { attribs, children } = domNode

  return isExternalLink(attribs.href)
    ? Object.assign(domNode, {
        attribs: {
          ...attribs,
          target: 'blank',
          rel: 'nofollow noopener',
        },
      })
    : createElement(
        Link,
        { ...attributesToProps(attribs), to: attribs.href },
        domToReact(children, parserOptions)
      )
}
