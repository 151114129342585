import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

type OptionsType = {
  readonly client?: AxiosInstance
  readonly baseURL?: string
  readonly token?: string
  readonly refreshToken?: string
  readonly switchUser?: string
  onAuthError?(): void
}

export class Api {
  private readonly _client: AxiosInstance
  private _token?: string
  private _refreshToken?: string
  private _switchUser?: string
  private _refreshRequest: Promise<AxiosResponse> | null
  private _onAuthError?(): void

  constructor(options: OptionsType = {}) {
    this._client = options.client ?? axios.create({ baseURL: options.baseURL })
    this._token = options.token
    this._refreshToken = options.refreshToken
    this._switchUser = options.switchUser
    this._refreshRequest = null
    this._onAuthError = options.onAuthError

    this._client.interceptors.request.use(
      (config) => {
        if (!this._token) {
          return config
        }

        const newConfig = {
          headers: {},
          ...config,
        }

        newConfig.headers.Authorization = `Bearer ${this._token}`

        if (this._switchUser) {
          newConfig.headers['x-switch-user'] = this._switchUser
        }
        return newConfig
      },
      (e) => Promise.reject(e)
    )

    this._client.interceptors.response.use(
      (r) => r,
      async (
        error: AxiosError & { config: AxiosRequestConfig & { retry?: boolean } }
      ) => {
        if (
          !this._refreshToken ||
          error.response?.status !== 401 ||
          error.config?.retry
        ) {
          this._onAuthError?.()
          throw error
        }

        if (!this._refreshRequest) {
          this._refreshRequest = this._client.post('/token/refresh', {
            refresh_token: this._refreshToken,
          })
        }

        const { data } = await this._refreshRequest

        this._token = data?.token
        this._refreshToken = data?.refresh_token

        const newRequest = {
          ...error.config,
          retry: true,
        }

        return this._client(newRequest)
      }
    )
  }

  get client() {
    return this._client
  }

  get isAuthenticated() {
    return Boolean(this._token)
  }

  // async login({ login, password }) {
  //   const { data } = await this.client.post('/auth/login', { login, password })
  //   this.token = data.token
  //   this.refreshToken = data.refreshToken
  // }

  // logout() {
  //   this.token = null
  //   this.refreshToken = null
  // }
}
