import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import {
  FETCH_BRANDS,
  CLEAR_BRANDS,
  FETCH_MODELS,
  CLEAR_MODELS,
} from './constants'
import { CarBrandType, CarModelType } from '@monorepo/interfaces'

type FetchBrandsActionProps = {
  name: string
}
export function fetchBrandsAction({
  name,
}: FetchBrandsActionProps): RequestAction {
  return {
    type: FETCH_BRANDS,
    request: {
      url: '/cars-catalog/brands',
      params: { name },
    },
    meta: {
      cache: 600,
      cacheKey: String(name.toLowerCase()),
      resetOn: [CLEAR_BRANDS],
      getData(_state, action) {
        return action.data as CarBrandType[]
      },
    },
  }
}

export function clearBrandsAction(): Action {
  return {
    type: CLEAR_BRANDS,
  }
}

type FetchModelsActionProps = {
  brand: string
  name: string
}
export function fetchModelsAction({
  brand,
  name,
}: FetchModelsActionProps): RequestAction {
  return {
    type: FETCH_MODELS,
    request: {
      url: '/cars-catalog/models',
      params: { brand, name },
    },
    meta: {
      cache: 600,
      cacheKey: String(`${brand.toLowerCase()}:${name.toLowerCase()}`),
      resetOn: [CLEAR_MODELS, FETCH_BRANDS],
      getData(_state, action) {
        return action.data as CarModelType[]
      },
    },
  }
}

export function clearModelsAction(): Action {
  return {
    type: CLEAR_MODELS,
  }
}
