import * as React from 'react'
import invariant from 'invariant'
import { Form } from 'react-bootstrap'
import { isFunction } from 'lodash'
import {
  useField,
  useFormikContext,
  ErrorMessage,
  FieldConfig,
  FieldInputProps,
  FieldMetaProps,
} from 'formik'

type ChildrenPropsType = {
  field: FieldInputProps<any>
  meta: FieldMetaProps<any>
  // helpers: FieldHelperProps<any>
}

type PropsType = FieldConfig & {
  label?: React.ReactNode
  // isValid?: (props: Omit<FieldProps, 'form'>) => boolean | boolean
  // isInvalid?: ((props: Omit<FieldProps, 'form'>) => boolean) | boolean
  isValid?: (props: ChildrenPropsType) => boolean | boolean
  isInvalid?: ((props: ChildrenPropsType) => boolean) | boolean
}

export const FormGroup: React.FC<PropsType> = ({
  children,
  label,
  isValid,
  isInvalid,
  ...props
}) => {
  const [field, meta /*helpers*/] = useField<any>(props)
  const form = useFormikContext()

  invariant(
    isFunction(children) || React.isValidElement(children),
    'Children must be valid react element or function'
  )
  // const extraProps = {
  //   ...(!isUndefined(isValid) && {
  //     isValid: isFunction(isValid) ? isValid({ field, meta }) : isValid,
  //   }),
  //   ...(!isUndefined(isInvalid) && {
  //     isInvalid: isFunction(isInvalid) ? isInvalid({ field, meta }) : isInvalid,
  //   }),
  // }

  const extraProps = {
    isValid: !!meta.value && meta.touched && !meta.error,
    isInvalid: meta.touched && !!meta.error,
  }

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      {isFunction(children)
        ? children({ field, meta, form })
        : React.cloneElement(children, { ...field, ...extraProps })}
      <ErrorMessage name={field.name}>
        {(msg: React.ReactNode) => (
          <Form.Control.Feedback
            type="invalid"
            className="d-block p-0"
            style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
    </>
  )
}
