import React from 'react'
import Paginate, { ReactPaginateProps } from 'react-paginate'
import { isMobile } from 'react-device-detect'

import { IPagination } from '@monorepo/interfaces'
import { usePagination } from './usePaginator'

type PaginationPropsType = Partial<ReactPaginateProps> & {
  meta: IPagination
}

export const Pagination: React.FC<PaginationPropsType> = ({
  marginPagesDisplayed,
  pageRangeDisplayed,
  meta,
  breakLabel,
  ...props
}) => {
  const { forcePage, hrefBuilder, onPageChange, pageCount } = usePagination(
    meta
  )

  return (
    <Paginate
      {...props}
      breakLabel={isMobile ? null : breakLabel}
      marginPagesDisplayed={marginPagesDisplayed ?? (isMobile ? 0 : 3)}
      pageRangeDisplayed={pageRangeDisplayed ?? (isMobile ? 2 : 2)}
      forcePage={forcePage}
      hrefBuilder={hrefBuilder}
      onPageChange={onPageChange}
      pageCount={pageCount}
    />
  )
}
