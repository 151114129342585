export type PickPointId = number

export interface IPickPoint {
  id: PickPointId
  externalId: string
  name: string
  address?: string
  coords?: {
    lon: number
    lat: number
  }
  images?: { url: string }[]
}

export * from './delivery-statistics.interface'
