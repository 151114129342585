import { MODULE as PARENT } from '../constants'

export const KEY = 'cart'
export const MODULE = [...[PARENT], KEY]

export const FETCH_CART = `${MODULE.join('/')}-fetch`
export const POPULATE_CART = `${MODULE.join('/')}-populate`
export const CLEAR_CART = `${MODULE.join('/')}-clear`
export const ADD_TO_CART = `${MODULE.join('/')}-add`
export const FETCH_OFFERS = `${MODULE.join('/')}-offers`
export const CLEAR_OFFERS = `${MODULE.join('/')}-offers-clear`
export const UPDATE_CART_ITEM = `${MODULE.join('/')}-update-cart-item`
export const REMOVE_CART_ITEM = `${MODULE.join('/')}-remove-cart-item`
export const CREATE_ORDER = `${MODULE.join('/')}-order-create`
