import * as yup from 'yup'

export const getResetPasswordSchema = () => {
  return yup.object().shape({
    plainPassword: yup.object().shape({
      first: yup
        .string()
        .label('Новый пароль')
        .min(6)
        .matches(/[0-9a-zA-Z]/)
        .required(),
      second: yup
        .string()
        .label('Подтверждение нового пароля')
        .sameAs(yup.ref('first'), 'Пароли должны совпадать'),
    }),
  })
}
