import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { get } from 'lodash'

import { MODULE, FETCH_OFFERS } from './constants'
// import { CartItemType } from '@monorepo/interfaces'
// import { CartItemsCollection, OffersCollection } from 'packages/core/records'
import {
  CartItemRecordsCollection,
  OfferRecordsCollection,
} from 'packages/core/records'
import { CartItemType } from '@monorepo/interfaces'

// export const cartSelector = getQuery<Array<CartItemType>>({
//   requestSelector: (state: any) => {
//     const { data, ...rest } = get(state, MODULE, {})

//     return {
//       ...rest,
//       data: (data as CartItemsCollection).toJS(),
//     }
//   },
// })

export const cartOffersSelector = createSelector(
  getQuery<OfferRecordsCollection>({
    type: FETCH_OFFERS,
    defaultData: OfferRecordsCollection(),
  }),
  ({ data, ...rest }) => ({
    data: data.toList().toJS(),
    ...rest,
  })
)

export const cartSelector = createSelector(
  getQuery<CartItemRecordsCollection>({
    requestSelector: (state: any) => get(state, MODULE, {}),
  }),
  getQuery<OfferRecordsCollection>({
    type: FETCH_OFFERS,
    defaultData: OfferRecordsCollection(),
  }),
  ({ data, ...rest }, { data: cartOffers }) => {
    return {
      ...rest,
      data: data
        .map((cartItem) => {
          const offer = cartOffers.find(
            // cartOffer => cartOffer.itemKey === cartItem!.itemKey,
            (cartOffer) => {
              return (
                cartOffer.good.id === cartItem!.good!.id &&
                cartOffer!.vendor!.id === cartItem!.vendor!.id
              )
            },
            null
          )
          return cartItem.set(
            'offer',
            offer ? offer.toJS() : null
            // cartOffers!.find(
            //   // cartOffer => cartOffer.itemKey === cartItem!.itemKey,
            //   cartOffer => {
            //     return (
            //       cartOffer.good.id === cartItem!.good!.id &&
            //       cartOffer!.vendor!.id === cartItem!.vendor!.id
            //     )
            //   },
            //   null
            // )
          )
        })
        .toJS() as CartItemType[],
    }
  }
)
