import React from 'react'
import cn from 'classnames'

import { SearchField as BaseSearchField } from 'App/Components'

import style from './style.module.scss'

type PropsType = {
  //
}

export const SearchField: React.FC<PropsType> = () => (
  <div
    className={cn(
      style.container,
      'header-woo-search-form'
      // 'hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left'
    )}
  >
    <div className="dgwt-wcas-search-wrapp dgwt-wcas-no-submit woocommerce">
      <BaseSearchField />
    </div>
  </div>
)
