import { List, Record, RecordOf } from 'immutable'
import { isEmpty } from 'lodash'

import { BrandType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'

export type BrandRecordPropsType = BrandType & {}

const defaultValues: BrandRecordPropsType = {
  id: undefined,
  name: '',
  description: '',
  catalogLink: undefined,
  site: undefined,
  images: [],
  country: '',
  priority: undefined,
  warranty: undefined,
  warrantyDescription: undefined,
}

export type BrandRecord = RecordOf<BrandRecordPropsType>
export type BrandRecordsCollection = List<BrandRecord>

const makeBrandRecord: Record.Factory<BrandRecordPropsType> = Record(
  defaultValues,
  'BrandRecord'
)

const mapper = ({
  site,
  ...props
}: Partial<BrandType>): Partial<BrandRecordPropsType> => ({
  site: isEmpty(site)
    ? undefined
    : /https?:\/\//i.test(site!)
    ? site
    : `http://${site}`,
  ...props,
})

export const BrandRecord = (props: Partial<BrandType> = {}): BrandRecord =>
  makeBrandRecord(mapper(props))

export const BrandRecordsCollection = makeListNew<BrandRecord, BrandType>(
  BrandRecord
)
