import React from 'react'
import { chain } from 'lodash'
import { LinkContainer } from 'react-router-bootstrap'

import { CompositButton } from 'App/Components'

type ErrorLayoutProps = {
  code: number | string
  title: React.ReactNode
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  code,
  title,
  children,
}) => (
  <div className="error404">
    {}
    <div id="et-content" className="content et-clearfix padding-true">
      <div className="container et-clearfix">
        <div className="message404 et-clearfix">
          <h1 className="error404-default-title">
            {chain(String(code))
              .split('')
              .reverse()
              .tail()
              .reverse()
              .join('')
              .value()}
            <span className="transform-error">
              {chain(String(code)).split('').last().value()}
            </span>
          </h1>
          <p className="error404-default-subtitle">{title}</p>
          <div className="error404-default-description">{children}</div>
          <LinkContainer to="/">
            <CompositButton
              className="error404-button et-button large"
              title="Перейти на главную"
              onClick={() => {
                if (code >= 500) {
                  window.location.href = '/'
                }
              }}
            >
              Перейти на главную
            </CompositButton>
          </LinkContainer>
        </div>
      </div>
    </div>
  </div>
)
