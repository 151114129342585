import React, { useContext } from 'react'

import { useClient as useBaseClient } from './useClient'
import { ApiContext } from './api.context'

export const ApiProvider: React.FC = ({ children }) => {
  const { client } = useBaseClient()

  return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>
}

export function useClient() {
  return useContext(ApiContext)
}
