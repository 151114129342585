import { useCallback, useEffect } from 'react'
import { TOKEN, REFRESH_TOKEN } from './constants'
import { initializeAction, logoutAction } from './actions'
import { useDispatch } from 'react-redux'

export function useAuthCompability() {
  const dispatch = useDispatch()
  const handler = useCallback(
    (event: StorageEvent) => {
      if (event.key && [TOKEN, REFRESH_TOKEN].includes(event.key)) {
        const value = event.newValue ? JSON.parse(event.newValue) : undefined
        const action = Boolean(value) ? initializeAction() : logoutAction()

        dispatch(action)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    window?.addEventListener('storage', handler, false)

    return () => {
      window?.removeEventListener('storage', handler)
    }
  }, [handler])
}
