import { useClient } from '@monorepo/core/api_module'
import { IOrder, OrderIdType } from './interfaces'

export function useApi() {
  const client = useClient()

  async function fetchOrderById(orderId: OrderIdType) {
    const { data } = await client.get<IOrder>(`/orders/show/${orderId}`)
    return data
  }

  return { fetchOrderById }
}
