import React from 'react'

import { Cart } from '@monorepo/hooks'
import { Link } from 'App/Components'

type CartButtonProps = {
  //
}

export const CartButton: React.FC<CartButtonProps> = () => {
  const { totalItems } = Cart.useCart()
  return (
    <div
      id="header-icon-91751"
      className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-large"
    >
      <Link
        to="/cart"
        className={`hbe-toggle hicon enova ien-ecart-${
          totalItems > 0 ? 9 : 11
        }`}
      />
    </div>
  )
}
