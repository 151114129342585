import { get, isNil, isEqual } from 'lodash'
import url from 'url'

export function isExternalLink(href: string = ''): boolean {
  const link = url.parse(url.format(href))

  return (
    typeof window === 'undefined' ||
    (!isNil(link.host) &&
      (!isEqual(link.protocol, get(window, 'location.protocol')) ||
        !isEqual(link.host, get(window, 'location.host'))))
  )
}

// export default isExternalLink
