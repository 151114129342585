// import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { FETCH_PAGE } from './constants'
import { PageBlockRecord, BlockStateRecord } from './records'

export const getBlockSelector = createSelector(
  getQuery<BlockStateRecord>({
    type: FETCH_PAGE,
    defaultData: PageBlockRecord(),
  }),
  ({ data, ...state }) =>
    memoize((slug?: string) => ({
      ...state,
      data: slug ? data.get(slug)?.toJS() : undefined,
    }))
)
