import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'

import { GoodType } from '@monorepo/interfaces'
import { SuggestionItem } from './SuggestionItem'

// import style from './style.module.scss'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  loading: boolean
  // open: boolean
  query: string
  suggestions: Array<GoodType>
  handleSelect(suggestion: GoodType): void
}

export const SuggetionsList: React.FC<PropsType> = ({
  className,
  loading,
  // open,
  query,
  suggestions,
  handleSelect,
}) => {
  const intl = useIntl()

  return (
    <div
      className={cn(
        className,
        'd-flex flex-column d-shadow'
        // 'dgwt-wcas-suggestions-wrapp~ woocommerce~'
        // style.container,
        // { [style.close]: !open },
      )}
      unselectable="on"
    >
      <span className="dgwt-wcas-st py-2 px-3">
        {loading ? (
          <>
            Поиск <strong>«{query.toUpperCase()}»</strong>...
          </>
        ) : suggestions.length > 0 ? (
          intl.formatMessage(
            { id: 'search.offers_amount' },
            { amount: suggestions.length }
          )
        ) : query.trim().length >= 2 ? (
          <>
            По запросу <strong>«{query.toUpperCase()}»</strong> ничего не
            найдено
          </>
        ) : (
          <>Что ищем?</>
        )}
      </span>

      {suggestions.length > 0 && (
        <div className="m-2 overflow-auto">
          {suggestions.map((item) => (
            <SuggestionItem
              key={item.id}
              item={item}
              handleSelect={handleSelect.bind(null, item)}
            />
          ))}
        </div>
      )}
    </div>
  )
}
