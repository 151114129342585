import { Record, RecordOf } from 'immutable'

export type MapProps = {
  center?: Array<number>
  zoom?: number
  link?: string
}
export type Map = RecordOf<MapProps>

const defaultValues: MapProps = {
  center: undefined,
  zoom: undefined,
  link: undefined,
}

const mapper = (props: MapProps): MapProps => ({
  ...props,
})

const makeMap: Record.Factory<MapProps> = Record(defaultValues, 'MapRecord')

export const Map = (props?: MapProps): Map =>
  makeMap(props ? mapper(props) : undefined)
