import React from 'react'
// import { Image, ImageProps } from '@monorepo/components/common'

import imagePlaceholder from 'App/Resources/images/placeholder-300x300.png'

type ImagePlaceholderPropsType = React.ImgHTMLAttributes<HTMLImageElement> & {
  //
}
// type ImagePlaceholderPropsType = Omit<ImageProps, 'src'> & {
//   //
// }

export const ImagePlaceholder: React.FC<ImagePlaceholderPropsType> = (
  props
) => <img {...props} src={imagePlaceholder} alt="" />
