import WebFont from 'webfontloader'

import '@monorepo/core/utils/jquery-loader'

WebFont.load({
  google: {
    families: [
      'Montserrat:100,200,300,400,500,600,700,800,900,100italic,200italic,300italic,400italic,500italic,600italic,700italic,800italic,900italic',
    ],
  },
})

export * from './Layout'
export * from './Page'
