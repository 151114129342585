import { useCallback, useMemo, useState } from 'react'
import { isBefore, isValid, startOfToday, parseISO, subMonths } from 'date-fns'
import {
  useQueryClient,
  useMutation,
  useQuery /*queryCache*/,
} from 'react-query'

import { App, useQueryState } from '@monorepo/hooks'
import { FetchReconciliationsFilterType } from '../interfaces'

import { ReconciliationType } from './useDetails'

import { ContractType, PaginationMetaType } from '@monorepo/interfaces'
import { useReconciliationsApi } from '../api'

export function useOperations(contract?: ContractType) {
  const { currentContract } = App.useContract()
  const { state: filterState } = useQueryState<FetchReconciliationsFilterType>({
    contractNumber: contract
      ? contract.number
      : currentContract
      ? currentContract.number
      : '',
    // contractNumber: currentContract?.number,
    startDate: '',
    endDate: '',
    page: '1',
    perPage: '10',
    sortOrder: 'DESC',
    // startDate: '2019-01-01T00:00:00.000Z',
    // endDate: formatISO(startOfToday()),
  })

  const [meta, setMeta] = useState<PaginationMetaType>()

  const filter = useMemo(() => {
    const {
      // contractNumber,
      // page,
      startDate: startDateString,
      endDate: endDateString,
      ...rest
    } = filterState

    const endDate = isValid(parseISO(endDateString))
      ? parseISO(endDateString)
      : startOfToday()

    const startDate = isValid(parseISO(startDateString))
      ? parseISO(startDateString)
      : undefined

    return {
      // ...rest,
      // contractNumber,
      // page,
      startDate:
        startDate && isBefore(startDate, endDate)
          ? startDate
          : subMonths(endDate, 6),
      endDate,
      ...rest,
    } as any
  }, [filterState])

  const { fetchList } = useReconciliationsApi()

  const fetcher = async (
    // _endPoint: string,
    filter: FetchReconciliationsFilterType
  ) => {
    const { items, ...meta } = await fetchList(/*endPoint, */ filter)
    setMeta(meta)

    return items
    // return data.map<ReconciliationType>((item) => ({
    //   ...item,
    //   isExpanded: false,
    // }))
  }

  // const endPoint = currentContract
  //   ? `/contracts/${currentContract.number}/show`
  //   : undefined

  const { data = [], isFetching } = useQuery(
    ['fetchOperations', filter],
    () => fetcher(filter),
    {
      enabled: Boolean(currentContract),
      refetchOnWindowFocus: false,
    }
  )
  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    (item: ReconciliationType) => Promise.resolve(item),
    {
      onMutate: (item: ReconciliationType) => {
        const key = ['fetchOperations', filter]

        queryClient.setQueryData(key, (old?: ReconciliationType[]) => {
          const next = old?.map((curr) =>
            curr.id === item.id
              ? { ...curr, isExpanded: !curr.isExpanded }
              : curr
          )
          return next || []
        })
      },
    }
  )

  const toggleDetailsHandler = useCallback(
    (item: ReconciliationType) => {
      // mutate({ ...item, isExpanded: !item.isExpanded })
      mutate(item)
    },
    [mutate]
  )

  return {
    data,
    meta,
    isFetching,
    filter,
    toggleDetailsHandler,
  }
}
