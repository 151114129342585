import { List, Record, RecordOf } from 'immutable'

import { OrderItemType } from '@monorepo/interfaces'
import { makeListNew } from '../CollectionFactory'
import { GoodRecord } from '@monorepo/core/records'

type OrderItemRecordPropsType = Omit<OrderItemType, 'good'> & {
  good: GoodRecord
}

const defaultValues: OrderItemRecordPropsType = {
  amount: 0,
  good: GoodRecord(),
  itemUid: null,
  price: 0,
  reference: '',
  status: undefined,
}

const mapper = ({
  good,
  ...props
}: OrderItemType): OrderItemRecordPropsType => {
  return {
    good: GoodRecord(good),
    ...props,
  }
}

const makeOrderItem: Record.Factory<OrderItemRecordPropsType> = Record(
  defaultValues,
  'OrderItemRecord'
)

export type OrderItemRecord = RecordOf<OrderItemRecordPropsType>
export type OrderItemRecordsCollection = List<OrderItemRecord>

export const OrderItemRecord = (props: OrderItemType): OrderItemRecord =>
  makeOrderItem(mapper(props))

export const OrderItemRecordsCollection = makeListNew<
  OrderItemRecord,
  OrderItemType
>(OrderItemRecord)
