import React from 'react'
import cn from 'classnames'

import { CartItemType } from '@monorepo/interfaces'
import { CartItem } from './CartItem'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  items: Array<CartItemType>
  onRemove(item: CartItemType): void
}

export const CartItemsList: React.FC<PropsType> = ({
  items,
  onRemove,
  className,
}) => (
  <ul
    className={cn(
      'woocommerce-mini-cart',
      'cart_list',
      'product_list_widget',
      className
    )}
  >
    {items.map((item, idx) => (
      <CartItem key={idx} item={item} onRemove={onRemove.bind(null, item)} />
    ))}
  </ul>
)
