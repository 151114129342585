import React from 'react'
import cn from 'classnames'

type AccordionTabProps = {
  title: React.ReactNode
  active?: boolean
}

export const AccordionTab: React.FC<AccordionTabProps> = ({
  active = false,
  title,
  children,
}) => (
  <>
    <div className={cn('toggle-title', 'et-clearfix', { active: active })}>
      <h6 className="toggle-title-tag">{title}</h6>
      <span className="toggle-ind" />
    </div>
    <div
      id="brake-disks-pads-1"
      className={cn('toggle-content', 'et-clearfix', { 'd-none': !active })}
    >
      <div
        className="wpb_text_column wpb_content_element "
        data-animation-delay={0}
      >
        <div className="wpb_wrapper">{children}</div>
      </div>
    </div>
  </>
)
