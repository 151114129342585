/* eslint-disable no-template-curly-in-string */
import { CatalogType as BaseCatalogType } from '@monorepo/interfaces'
import { ilcats as ilcatsConfig } from '@monorepo/core/config'

type CategoryName =
  | 'Японские авто'
  | 'Европейские авто'
  | 'Американские авто'
  | 'Корейские авто'
  | 'Китайские авто'

type CatalogType = BaseCatalogType & {
  category?: CategoryName | CategoryName[]
}

const ilcats: CatalogType[] = [
  {
    id: 'ABARTH',
    name: 'ABARTH',
    url: `https://www.ilcats.ru/abarth/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'AFTERMARKET',
    name: 'AFTERMARKET',
    url: `http://aftermarket.autocats.ru.com/pid/86749/clid/${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'ALFA ROMEO',
    name: 'ALFA ROMEO',
    url: `https://www.ilcats.ru/alfa-romeo/?clid=${ilcatsConfig.clientId}`,
    image: 'Alfa-Romeo-logo-2015-1920x1080.png',
    isActive: true,
    category: ['Европейские авто'],
  },
  {
    id: 'ARCTIC-CAT Atvs',
    name: 'ARCTIC-CAT Atvs',
    url: `https://www.ilcats.ru/arctic-cat-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'AUDI',
    name: 'AUDI',
    url: `https://www.ilcats.ru/audi/?clid=${ilcatsConfig.clientId}`,
    image: 'Audi-logo-2009-1920x1080.png',
    isActive: true,
    category: ['Европейские авто'],
  },
  {
    id: 'BAW',
    name: 'BAW',
    url: `https://www.ilcats.ru/baw/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'BMW',
    name: 'BMW',
    url: `https://www.ilcats.ru/bmw/?clid=${ilcatsConfig.clientId}`,
    image: 'BMW-logo-2000-2048x2048.png',
    isActive: true,
    category: ['Европейские авто'],
  },
  {
    id: 'BMW Moto',
    name: 'BMW Moto',
    url: `https://www.ilcats.ru/bmw-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'BMWI',
    name: 'BMWI',
    url: `https://www.ilcats.ru/bmwi/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'BYD',
    name: 'BYD',
    url: `https://www.ilcats.ru/byd/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'CAN-AM Atvs',
    name: 'CAN-AM Atvs',
    url: `https://www.ilcats.ru/can-am-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'CAN-AM Moto',
    name: 'CAN-AM Moto',
    url: `https://www.ilcats.ru/can-am-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'CHERY',
    name: 'CHERY',
    url: `https://www.ilcats.ru/chery/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'CHEVROLET',
    name: 'CHEVROLET',
    url: `https://www.ilcats.ru/chevrolet/?clid=${ilcatsConfig.clientId}`,
    image: 'Chevrolet-logo-2013-2560x1440.png',
    isActive: true,
    category: ['Американские авто'],
  },
  {
    id: 'CHRYSLER',
    name: 'CHRYSLER',
    url: `https://www.ilcats.ru/chrysler/?clid=${ilcatsConfig.clientId}`,
    image: 'Chrysler-logo-2010-1920x1080.png',
    isActive: true,
    category: ['Американские авто'],
  },
  {
    id: 'CITROEN',
    name: 'CITROEN',
    url: `https://www.ilcats.ru/citroen/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'DAF',
    name: 'DAF',
    url: `https://www.ilcats.ru/daf/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'DATSUN',
    name: 'DATSUN',
    url: `https://www.ilcats.ru/datsun/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'DODGE',
    name: 'DODGE',
    url: `https://www.ilcats.ru/dodge/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Американские авто',
  },
  {
    id: 'FAW',
    name: 'FAW',
    url: `https://www.ilcats.ru/faw/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'FIAT',
    name: 'FIAT',
    url: `https://www.ilcats.ru/fiat/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'FIAT Prof',
    name: 'FIAT Prof',
    url: `https://www.ilcats.ru/fiat-prof/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'FORD',
    name: 'FORD',
    url: `https://www.ilcats.ru/ford/?clid=${ilcatsConfig.clientId}`,
    image: 'Ford-logo-2003-1366x768.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'GEELY',
    name: 'GEELY',
    url: `https://www.ilcats.ru/geely/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'GREAT-WALL',
    name: 'GREAT-WALL',
    url: `https://www.ilcats.ru/great-wall/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'HONDA',
    name: 'HONDA',
    url: `https://www.ilcats.ru/honda/?clid=${ilcatsConfig.clientId}`,
    image: 'Honda-logo-1920x1080.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'HONDA Atvs',
    name: 'HONDA Atvs',
    url: `https://www.ilcats.ru/honda-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Японские авто',
  },
  {
    id: 'HONDA Moto',
    name: 'HONDA Moto',
    url: `https://www.ilcats.ru/honda-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Японские авто',
  },
  {
    id: 'HONDA Scooters',
    name: 'HONDA Scooters',
    url: `https://www.ilcats.ru/honda-scooters/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Японские авто',
  },
  {
    id: 'HYUNDAI',
    name: 'HYUNDAI',
    url: `https://www.ilcats.ru/hyundai/?clid=${ilcatsConfig.clientId}`,
    image: 'Hyundai-logo-silver-2560x1440.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'INFINITI',
    name: 'INFINITI',
    url: `https://www.ilcats.ru/infiniti/?clid=${ilcatsConfig.clientId}`,
    image: 'Infiniti-logo-1989-2560x1440.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'ISUZU',
    name: 'ISUZU',
    url: `https://www.ilcats.ru/isuzu/?clid=${ilcatsConfig.clientId}`,
    image: 'Isuzu-logo-1991-3840x2160.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'JAGUAR',
    name: 'JAGUAR',
    url: `https://www.ilcats.ru/jaguar/?clid=${ilcatsConfig.clientId}`,
    image: 'Jaguar-logo-2012-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'JEEP',
    name: 'JEEP',
    url: `https://www.ilcats.ru/jeep/?clid=${ilcatsConfig.clientId}`,
    image: 'Jeep-logo-green-3840x2160.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'KAWASAKI Atvs',
    name: 'KAWASAKI Atvs',
    url: `https://www.ilcats.ru/kawasaki-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Японские авто',
  },
  {
    id: 'KAWASAKI Moto',
    name: 'KAWASAKI Moto',
    url: `https://www.ilcats.ru/kawasaki-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Японские авто',
  },
  {
    id: 'KIA',
    name: 'KIA',
    url: `https://www.ilcats.ru/kia/?clid=${ilcatsConfig.clientId}`,
    image: 'Kia-logo-2560x1440.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'LANCIA',
    name: 'LANCIA',
    url: `https://www.ilcats.ru/lancia/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'LEXUS',
    name: 'LEXUS',
    url: `https://www.ilcats.ru/lexus/?clid=${ilcatsConfig.clientId}`,
    image: 'Lexus-logo-1988-1920x1080.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'LIFAN',
    name: 'LIFAN',
    url: `https://www.ilcats.ru/lifan/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'maintenance',
    name: 'maintenance',
    url: `https://www.ilcats.ru/maintenance/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'MAZDA',
    name: 'MAZDA',
    url: `https://www.ilcats.ru/mazda/?clid=${ilcatsConfig.clientId}`,
    image: 'Mazda-logo-1997-1920x1080.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'MERCEDES',
    name: 'MERCEDES',
    url: `https://www.ilcats.ru/mercedes/?clid=${ilcatsConfig.clientId}`,
    image: 'Mercedes-Benz-logo-2011-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'MINI',
    name: 'MINI',
    url: `https://www.ilcats.ru/mini/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'MITSUBISHI',
    name: 'MITSUBISHI',
    url: `https://www.ilcats.ru/mitsubishi/?clid=${ilcatsConfig.clientId}`,
    image: 'Mitsubishi-logo-2000x2500.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'NISSAN',
    name: 'NISSAN',
    url: `https://www.ilcats.ru/nissan/?clid=${ilcatsConfig.clientId}`,
    image: 'Nissan-logo-2013-1440x900.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'OPEL',
    name: 'OPEL',
    url: `https://www.ilcats.ru/opel/?clid=${ilcatsConfig.clientId}`,
    image: 'Opel-logo-2009-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'PEUGEOT',
    name: 'PEUGEOT',
    url: `https://www.ilcats.ru/peugeot/?clid=${ilcatsConfig.clientId}`,
    image: 'Peugeot-logo-2010-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'PLYMUTH',
    name: 'PLYMUTH',
    url: `https://www.ilcats.ru/plymouth/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Американские авто',
  },
  {
    id: 'POLARIS Atvs',
    name: 'POLARIS Atvs',
    url: `https://www.ilcats.ru/polaris-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'PORSCHE',
    name: 'PORSCHE',
    url: `https://www.ilcats.ru/porsche/?clid=${ilcatsConfig.clientId}`,
    image: 'Porsche-logo-2008-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'RENAULT-TRUCKS',
    name: 'RENAULT-TRUCKS',
    url: `https://www.ilcats.ru/renault-trucks/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'ROLLS-ROYCE',
    name: 'ROLLS-ROYCE',
    url: `https://www.ilcats.ru/rolls-royce/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'SAAB',
    name: 'SAAB',
    url: `https://www.ilcats.ru/saab/?clid=${ilcatsConfig.clientId}`,
    image: 'Saab-logo-2013-2000x450.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'SEAT',
    name: 'SEAT',
    url: `https://www.ilcats.ru/seat/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'SKANIA',
    name: 'SKANIA',
    url: `https://www.ilcats.ru/skania/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Корейские авто',
  },
  {
    id: 'SKODA',
    name: 'SKODA',
    url: `https://www.ilcats.ru/skoda/?clid=${ilcatsConfig.clientId}`,
    image: 'Skoda-logo-2016-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'SMART',
    name: 'SMART',
    url: `https://www.ilcats.ru/smart/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'SSANGYONG',
    name: 'SSANGYONG',
    url: `https://www.ilcats.ru/ssangyong/?clid=${ilcatsConfig.clientId}`,
    image: 'SsangYong-logo-2560x1440.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'SUBARU',
    name: 'SUBARU',
    url: `https://www.ilcats.ru/subaru/?clid=${ilcatsConfig.clientId}`,
    image: 'Subaru-logo-2003-2560x1440.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'SUZUKI',
    name: 'SUZUKI',
    url: `https://www.ilcats.ru/suzuki/?clid=${ilcatsConfig.clientId}`,
    image: 'Suzuki-logo-5000x2500.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'SUZUKI Atvs',
    name: 'SUZUKI Atvs',
    url: `https://www.ilcats.ru/suzuki-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'SUZUKI Moto',
    name: 'SUZUKI Moto',
    url: `https://www.ilcats.ru/suzuki-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'SUZUKI Scooters',
    name: 'SUZUKI Scooters',
    url: `https://www.ilcats.ru/suzuki-scooters/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'TOYOTA',
    name: 'TOYOTA',
    url: `https://www.ilcats.ru/toyota/?clid=${ilcatsConfig.clientId}`,
    image: 'Toyota-logo-1989-2560x1440.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'VAUXHALL',
    name: 'VAUXHALL',
    url: `https://www.ilcats.ru/vauxhall/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'VICTORY Moto',
    name: 'VICTORY Moto',
    url: `https://www.ilcats.ru/victory-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'VOLKSWAGEN',
    name: 'VOLKSWAGEN',
    url: `https://www.ilcats.ru/vw/?clid=${ilcatsConfig.clientId}`,
    image: 'Volkswagen-logo-2015-1920x1080.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'VOLVO-TRUCKS',
    name: 'VOLVO-TRUCKS',
    url: `https://www.ilcats.ru/volvo-trucks/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'VORTEX',
    name: 'VORTEX',
    url: `https://www.ilcats.ru/vortex/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
    category: 'Китайские авто',
  },
  {
    id: 'YAMAHA Atvs',
    name: 'YAMAHA Atvs',
    url: `https://www.ilcats.ru/yamaha-atvs/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'YAMAHA Moto',
    name: 'YAMAHA Moto',
    url: `https://www.ilcats.ru/yamaha-moto/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'YAMAHA Scooters',
    name: 'YAMAHA Scooters',
    url: `https://www.ilcats.ru/yamaha-scooters/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'zinoro',
    name: 'zinoro',
    url: `https://www.ilcats.ru/zinoro/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
  {
    id: 'ZX',
    name: 'ZX',
    url: `https://www.ilcats.ru/zx/?clid=${ilcatsConfig.clientId}`,
    isActive: false,
  },
]

const catalogsParts: CatalogType[] = [
  // {
  //   id: 'tecdoc',
  //   name: 'TecDoc',
  //   url:
  //     'https://aftermarket.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;catalog:pc}',
  //   image: 'https://img.catalogs-parts.com/images/cp_brands/aftermarket.png',
  //   isActive: true,
  // },
  {
    id: 'to',
    name: 'Каталог ТО',
    url:
      'https://to.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/to.png',
    isActive: true,
  },
  {
    id: 'lamps',
    name: 'Автолампы',
    url: 'https://lamps.catalogs-parts.com/#{client:${clientId};lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/lamps.png',
    isActive: true,
  },
  {
    id: 'batteries',
    name: 'Аккумуляторы',
    url:
      'https://batteries.catalogs-parts.com/#{client:${clientId};page:batteries;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/batteries.png',
    isActive: true,
  },
  {
    id: 'tires',
    name: 'Шины',
    url:
      'https://tires.catalogs-parts.com/#{client:${clientId};page:tires;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/tires.png',
    isActive: true,
  },
  {
    id: 'diski',
    name: 'Диски',
    url:
      'https://wheels.catalogs-parts.com/#{client:${clientId};page:wheels;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/wheels.png',
    isActive: true,
  },
  {
    id: 'oils',
    name: 'Масла',
    url:
      'https://oils.catalogs-parts.com/#{client:${clientId};page:oils;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/oils.png',
    isActive: true,
  },
  {
    id: 'bolty-gayki',
    name: 'Болты, Гайки',
    url:
      'https://bolts.catalogs-parts.com/#{client:${clientId};page:bolts;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/bolts.png',
    isActive: true,
  },
  {
    id: 'caps',
    name: 'Колпаки',
    url:
      'https://caps.catalogs-parts.com/#{client:${clientId};page:caps;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/caps.png',
    isActive: true,
  },
  {
    id: 'aksessuary',
    name: 'Аксессуары',
    url:
      'https://accessories.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/accessories.png',
    isActive: true,
  },
]

export const initialData = {
  ilcats,
  catalogsParts,
}
