import React from 'react'
import cn from 'classnames'

import { GoodType } from '@monorepo/interfaces'
import { Link, ImagePlaceholder } from 'App/Components'

import style from './style.module.scss'

type PropsType = {
  item: GoodType
  handleSelect(...args: any): void
}

export const SuggestionItem: React.FC<PropsType> = ({ item, handleSelect }) => (
  <Link
    className={cn(
      style.container,
      'dgwt-wcas-suggestion dgwt-wcas-suggestion-product px-0'
    )}
    to={item.searchLink}
  >
    <span className="dgwt-wcas-st container-fluid d-flex m-0 p-0">
      {item?.thumbs?.[0] ? (
        <img
          src={item.thumbs[0]}
          className={`img-thumbnail mr-2 ${style.image}`}
          height={50}
          width={50}
          alt={item.name}
        />
      ) : (
        <ImagePlaceholder
          className={`img-thumbnail mr-2 ${style.image}`}
          height={50}
          width={50}
        />
      )}
      <span className="dgwt-wcas-st-title text-wrap~">
        <span className="d-flex pb-1">
          <strong className={cn(style.brand, 'py-1 px-2')}>
            {item.brandName}
          </strong>
          <span className={cn(style.number, 'py-1 px-2 text-uppercase')}>
            {item.number}
          </span>
        </span>
        <small>
          <em className="text-wrap">{item.description}</em>
        </small>
      </span>
      {/* <span className="dgwt-wcas-st-title text-wrap">
        <strong className={cn(style.brand, 'py-1 px-2')}>
          {item.brandName}
        </strong>
        <span className={cn(style.number, 'py-1 px-2')}>{item.number}</span>
        <br />
        <small>
          <em>{item.description}</em>
        </small>
      </span> */}
    </span>
  </Link>
)
