// import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'

import { FETCH_CARS } from './constants'
import { DataRecord } from './records'
import { createSelector } from 'reselect'

export const garageSelector = createSelector(
  getQuery<DataRecord>({
    type: FETCH_CARS,
    defaultData: DataRecord(),
  }),
  ({ data, ...state }) => ({ data: data.toJS(), ...state })
)
