import { RequestAction } from 'redux-saga-requests'

import {
  FETCH_POST,
  FETCH_POSTS_LIST,
  FETCH_RECENT_POSTS,
  CLEAR_POSTS,
} from './constants'
import {
  BlogPostRecordsCollection,
  PaginatorRecord,
  BlogPostRecord,
} from '@monorepo/core/records'
import { DataRecord } from './records'

type FetchPostsListActionParamsType = {
  page: number
}
export function fetchPostsListAction(
  params: FetchPostsListActionParamsType
): RequestAction {
  return {
    type: FETCH_POSTS_LIST,
    request: {
      url: '/v2/blog/posts',
      params,
    },
    meta: {
      // resetOn: [CLEAR_POSTS],
      cache: 600,
      cacheKey: String(params.page),
      getData: (_state, action) => {
        const { items, ...meta } = action.data

        return DataRecord({
          items: BlogPostRecordsCollection(items),
          meta: PaginatorRecord(meta),
        })
      },
    },
  }
}

type FetchPostActionParamsType = {
  slug: string
}
export function fetchPostAction({
  slug,
}: FetchPostActionParamsType): RequestAction {
  return {
    type: FETCH_POST,
    request: {
      url: `/v2/blog/posts/${slug}`,
    },
    meta: {
      // resetOn: [CLEAR_POSTS],
      cache: 600,
      cacheKey: String(slug),
      getData: (state, action) => {
        return BlogPostRecord(action.data)
      },
    },
  }
}

type FetchRecentPostsActionParamsType = {
  count?: number
}
export function fetchRecentPostsAction(
  params: FetchRecentPostsActionParamsType
): RequestAction {
  return {
    type: FETCH_RECENT_POSTS,
    request: {
      url: `/v2/blog/recent`,
      params,
    },
    meta: {
      resetOn: [CLEAR_POSTS],
      cache: 600,
      getData: (state, action) => {
        return BlogPostRecordsCollection(action.data)
      },
    },
  }
}

export function clearPostsAction() {
  return {
    type: CLEAR_POSTS,
  }
}
