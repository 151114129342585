import { put, putResolve, takeLatest, delay, select } from 'redux-saga/effects'
import { find, isString, size, trim, toLower } from 'lodash'
import { /*push,*/ LOCATION_CHANGE } from 'connected-react-router'
import qs from 'qs'

import {
  clearGoodsAction,
  fetchGoodsAction,
  setQueryAction,
  SetQueryActionType,
  SelectGoodActionType,
  selectGoodAction,
} from './actions'
import { SET_QUERY, SET_CURRENT } from './constants'
import { stateSelector } from './selectors'

function* handleInput({ meta }: SetQueryActionType) {
  if (isString(meta.query)) {
    const query = trim(meta.query).toLowerCase()
    const { minLength, debounce } = meta

    const { data } = yield select(stateSelector)

    const current = find(
      data.sugestions,
      (good) => toLower(query) === toLower(good.name)
    )

    yield delay(debounce)
    if (size(query) >= minLength && !current) {
      yield put(fetchGoodsAction(query.toLowerCase()))
    } else if (size(query) === 0) {
      yield put(clearGoodsAction())
    }
  }
}

function* handleSelect({ meta }: SelectGoodActionType) {
  const good = meta.current
  if (good) {
    yield put(setQueryAction(good.number))
    // yield put(/*(good*/.searchLink!))
  }
}

function* handleLocationChange({ payload }: any) {
  const {
    pathname,
    search,
    // query: { brandName, number },
  } = payload.location

  const query: { number?: string; brandName?: string } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  if (pathname === '/search' && query.brandName && query.number) {
    if (payload.isFirstRendering) {
      try {
        yield putResolve(fetchGoodsAction(query.number.toLowerCase()))
      } catch (error) {}
    }

    const {
      data: { suggestions },
    } = yield select(stateSelector)

    const current = find(suggestions, (suggestion) => {
      return (
        toLower(suggestion.number) === toLower(query.number) &&
        toLower(suggestion.brandName) === toLower(query.brandName)
      )
    })

    if (current) {
      yield put(selectGoodAction(current))
    }
  } else {
    yield put(setQueryAction(''))
  }
}

export default function* saga() {
  yield takeLatest<any, any>(SET_QUERY, handleInput)
  yield takeLatest<any, any>(SET_CURRENT, handleSelect)
  yield takeLatest<any, any>(LOCATION_CHANGE, handleLocationChange)
}
