import React from 'react'
import YandexMetrika from './YandexMetrika'

export interface MetricsProps {}

const Metrics: React.FC<MetricsProps> = () => (
  <>
    <YandexMetrika />
  </>
)

export default Metrics
