import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => ({
  path: '/search',
  // component: Search,
  // component: lazyPage(() => import('@monorepo/core/containers/Search')),
  component: lazyPage(() =>
    import('App/View/Search').then(({ Search }) => Search)
  ),
  // exact: true,
})
