import React from 'react'
import { Helmet } from 'react-helmet'
import ErrorBoundary from 'react-error-boundary'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query-devtools'
import { debug } from '@monorepo/core/config'

import { Metrics } from '@monorepo/components/common'
import { Layout } from './Layout'
import { Error500 } from 'App/View/Errors'

import './Resources/styles/style.scss'

export const App = ({ routes, location, locale, ...props }) => (
  <>
    <Helmet
      encodeSpecialCharacters
      titleTemplate="%s | АвтоСеть - Интернет-магазин автозапчастей"
      defaultTitle="АвтоСеть - Интернет-магазин автозапчастей"
    >
      <html dir="ltr" lang={locale} amp />
      <meta charSet="utf-8" />
    </Helmet>
    <ErrorBoundary FallbackComponent={Error500}>
      <Layout>
        <ErrorBoundary FallbackComponent={Error500}>
          {renderRoutes(routes, props, { location })}
        </ErrorBoundary>
      </Layout>
    </ErrorBoundary>
    <ToastContainer />
    {!debug && <Metrics />}
    {debug && <ReactQueryDevtools />}
  </>
)
