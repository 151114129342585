import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  cartOffersSelector,
  fetchCartOffersAction,
  clearCartOffersAction,
} from '@monorepo/core/features/cart'

export function useCartOffers() {
  const dispatch = useDispatch()
  const { data: offers, loading } = useSelector(cartOffersSelector)

  const fetchCartOffersHandler = useCallback(
    bindActionCreators(fetchCartOffersAction, dispatch),
    []
  )

  const clearCartOffersHandler = useCallback(
    bindActionCreators(clearCartOffersAction, dispatch),
    []
  )

  useEffect(() => {
    fetchCartOffersHandler()

    return () => {
      clearCartOffersHandler()
    }
  }, [clearCartOffersHandler, fetchCartOffersHandler])

  return { offers, loading }
}
