import { useQuery } from 'react-query'

import { IVendor } from '@monorepo/interfaces'
import { useApi } from '../useApi'

export const useStatistics = (vendor: IVendor, enabled: boolean = false) => {
  const { fetchDeliveryStatistics } = useApi()

  return useQuery(
    ['fetchDeliveryStatistics', vendor],
    () => fetchDeliveryStatistics(vendor),
    {
      refetchOnWindowFocus: false,
      enabled,
      staleTime: 1000 * 10,
    }
  )
}
