import React from 'react'
import ReactImage, { ImgProps } from 'react-image'
import LazyLoad, { LazyLoadProps } from 'react-lazyload'
import ConditionalWrap from 'conditional-wrap'
import { isObject } from 'lodash'

import Loader from './Loader'

export type ImageProps = Omit<ImgProps, 'loader'> & {
  lazy?: boolean | LazyLoadProps
  loader?: boolean
}

export const Image: React.FC<ImageProps> = ({
  loader = true,
  lazy = true,
  children,
  ...props
}) => (
  <ConditionalWrap
    condition={!!lazy}
    wrap={(children: any) => (
      <LazyLoad offset={100} {...(isObject(lazy) && lazy)}>
        {children}
      </LazyLoad>
    )}
  >
    <ReactImage
      loader={
        loader ? (
          <Loader style={props.style} className={props.className} />
        ) : undefined
      }
      {...props}
      // container={children => {
      //   return children
      // }}
    />
    {children}
  </ConditionalWrap>
)
