// import { Record, List } from 'immutable'
// import { uniqueId } from 'lodash'

// import { EntityRecord, CollectionRecord } from '../../utils/CollectionRecord'

// export const CarRecord = EntityRecord(
//   {
//     id: null,
//     description: null,
//     engineModel: null,
//     enginePower: null,
//     engineSize: null,
//     mark: null,
//     model: null,
//     modification: null,
//     productionYear: null,
//     vin: null,
//     isNew: false,
//   },
//   ({ id = uniqueId('car-') } = {}) => ({
//     id,
//   })
// )

// export const ReducerRecord = CollectionRecord(CarRecord)
import invariant from 'invariant'
import { QueryState } from 'redux-saga-requests'
import { Record, RecordOf } from 'immutable'

// import { EntityRecord } from '@monorepo/core/utils/CollectionRecord'
import { CarRecord, CarRecordsCollection } from 'packages/core/records'
import { CarType } from '@monorepo/interfaces'

interface DataType {
  cars: Array<CarType>
}

interface DataRecordType extends Omit<DataType, 'cars'> {
  cars: CarRecordsCollection
}

export const DataRecord = Record<DataRecordType>({
  cars: CarRecordsCollection(),
})

export type DataRecord = RecordOf<DataRecordType>

export type StateType = QueryState<DataRecord>

export function updateCar(
  carsList: CarRecordsCollection,
  carId: string | undefined,
  changes: Partial<CarType>
): CarRecordsCollection {
  invariant(!!carId, 'Car id is not specified')

  const car = carsList.find(car => car.id === carId)

  invariant(
    car && carsList.includes(car),
    `Car with id ${carId} is not found in cars list`
  )

  const idx = carsList.indexOf(car)

  return carsList.update(idx, CarRecord(changes), car => car.merge(changes))
}

export function removeCar(
  carsList: CarRecordsCollection,
  carId: string | undefined
) {
  const idx = carsList.findIndex(car => car.id === carId)

  return idx === -1 ? carsList : carsList.remove(idx)
}
