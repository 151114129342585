// import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'

import { FETCH_POST, FETCH_POSTS_LIST, FETCH_RECENT_POSTS } from './constants'
import { DataRecord, DataType } from './records'
import { createSelector } from 'reselect'
import {
  BlogPostRecord,
  BlogPostRecordsCollection,
} from 'packages/core/records'
import { BlogPostType } from '@monorepo/interfaces'

export const postsListSelector = createSelector(
  getQuery<DataRecord>({
    type: FETCH_POSTS_LIST,
    defaultData: DataRecord(),
  }),
  ({ data, ...state }) => ({ data: data.toJS() as DataType, ...state })
)

export const postSelector = createSelector(
  getQuery<BlogPostRecord>({
    type: FETCH_POST,
    defaultData: BlogPostRecord(),
  }),
  ({ data, ...state }) => ({ data: data.toJS() as BlogPostType, ...state })
)

export const recentPostSelector = createSelector(
  getQuery<BlogPostRecordsCollection>({
    type: FETCH_RECENT_POSTS,
    defaultData: BlogPostRecordsCollection(),
  }),
  ({ data, ...state }) => ({
    data: data.toJS() as Array<BlogPostType>,
    ...state,
  })
)
