import { useQuery } from 'react-query'

import { useApi } from '../useApi'

export function useOfficesList() {
  const { fetchOffices } = useApi()

  return useQuery('fetchOffices', fetchOffices, {
    refetchOnWindowFocus: false,
  })
}
