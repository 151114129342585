import { List, Record, RecordOf } from 'immutable'

import { VendorType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'

export type VendorRecordPropsType = VendorType & {}

const defaultValues: VendorRecordPropsType = {
  id: undefined,
  name: undefined,
}

export type VendorRecord = RecordOf<VendorRecordPropsType>
export type VendorRecordsCollection = List<VendorRecord>

const makeVendorRecord: Record.Factory<VendorRecordPropsType> = Record(
  defaultValues,
  'VendorRecord'
)

const mapper = ({
  ...props
}: Partial<VendorType> = {}): Partial<VendorRecordPropsType> => ({
  ...props,
})

export const VendorRecord = (props: Partial<VendorType> = {}): VendorRecord =>
  makeVendorRecord(mapper(props || {}))

export const VendorRecordsCollection = makeListNew<VendorRecord, VendorType>(
  VendorRecord
)
