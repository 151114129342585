import React from 'react'

type EmailLinkPropsType = Omit<
  React.HTMLProps<HTMLAnchorElement>,
  'href' | 'ref'
> & {
  email: string
}

export const EmailLink: React.FC<EmailLinkPropsType> = ({
  email,
  children,
  ...attributes
}) => (
  <a {...attributes} href={`mailto:${email}`}>
    {children ?? email}
  </a>
)
