import React from 'react'

type WhatsAppLinkPropsType = Omit<
  React.HTMLProps<HTMLAnchorElement>,
  'href'
> & {
  phone: string
}

export const WhatsAppLink: React.FC<WhatsAppLinkPropsType> = ({
  phone,
  title = 'Написать в WhatsApp',
  children,
  ...props
}) => (
  <a
    href={`whatsapp://send?phone=${phone.replace(/\D/g, '')}`}
    title={title}
    {...props}
  >
    {children}
  </a>
)
