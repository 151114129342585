import React from 'react'

import { UserType } from '@monorepo/interfaces'
import { CompositButton } from 'App/Components'
import './style.scss'

type UserMenuProps = {
  user: UserType
  show: boolean
  handleLogout(...args: any): void
}

export const UserMenu: React.FC<UserMenuProps> = ({ user, handleLogout }) => (
  <div className="widget widget_reglog active">
    <span>Привет, {user.profile.firstName}</span>
    <br />
    <div className="my-account-buttons">
      <CompositButton to="/cabinet">Личный кабинет</CompositButton>
      <CompositButton
        to="/account/logout"
        onClick={(e) => {
          e.preventDefault()
          handleLogout()
        }}
      >
        Выйти
      </CompositButton>
    </div>
  </div>
)
