import { List, Record, RecordOf } from 'immutable'
import { uniqueId, startsWith } from 'lodash'

import { CarType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'

export type CarRecordPropsType = CarType & {}

const defaultValues: CarRecordPropsType = {
  id: undefined,
  description: '',
  engineModel: '',
  enginePower: '',
  engineSize: '',
  mark: '',
  model: '',
  modification: '',
  productionYear: undefined,
  // fullName: '',
  vin: '',
  isNew: true,
}
export type CarRecord = RecordOf<CarRecordPropsType>
export type CarRecordsCollection = List<CarRecord>

const makeCarRecord: Record.Factory<CarRecordPropsType> = Record(
  defaultValues,
  'CarRecord'
)

const mapper = ({
  id = uniqueId('car-'),
  ...props
}: Partial<CarType>): Partial<CarRecordPropsType> => ({
  id,
  isNew: startsWith(id, 'car-', 0),
  ...props,
})

export const CarRecord = (props: Partial<CarType> = {}): CarRecord =>
  makeCarRecord(mapper(props))

export const CarRecordsCollection = makeListNew<CarRecord, CarType>(CarRecord)
