import { Record, RecordOf } from 'immutable'
import { chain } from 'lodash'

import { UserProfileType /*, CartItemType*/ } from '@monorepo/interfaces'
import { OfficeRecord } from '../CompanyRecord'

export type UserProfileRecordPropsType = UserProfileType & {}

const defaultValues: UserProfileRecordPropsType = {
  isWholesale: false,
  // isImpersonated: false,
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  fullName: undefined,
  city: undefined,
  address: undefined,
  office: undefined,
  phone: undefined,
  email: undefined,
  markup: 0,
}
export type UserProfileRecord = RecordOf<UserProfileRecordPropsType>

const getFullName = (props: Partial<UserProfileType>): string =>
  chain(props)
    .pick('lastName', 'firstName', 'middleName')
    .filter()
    .join(' ')
    .value()

type MapperProps = Partial<UserProfileType>
const mapper = ({
  office,
  ...props
}: MapperProps): Partial<UserProfileRecordPropsType> => {
  return {
    ...props,
    fullName: getFullName(props),
    office: office ? OfficeRecord(office) : undefined,
  }
}

const makeUserProfile: Record.Factory<UserProfileRecordPropsType> = Record(
  defaultValues,
  'UserProfileRecord'
)

export const UserProfileRecord = (props?: MapperProps): UserProfileRecord =>
  makeUserProfile(props ? mapper(props) : undefined)
