import React from 'react'
import cn from 'classnames'
// import { defaults } from 'lodash'
import { Redirect, useLocation } from 'react-router'

import { AclType } from '@monorepo/interfaces'
import { User } from '@monorepo/hooks'
import { Link } from 'App/Components'
import style from './style.module.scss'

export type PagePropsType = React.HTMLAttributes<HTMLDivElement> & {
  className?: string
  title?: React.ReactNode
  grid?: boolean
  padding?: boolean
  sidebar?: 'left' | 'right' | 'none'
  small?: boolean
  isFetching?: boolean
  topSpace?: boolean
  bottomSpace?: boolean
  acl?: Partial<AclType>
}

export const Page: React.FC<PagePropsType> = ({
  children,
  title,
  grid = false,
  padding = true,
  sidebar = 'none',
  small = false,
  topSpace = false,
  bottomSpace = false,
  acl,
  ...props
}) => {
  const { isDissallow, isReady, isGuest } = User.useAcl(acl)
  const location = useLocation()

  if (isReady && isDissallow) {
    return (
      <Redirect
        to={{
          pathname: isGuest ? '/account/login' : '/',
          state: { referrer: location },
        }}
      />
    )
  }

  return (
    <div {...props}>
      <div
        className={cn(style.container, 'title-section et-clearfix d-shadow')}
      >
        <div className="container et-clearfix">
          <div className="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_column-gap-24 vc-row-606817">
            <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
              <div className="vc_column-inner pt-0">
                <div className="wpb_wrapper">
                  <div
                    className="et-breadcrumbs-container tse text-align-left align-left tablet-align-left mobile-align-left"
                    id="et-breadcrumbs-container-394423"
                  >
                    <div className="et-breadcrumbs">
                      <Link to="/">Главная</Link>
                      {title && <span>{title}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {topSpace && <div className={style.spaceBlock} />}
      <div
        // id="et-content"
        className={cn(
          'content',
          'et-clearfix',
          { 'padding-false': !padding },
          { 'pt-4': padding }
        )}
      >
        <div
          className={cn(`layout-sidebar-${sidebar}`, {
            grid,
            small,
          })}
        >
          {/* <div className="container et-clearfix">{children}</div> */}
          {children}
        </div>
      </div>
      {bottomSpace && <div className={style.spaceBlock} />}
    </div>
  )
}
