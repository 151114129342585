import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'

export type PhoneProps = {
  number: string
  name: string
  isMain: boolean
  isMobile: boolean
}
export type Phone = RecordOf<PhoneProps>
export type PhonesCollection = List<Phone>

const defaultValues: PhoneProps = {
  number: '',
  name: '',
  isMain: false,
  isMobile: false,
}

const mapper = ({ ...props }: PhoneProps): PhoneProps => ({
  ...props,
})

const makePhone: Record.Factory<PhoneProps> = Record(
  defaultValues,
  'PhoneRecord'
)

export const Phone = (props?: PhoneProps): Phone =>
  makePhone(props ? mapper(props) : undefined)
export const PhonesCollection = makeList(Phone)
