import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import {
  SET_QUERY,
  SET_CURRENT,
  FETCH_SUGGESTIONS,
  CLEAR_SUGGESTIONS,
} from './constants'
// import { GoodRecord } from 'core/records-old'
// import { StateRecord } from './records'
// import { GoodsCollection } from '@monorepo/core/records'
import { GoodType } from '@monorepo/interfaces'

export type SetQueryActionType = RequestAction & {
  meta: {
    query: string
    debounce: number
    minLength: number
  }
}

export function setQueryAction(
  query: string,
  {
    debounce = 500,
    minLength = 2,
  }: { debounce?: number; minLength?: number } = {}
): SetQueryActionType {
  return {
    type: SET_QUERY,
    request: false,
    meta: {
      query,
      debounce,
      minLength,
    },
  }
}

export type SelectGoodActionType = RequestAction & {
  meta: {
    current: GoodType
  }
}

export function selectGoodAction(current: GoodType): SelectGoodActionType {
  return {
    type: SET_CURRENT,
    request: false,
    meta: {
      takeLatest: true,
      current,
    },
  }
}

export function fetchGoodsAction(query: string): RequestAction {
  return {
    type: FETCH_SUGGESTIONS,
    request: {
      url: '/search/goods',
      params: {
        number: query,
        strict: true,
      },
    },
    meta: {
      asPromise: true,
      // resetOn: [CLEAR_SUGGESTIONS],
      takeLatest: true,
      cache: 600,
      cacheKey: query,
      // getData: ({ data }, action) => {
      //   return ((data || StateRecord()) as StateRecordType).set(
      //     'suggestions',
      //     GoodsCollection(action.data)
      //   )
      //   // return GoodsCollection(action.data)
      // },
    },
  }
}

export function clearGoodsAction(): Action {
  return {
    type: CLEAR_SUGGESTIONS,
  }
}
