type CatalogType = {
  clientId?: string
  mountPoint?: string
}

const settings: CatalogType = {}

try {
  Object.assign(
    settings,
    JSON.parse(process.env.REACT_APP_CATALOGS_PARTS || '[]')
  )
} catch (error) {}

export const CatalogsParts = settings
