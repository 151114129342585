import { List, Record, RecordOf } from 'immutable'

import { ContractType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'

export type ContractRecordPropsType = ContractType & {}

const defaultValues: ContractRecordPropsType = {
  id: '',
  balance: 0,
  creditLimit: 0,
  defermentPayment: 0,
  overdueDebt: 0,
  currency: undefined,
  isAccounting: false,
  isCurrent: false,
  isForOrder: false,
  isOrderDeny: false,
  isOverdueCreditLimit: false,
  isOverdueDebt: false,
  name: '',
  number: '',
  type: '',
}

export type ContractRecord = RecordOf<ContractRecordPropsType>
export type ContractRecordsCollection = List<ContractRecord>

const makeContractRecord: Record.Factory<ContractRecordPropsType> = Record(
  defaultValues,
  'ContractRecord'
)

const mapper = ({
  ...props
}: Partial<ContractType>): Partial<ContractRecordPropsType> => ({
  ...props,
})

export const ContractRecord = (
  props: Partial<ContractType> = {}
): ContractRecord => makeContractRecord(mapper(props || {}))

export const ContractRecordsCollection = makeListNew<
  ContractRecord,
  ContractType
>(ContractRecord)
