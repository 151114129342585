export function formatDeliveryTime(
  deliveryTime: number,
  emptyValue?: string | number
): string {
  if (deliveryTime === 0 && emptyValue) {
    return String(emptyValue)
  }

  const days: number = Math.floor(deliveryTime / 24)
  const hours: number = deliveryTime % 24

  return [
    days > 0 ? `${days} дн.` : false,
    (days > 0 && hours > 0) || days === 0 ? `${hours} ч.` : false,
  ]
    .filter(Boolean)
    .join(' ')
}
