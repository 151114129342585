import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from 'react-scroll-up'
import { detect } from 'detect-browser'
import cn from 'classnames'

import { Header } from './Header'
import { Footer } from './Footer'

import style from './style.module.scss'

import logoImage from 'App/Resources/images/Autoset_logo_1000_w.png'

export type LayoutProps = {
  //
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const browser = useMemo(detect, [])

  return (
    <>
      <div id="gen-wrap">
        <div
          id="wrap"
          className={cn({
            [`browser-${browser?.name}`]: browser?.name,
          })}
        >
          <Header logoImage={logoImage} />
          <div className="page-content-wrap">
            {children}
            <Footer logoImage={logoImage} />
          </div>
        </div>
        <ScrollToTop showUnder={160} duration={250} style={{ zIndex: 999 }}>
          <FontAwesomeIcon
            size="3x"
            icon={faArrowAltCircleUp}
            className={style.scrollToTopButton}
          />
        </ScrollToTop>
      </div>
    </>
  )
}

// export const withLayout = () => (Component: any) => (props: any) => (
//   <Layout>
//     <Component {...props} />
//   </Layout>
// )
