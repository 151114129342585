import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import {
  brandsSelector,
  clearBrandsAction,
  fetchBrandsAction,
} from '@monorepo/core/features/CarCatalog'

export function useBrands() {
  const { data: brands, loading } = useSelector(brandsSelector)

  const dispatch = useDispatch()

  const fetchBrands = useCallback(
    debounce((name: string) => {
      dispatch(fetchBrandsAction({ name }))
    }, 1000),
    [dispatch]
  )

  const clearBrands = useCallback(
    bindActionCreators(clearBrandsAction, dispatch),
    []
  )

  useEffect(() => {
    return () => {
      clearBrands()
    }
  }, [clearBrands])

  return { fetchBrands, brands, loading }
}
