// import { useDispatch } from 'react-redux'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
// import { push } from 'connected-react-router'
import { chain, isEqual, keys } from 'lodash'
import qs from 'qs'

function convertToTypeOf(typevar: any, input: any) {
  const type = typeof typevar

  switch (type) {
    case 'string':
      return String.bind(null, input)()
    case 'number':
      return Number.bind(null, input)()
    case 'boolean':
      return input === 'true' ? true : false
    default:
      return input
    // throw 'Unsupported type'
  }
}

export function useQueryState<
  T extends {},
  U extends { [key in keyof T]: string } = { [key in keyof T]: string }
>(initialState: U) {
  const [stateKeys] = useState(keys(initialState))

  const location = useLocation()
  const history = useHistory()
  // const dispatch = useDispatch()
  // const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  const getStateFrom = useCallback(
    (params: Object) =>
      chain(params)
        .pick(stateKeys)
        .mapValues((val, key) =>
          convertToTypeOf(initialState[key as keyof T], val)
        )
        .value(),
    [initialState, stateKeys]
  )

  const currentState = useMemo(
    () => getStateFrom(qs.parse(location.search, { ignoreQueryPrefix: true })),
    [getStateFrom, location.search]
  )

  const [state, setState] = useState<U>({
    ...initialState,
    ...currentState,
  })

  const updateState = useCallback(
    (property: string, value: any) => {
      const nextState = {
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        ...state,
        ...getStateFrom({ [property]: value }),
      }

      const url = `${location.pathname}${qs.stringify(nextState, {
        addQueryPrefix: true,
        arrayFormat: 'brackets',
      })}`

      history.push(url, {
        scrollToTop: false,
      })
    },
    [getStateFrom, history, location, state]
  )

  useEffect(() => {
    const nextState = { ...initialState, ...currentState }
    // const nextState = { ...initialState, ...currentState }
    // console.log(isEqual(state, nextState), { state, nextState })
    // updateState(nextState)
    // !isEqual(state, nextState) && setState(nextState)
    !isEqual(state, nextState) && setState(nextState)
  }, [currentState, initialState, state, stateKeys, updateState])

  return { state, updateState }
}
