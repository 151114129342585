import React from 'react'
// import cn from 'classnames'

import { Image, Loader as BaseLoader } from '@monorepo/components/common'
import logoImage from 'App/Resources/images/Autoset_logo_1000_b.png'

type LoaderProps = {
  //
}

export const Loader: React.FC<LoaderProps> = () => (
  <BaseLoader
    size={250}
    borderSize={5}
    image={<Image loader={false} lazy={false} src={logoImage} />}
    // className="m-5"
  />
)
