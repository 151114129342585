import { put, takeEvery } from 'redux-saga/effects'
import { success } from 'redux-saga-requests'

import { CLEAR_USER, FETCH_USER } from '../user/constants'
import { clearContractsAction, populateContractsAction } from './actions'

function* watchClearUser() {
  yield put(clearContractsAction())
}

function* watchSuccessFetchUserRequest(action: any) {
  const items = action.data.contracts
  yield put(populateContractsAction(items))
}

export function* contractSaga() {
  yield takeEvery(CLEAR_USER, watchClearUser)
  yield takeEvery(success(FETCH_USER), watchSuccessFetchUserRequest)
}
