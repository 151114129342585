import { useCallback, useMemo } from 'react'

import { getChangeUserPasswordSchema } from '@monorepo/validations'
import { useUser } from './useUser'
import { UserProfileType } from '@monorepo/interfaces'

type FormValuesType = {
  password: string
  plainPassword: {
    first: string
    second: string
  }
}

export function useChangePasswordForm() {
  const initialValues: FormValuesType = useMemo(
    () => ({
      password: '',
      plainPassword: {
        first: '',
        second: '',
      },
    }),
    []
  )

  const { updateProfileHandler } = useUser()

  const onSubmit = useCallback(
    ({ plainPassword }: FormValuesType) => {
      return updateProfileHandler({ plainPassword } as Partial<UserProfileType>)
    },
    [updateProfileHandler]
  )

  const validationSchema = useMemo(() => getChangeUserPasswordSchema(), [])

  return { validationSchema, initialValues, onSubmit }
}
