/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

import { Link } from 'App/Components'
import { Image } from '@monorepo/components/common'
import { SearchField } from './SearchField'
import { CartMenu } from './CartMenu'
import './style.scss'

type PropsType = {
  logoImage: string
}

export const MiddleSection: React.FC<PropsType> = ({ logoImage }) => (
  <div className="vc_row wpb_row vc_row-fluid vc_custom_1553775461826 vc_row-has-fill hide-sticky vc-row-271378">
    <div className="container et-clearfix">
      <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
        <div className="vc_column-inner ">
          <div className="wpb_wrapper">
            <div id="header-logo-617774" className="hbe header-logo hbe-left">
              <Link to="/">
                <Image className="logo" src={logoImage} alt="" loader={false} />
                <Image
                  className="sticky-logo"
                  src={logoImage}
                  alt=""
                  loader={false}
                />
              </Link>
            </div>
            <CartMenu />
            {/* <div className="header-vertical-separator hbe hide-default-false hide-sticky-false hbe-right solid vertical header-vertical-separator-855577">
              <div className="line" />
            </div> */}
            {/* <div
              id="header-icon-749461"
              className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-custom"
            >
              <Link to="/" className="hbe-toggle hicon auto enova-auto-heart" />
            </div> */}
            {/* <div className="header-vertical-separator hbe hide-default-false hide-sticky-false hbe-right solid vertical header-vertical-separator-855577">
              <div className="line" />
            </div> */}
            {/* <div
              id="header-icon-730164"
              className="wooscp-btn header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-custom"
            >
              <Link
                to="/"
                target="_self"
                className="hbe-toggle hicon auto enova-auto-reload"
              />
            </div> */}
            <SearchField />
            {/* <Search /> */}
          </div>
        </div>
      </div>
    </div>
  </div>
)
