import React from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import cn from 'classnames'
import { delay } from 'lodash'

import 'csshake'

import { UserCredentialsType } from '@monorepo/interfaces'
import { LoginForm } from './LoginForm'

type PropsType = ModalProps & {
  onClose(...args: any): void
  onLogin(credientals: UserCredentialsType): void
  error?: string
}

export const LoginModal: React.FC<PropsType> = ({
  show,
  onClose,
  onLogin,
  error,
}) => {
  const [showError, setShowError] = React.useState<boolean>(false)
  const [shake, setShake] = React.useState<boolean>(false)

  const loginHandler = React.useCallback(
    async (credientals: UserCredentialsType) => {
      try {
        await onLogin(credientals)
        onClose()
      } catch (error) {
        setShake(true)
        delay(setShake, 500, false)
        delay(setShowError, 500, true)
        delay(setShowError, 3500, false)
      }
    },
    [onClose, onLogin]
  )

  return (
    <Modal
      show={show}
      onHide={onClose}
      className={cn({ 'shake-horizontal': shake })}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3">Авторизация</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoginForm
          handleLogin={loginHandler}
          closeHandler={onClose}
          error={showError ? error : undefined}
        />
      </Modal.Body>
      {/* <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
      <Button variant="primary" onClick={onClose}>
        Save Changes
      </Button>
    </Modal.Footer> */}
    </Modal>
  )
}
