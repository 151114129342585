import {
  defaults,
  filter,
  find,
  flow,
  groupBy,
  isEqual,
  isNumber,
  map,
  minBy,
  orderBy,
  partition,
  reject,
  take,
  values,
} from 'lodash/fp'
import { OfferType, GoodType } from '@monorepo/interfaces'
import { DataType } from '../../records'

import { ResultType, OptionsType } from '../../types'
import { createKey } from '../../utils'

type GroupResultType<T = ResultType> = {
  good?: ResultType
  crosses: ResultType[]
  originalCrosses: ResultType[]
}

const splitByCategories = (requestGood: GoodType) => <T extends ResultType>(
  offers: T[]
): GroupResultType<T> => {
  const good = find(['good.id', requestGood.id], offers)
  const [originalCrosses, crosses] = partition(
    ['good.brand.id', requestGood.brand.id],
    reject(['good.id', requestGood.id], offers)
  )

  return {
    good,
    originalCrosses,
    crosses,
  }
}

const defaultOptions: Required<OptionsType> = {
  sortBy: 'price',
  excludeBrands: [],
}

const processor = (
  { good, remote, showState, apiState }: DataType,
  options: OptionsType
) =>
  flow(
    filter<OfferType>((offer) => {
      return !options.excludeBrands.includes(offer.good.brandName)
      // console.log(offer, options)
      // return true
    }),
    orderBy<OfferType>(['isStock', options.sortBy], ['desc', 'asc']),
    groupBy<OfferType>('good.id'),
    (result) => values(result),
    map<OfferType[], ResultType>((allOffers) => {
      const [stockOffers, restOffers] = partition('isStock', allOffers)
      const good = allOffers[0].good
      let offers = stockOffers

      const showMore = showState[good.id] || false
      if (!Boolean(showMore)) {
        const cheaperOffer = minBy(options.sortBy, restOffers)
        const fasterOffer = minBy(
          'deliveryTime',
          reject(isEqual(cheaperOffer), restOffers)
        )
        offers = offers.concat(cheaperOffer || [], fasterOffer || [])
      } else if (isNumber(showMore)) {
        offers = take(showMore as number, offers.concat(restOffers || []))
      } else {
        offers = offers.concat(restOffers)
      }

      const currentApiState = apiState[createKey(good)]

      const isExpanded = Boolean(showMore)
      const totalOffers = allOffers.length
      const isLoading = currentApiState && currentApiState === 'loading'
      const isLoaded = currentApiState && currentApiState === 'loaded'
      const isHasMoreOffers =
        offers.length < totalOffers ||
        isLoading ||
        (!isExpanded && remote.length > 0)

      return {
        good,
        offers,
        meta: {
          isHasMoreOffers,
          isExpanded,
          totalOffers,
          isLoading,
          isLoaded,
        },
      }
    }),
    splitByCategories(good!)
  )

const groupResult = (result: DataType, options: Partial<OptionsType> = {}) => {
  const { good, offers } = result
  const groupOptions = defaults(defaultOptions, options)

  return good ? processor(result, groupOptions)(offers) : []
}

export default groupResult
