import React, { useMemo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import qs from 'qs'

import { Providers } from '../providers'
import { IOrder } from '@monorepo/hooks/Order'

type PaymentProviderProps = {
  order: IOrder
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({ order }) => {
  const [isNewOrder, setNewOrder] = useState(false)
  const history = useHistory()
  const { search, pathname } = useLocation()

  const { newOrder } = qs.parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (newOrder) {
      setNewOrder(true)
      history.replace(pathname)
    }
  }, [history, newOrder, pathname])

  const baseUrl = window.location.origin

  const PayComponent = useMemo(() => {
    const providerName = order?.paymentMethod?.provider

    if (!providerName) {
      return null
    }

    return (Providers as any)?.[providerName]?.Component ?? null
  }, [order])

  const redirectUrl = `/cart/order/${order!.id}`

  return isNewOrder && PayComponent ? (
    <PayComponent
      order={order}
      paymentMethod={order.paymentMethod}
      successUrl={`${baseUrl}/${redirectUrl}?payment=success`}
      failureUrl={`${baseUrl}/${redirectUrl}?payment=failure`}
    />
  ) : null
}
