import { List, Record, RecordOf } from 'immutable'

import { OfferType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'
import { GoodRecord } from './GoodRecord'
import { VendorRecord } from './VendorRecord'

export type OfferRecordPropsType = OfferType & {}

const defaultValues: OfferRecordPropsType = {
  amount: 0,
  amountString: '0',
  cartAmount: 0,
  deliveryTime: undefined,
  expectedAt: undefined,
  good: GoodRecord(),
  isReturnAllowed: true,
  isStock: false,
  stockName: undefined,
  itemKey: '',
  minAmount: 1,
  multiplicityAmount: 1,
  name: '',
  price: 0,
  returnPercent: 0,
  vendor: VendorRecord(),
  quality: undefined,
  images: undefined,
  thumbs: undefined,
}

export type OfferRecord = RecordOf<OfferRecordPropsType>
export type OfferRecordsCollection = List<OfferRecord>

const makeOfferRecord: Record.Factory<OfferRecordPropsType> = Record(
  defaultValues,
  'OfferRecord'
)

const mapper = ({
  good,
  vendor,
  ...props
}: Partial<OfferType>): Partial<OfferRecordPropsType> => ({
  ...props,
  good: GoodRecord(good),
  vendor: VendorRecord(vendor),
})

export const OfferRecord = (props: Partial<OfferType> = {}): OfferRecord =>
  makeOfferRecord(mapper(props))

export const OfferRecordsCollection = makeListNew<OfferRecord, OfferType>(
  OfferRecord
)
