import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => [
  {
    path: '/pages/:slug',
    // component: Post,
    component: lazyPage(() =>
      import('App/View/StaticPage').then(({ StaticPage }) => StaticPage)
    ),
    exact: true,
  },
]
