import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { get, memoize } from 'lodash'

import { MODULE } from './constants'
import { StateType, DataType } from './types'

export const getMenuSelector = createSelector(
  getQuery<DataType>({
    requestSelector: (state: StateType) => get(state, MODULE, {}),
  }),
  ({ data, ...state }) =>
    memoize((name: string) => ({
      ...state,
      data: data[name] ?? [],
    }))
)
