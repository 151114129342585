import * as Yup from 'yup'
import { find } from 'lodash'

import { CartItemSchema } from './CartItemSchema'
import { IDeliveryMethod } from '@monorepo/interfaces'

type GetOrderSchemaProps = {
  deliveryMethods?: IDeliveryMethod[]
}

export const getOrderSchema = ({ deliveryMethods }: GetOrderSchemaProps) =>
  Yup.object().shape({
    paymentMethod: Yup.string().label('Способ оплаты').required(),
    deliveryType: Yup.string().label('Способ доставки').required(),
    deliveryAddress: Yup.string()
      .label('Адрес доставки')
      .when('deliveryType', {
        is: (deliveryTypeId) =>
          !!find(deliveryMethods, { id: deliveryTypeId })?.isAddressRequired,
        then: (schema: Yup.StringSchema) =>
          schema.min(10, 'Адрес указан не верно').required(),
      }),
    pickPoint: Yup.string()
      .label('Точка выдачи')
      .when('deliveryType', {
        is: (deliveryTypeId) =>
          !!find(deliveryMethods, { id: deliveryTypeId })?.isUsePickPoints,
        then: (schema: Yup.StringSchema) => schema.required(),
      }),
    customerComment: Yup.string(),
    items: Yup.array()
      .label('Товар')
      .of(CartItemSchema)
      .min(1, 'Не выбрано ни одного товара в корзине'),
  })
