import React from 'react'
import Paginate, { ReactPaginateProps } from 'react-paginate'
import { isMobile } from 'react-device-detect'

import { usePagination } from './usePaginator'

export type PaginationPropsType = Omit<ReactPaginateProps, 'pageCount'> & {
  totalPages: number
}

export const PaginationOld: React.FC<PaginationPropsType> = ({
  totalPages,
  marginPagesDisplayed,
  pageRangeDisplayed,
  ...props
}) => {
  const { currentPage, hrefBuilder, pageChangeHandler } = usePagination()

  return (
    <Paginate
      forcePage={currentPage > 1 ? Number(currentPage) - 1 : undefined}
      breakLabel={isMobile ? null : '...'}
      marginPagesDisplayed={marginPagesDisplayed ?? (isMobile ? 0 : 3)}
      pageRangeDisplayed={pageRangeDisplayed ?? (isMobile ? 2 : 2)}
      hrefBuilder={hrefBuilder}
      onPageChange={pageChangeHandler}
      pageCount={totalPages}
      {...props}
    />
  )
}

export default PaginationOld
