import { List, Record, RecordOf } from 'immutable'
import { chain, replace } from 'lodash'

import { AddressType } from '@monorepo/interfaces'
import { makeListNew } from './CollectionFactory'

export type AddressRecordPropsType = AddressType & {}

const defaultValues: AddressRecordPropsType = {
  country: '',
  region: '',
  city: '',
  street: '',
  building: '',
  additional: '',
  fullAddress: '',
}

export type AddressRecord = RecordOf<AddressRecordPropsType>
export type AddressRecordsCollection = List<AddressRecord>

const makeAddressRecord: Record.Factory<AddressRecordPropsType> = Record(
  defaultValues,
  'AddressRecord'
)

function getFullAddress(props: Partial<AddressType>) {
  return chain(props)
    .pick(['region', 'city', 'street', 'building'])
    .mapValues(value => (value ? replace(value, /\s+/g, '\u00A0') : undefined))
    .values()
    .filter()
    .join(', ')
    .value()
}

const mapper = ({
  ...props
}: Partial<AddressType>): Partial<AddressRecordPropsType> => ({
  ...props,
  fullAddress: getFullAddress(props),
})

export const AddressRecord = (
  props: Partial<AddressType> = {}
): AddressRecord => makeAddressRecord(mapper(props))

export const AddressRecordsCollection = makeListNew<AddressRecord, AddressType>(
  AddressRecord
)
