import configurableComponent from '@monorepo/core/utils/configurableComponent'
import Loader, { LoaderProps } from './Loader'

const defaultConfig: LoaderProps = {
  size: undefined,
  borderSize: undefined,
  image: undefined,
  text: undefined,
}

const [withConfig, configure] = configurableComponent(defaultConfig)

export { configure }

export default (withConfig(Loader) as unknown) as React.FC<LoaderProps>
