import { IPaymentMethod, IQuality } from '@monorepo/interfaces'

export type OrderIdType = number | string

export type OrderItemId = number

export abstract class AbstractProduct {
  constructor(
    readonly brandName: string,
    readonly number: string,
    readonly name: string,
    readonly brand?: { name: string },
    readonly images?: string[]
  ) {}
}

export interface IOrderItem extends AbstractProduct {
  readonly id: OrderItemId
  // brandName: string
  // number: string
  // name: string
  // images: string[]
  readonly amount: number
  readonly price: number
  readonly reference: string
  readonly quality?: IQuality
}

export interface IOrder {
  id: OrderIdType
  number: string
  createdAt: string
  customerComment: string
  items: IOrderItem[]
  totalPrice: number
  paymentMethod: IPaymentMethod
}
