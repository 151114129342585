import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'

export type SocialNetworkProps = {
  name: ''
  icon: string
  link: string
}
export type SocialNetwork = RecordOf<SocialNetworkProps>
export type SocialNetworksCollection = List<SocialNetwork>

const defaultValues: SocialNetworkProps = {
  name: '',
  icon: '',
  link: '',
}

const mapper = ({ ...props }: SocialNetworkProps): SocialNetworkProps => ({
  ...props,
})

const makeSocialNetwork: Record.Factory<SocialNetworkProps> = Record(
  defaultValues,
  'SocialNetworkRecord'
)

export const SocialNetwork = (props?: SocialNetworkProps): SocialNetwork =>
  makeSocialNetwork(props ? mapper(props) : undefined)

export const SocialNetworksCollection = makeList(SocialNetwork)
