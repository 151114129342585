import { useCallback } from 'react'

import { CartItemType, OfferType } from '@monorepo/interfaces'
import { CartItemSchema } from '@monorepo/validations'

import { useCart } from './useCart'

type FormValuesType = {
  amount: number
  price: number
  deliveryTime: number
  reference: string
  isSelected: boolean
  offer: OfferType | null
}

export function useCartForm(item: CartItemType, offer: OfferType | null) {
  const initialValues: FormValuesType = {
    amount: item.amount,
    isSelected: item.isSelected,
    reference: item.reference,
    price: item.price,
    deliveryTime: item.deliveryTime,
    offer,
  }

  const { updateCartItemHandler } = useCart()

  const onSubmit = useCallback(updateCartItemHandler.bind(null, item), [])

  return {
    validationSchema: CartItemSchema,
    initialValues,
    onSubmit,
  }
}
