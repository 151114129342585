import React from 'react'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap'

import { Image } from '@monorepo/components/common'
import { Link, ImagePlaceholder } from 'App/Components'
import style from './style.module.scss'
// import { ImagePlaceholder } from '../ImagePlaceholder'

type CardPropsType = {
  title?: React.ReactNode
  description?: React.ReactNode
  tooltip?: string
  image?: string
  url?: string
  height?: number
  imagePlaceholder?: boolean
}

export const Card: React.FC<CardPropsType> = ({
  image,
  title,
  description,
  tooltip,
  height = 300,
  url,
  children,
  imagePlaceholder,
}) => (
  <article
    className={cn(
      style.container,
      'et-item post type-post status-publish h-100'
    )}
  >
    <div className="post-inner et-item-inner d-flex flex-column justify-content-between h-100 p-0 d-shadow">
      <div
        className="post-image overlay-hover post-media bg-white"
        style={{ height }}
      >
        <ConditionalWrap
          condition={url}
          wrap={(children: any) => (
            <>
              <Link className="post-image-overlay" to={url!} title={tooltip}>
                <span className="overlay-read-more" />
              </Link>
              {children}
            </>
          )}
        >
          <div className="image-container h-100 p-1">
            {/* <div className="image-preloader" /> */}
            {image ? (
              <Image
                src={image}
                alt=""
                className="h-100 wp-post-image"
                style={{ objectFit: 'contain' }}
                loaderContainer={() => <div className="image-preloader" />}
                // loader
              />
            ) : (
              imagePlaceholder && <ImagePlaceholder />
            )}
          </div>
        </ConditionalWrap>
      </div>
      <div className="post-body flex-grow-1">
        <div className="p-2 pb-0 post-body-inner-wrap d-flex flex-column justify-content-between h-100">
          <div className="post-body-inner">
            {title && (
              <div className="post-meta et-clearfix">
                <div className="post-category">
                  <span>{title}</span>
                </div>
              </div>
            )}
            {description && (
              <small className="post-title entry-title text-white">
                {/* <Link to="/contacts" rel="bookmark"> */}
                {description}
                {/* </Link> */}
              </small>
            )}
          </div>
          {children}
          {/* <CompositButton to={item.url}>
                                    Читать далее
                                  </CompositButton> */}
        </div>
      </div>
    </div>
  </article>
)
