import { useQuery } from 'react-query'
import { useAuth } from '../auth_module'

import { useApi } from './useApi'

export function useCurrentUser() {
  const { fetchUser } = useApi()
  const { isAuthenticated } = useAuth()

  return useQuery('fetchUser', fetchUser, {
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  })
}
