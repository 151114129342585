export type TimeParserOptionsType = {
  maxDays?: number
}

const defaultParserOptions: Required<TimeParserOptionsType> = {
  maxDays: 10,
}

export function timeParser(
  totalHours: number,
  options: TimeParserOptionsType = {}
) {
  const { maxDays } = { ...defaultParserOptions, ...options }

  const deliveryDays = totalHours / 24
  const isLongDelivery = deliveryDays >= maxDays
  const days = isLongDelivery
    ? Math.ceil(deliveryDays)
    : Math.floor(deliveryDays)
  const hours = isLongDelivery ? 0 : totalHours % 24

  return { days, hours }
}

export type TimeFormatterOptionsType = {
  daysSymbol?: string
  hoursSymbol?: string
}

const defaultFormatterOptions: Required<TimeFormatterOptionsType> = {
  daysSymbol: 'дн.',
  hoursSymbol: 'ч',
}
export function timeFormatter(
  totalHours: number,
  options: TimeFormatterOptionsType & TimeParserOptionsType = {}
): string {
  const { daysSymbol, hoursSymbol, ...timeParserOptions } = {
    ...defaultFormatterOptions,
    ...options,
  }

  const { days, hours } = timeParser(totalHours, timeParserOptions)

  return [
    days > 0 ? `${days} ${daysSymbol}` : undefined,
    days === 0 || hours > 0 ? `${hours} ${hoursSymbol}` : undefined,
  ]
    .filter(Boolean)
    .join(' ')
}
