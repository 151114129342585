import parse from 'html-react-parser'
import { get, has } from 'lodash'

import renderers from './renderers'

function replace(domNode) {
  if (domNode.type === 'tag' && has(renderers, domNode.name)) {
    const renderer = get(renderers, domNode.name)
    return renderer(domNode)
  }
}

export const parserOptions = {
  replace,
}

export default content => parse(content, parserOptions)
