import qs from 'qs'
import { memoize } from 'lodash'

import { GoodType } from '@monorepo/interfaces'

export const getSearchUrl = (brandName: string, number: string) =>
  `/search?${qs.stringify({ brandName, number })}`

export const getCatalogUrl = (brandName: string, number: string) =>
  `/goods/${brandName}/${number}`

export const useGoodUrls = memoize(
  ({ brandName, number }: GoodType) => {
    return {
      searchUrl: getSearchUrl(brandName, number),
      catalogUrl: getCatalogUrl(brandName, number),
    }
  },
  ({ brandName, number }: GoodType) => [brandName, number].join(':')
)
