import React from 'react'

import { User } from '@monorepo/hooks'
import { UserButton } from './UserButton'
import { CartButton } from './CartButton'
import { Logo } from './Logo'
import { MenuButton } from './MenuButton'

type BottomSectionProps = {
  logoImage: string
}

export const BottomSection: React.FC<BottomSectionProps> = ({ logoImage }) => {
  const { user } = User.useUser()

  return (
    <div
      className="vc_row wpb_row vc_row-fluid vc_custom_1559210719611 vc_row-has-fill vc-row-770266"
      // style={{ zIndex: 1001 }}
    >
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none pl-0 pr-0">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <UserButton user={user} />
              <div className="header-vertical-separator hbe hide-default-false hide-sticky-false hbe-right solid vertical header-vertical-separator-722008">
                <div className="line" />
              </div>
              <CartButton />
              <MenuButton />
              <Logo logoImage={logoImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
