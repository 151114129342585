import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarCrash, faParking } from '@fortawesome/free-solid-svg-icons'

import { User } from '@monorepo/hooks'
// import { PhoneLink } from 'App/Components'
import { UserActionsButton } from './UserActionsButton'
import { ContractsSwitcher } from './ContractsSwitcher'
import './style.scss'

type PropsType = {
  //
}

export const TopSection: React.FC<PropsType> = () => {
  // const { company, mainPhone } = App.useCompany()
  const { user } = User.useUser()

  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1553775458473 vc_row-has-fill hide-sticky vc-row-620359">
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              {/* <div
                id="header-icon-69631"
                className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left size-small"
              >
                <FontAwesomeIcon icon={faParking} className="hicon" size="lg" />
              </div> */}
              <div
                id="header-slogan-53642"
                className="hbe header-slogan hide-default-false hide-sticky-false hbe-left ml-2"
              >
                <span style={{ fontSize: 14, fontWeight: 500 }}>
                  Официальный дилер <span className="text-patron">Patron</span>
                </span>
                , <span className="font-weight-bold">Sakura</span>,{' '}
                <span className="font-weight-bold">JS Asakashi</span>,
                <span className="font-weight-bold">Fit</span>,{' '}
                <span className="font-weight-bold">Nibk</span>
              </div>
              <div
                id="header-icon-969829"
                className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left size-small"
              >
                <FontAwesomeIcon
                  icon={faCarCrash}
                  className="hicon"
                  size="lg"
                />
              </div>
              <div
                id="header-slogan-18402"
                className="hbe header-slogan hide-default-false hide-sticky-false hbe-left ml-2"
              >
                <span
                  className="text-nowrap"
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  Открылся наш автосервис!
                </span>
              </div>
              {/* <div
                id="header-icon-487577"
                className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left size-small"
              >
                <span className="hbe-toggle hicon far fa-clock" />
              </div>
              <div
                id="header-slogan-291137"
                className="hbe header-slogan hide-default-false hide-sticky-false hbe-left"
              >
                <span
                  className="text-nowrap"
                  style={{ fontSize: 12, fontWeight: 500 }}
                >
                  ПН-ПТ 09:00-20:00, СБ 10:00-18:00, ВС 10:00-17:00
                </span>
              </div> */}
              <UserActionsButton />
              {!user.isGuest && (
                <>
                  <div className="header-vertical-separator hbe hide-default-false hide-sticky-false hbe-right solid vertical header-vertical-separator-561232">
                    <div className="line" />
                  </div>
                  <ContractsSwitcher />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
