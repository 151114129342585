import { useQuery } from 'react-query'

import { DeliveryMethodId } from '@monorepo/interfaces'
import { useApi } from '../useApi'

export function useDeliveryMethodsList() {
  const { fetchDeliveryMethods } = useApi()

  const { data, isFetching } = useQuery(
    'fetchDeliveryMethodsList',
    fetchDeliveryMethods,
    {
      refetchOnWindowFocus: false,
    }
  )

  const findById = (id: DeliveryMethodId) =>
    data?.find((item) => String(item.id) === String(id))

  return { data, isFetching, findById }
}
