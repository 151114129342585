import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { isExternalLink } from '@monorepo/core/helpers'

interface LinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href' | 'ref'> {
  to: string
  children?: React.ReactNode
  external?: boolean
  type?: 'email-' | 'tel-'
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  external = isExternalLink(to),
  ...attributes
}) =>
  external ? (
    <a href={to} {...attributes} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...attributes}>
      {children}
    </RouterLink>
  )
