import React, { useCallback } from 'react'
import cn from 'classnames'
import { delay } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useGoodSuggestion } from '@monorepo/hooks'
import { SuggetionsList } from './SuggetionsList'

import style from './style.module.scss'

type PropsType = {
  show?: boolean
}

const useShowState = (initialShow: boolean) => {
  const [show, setShow] = React.useState<boolean>(initialShow)

  const handleShow = React.useCallback(() => setShow(true), [])
  const handleHide = React.useCallback(() => delay(setShow, 250, false), [])

  return { show, handleShow, handleHide }
}

export const SearchField: React.FC<PropsType> = ({
  show: initialShow = false,
}) => {
  const {
    query,
    loading,
    suggestions,
    handleChange: baseHandleChange,
    handleSelect,
    handleClear,
  } = useGoodSuggestion()

  const { show, handleHide, handleShow } = useShowState(initialShow)

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
    },
    []
  )
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      baseHandleChange(event.target.value)
    },
    [baseHandleChange]
  )

  return (
    <form
      className={cn(style.container, 'dgwt-wcas-search-form')}
      role="search"
      onSubmit={handleSubmit}
    >
      <div className="dgwt-wcas-sf-wrapp">
        <label className="screen-reader-text">Поиск товаров</label>

        <input
          type="search"
          className={cn(style.inputField, { [style.inputFieldOpen]: show })}
          name="query"
          placeholder="Поиск товаров..."
          autoComplete="off"
          value={query}
          onChange={handleChange}
          onFocus={handleShow}
          onBlur={handleHide}
        />
        <FontAwesomeIcon icon={faSearch} className="dgwt-wcas-ico-magnifier" />

        {query.length > 0 && (
          <div
            className={cn('dgwt-wcas-preloader', 'dgwt-wcas-close', {
              'dgwt-wcas-inner-preloader': loading,
            })}
            onClick={handleClear}
          />
        )}
        <SuggetionsList
          className={cn(style.suggestionList, {
            [style.suggestionListOpen]: show,
          })}
          query={query}
          loading={loading}
          suggestions={suggestions}
          handleSelect={handleSelect}
        />
      </div>
    </form>
  )
}
