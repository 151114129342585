import React, { useCallback, useEffect, useState /*, useRef*/ } from 'react'
import { Alert, Button, Modal, ProgressBar } from 'react-bootstrap'
import { useTimer } from 'use-timer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
// import parse from 'url-parse'
// import { useHistory } from 'react-router-dom'

import { useApi } from './useApi'
import { IOrder } from '@monorepo/hooks/Order'
import { IPaymentMethod } from '@monorepo/interfaces'

type ComponentProps = {
  order: IOrder
  paymentMethod: IPaymentMethod
  successUrl: string
  failureUrl?: string
}

export const Component: React.FC<ComponentProps> = ({
  order,
  paymentMethod,
  successUrl,
  failureUrl,
}) => {
  const { registerOrder } = useApi()

  const { time, start, /*pause, reset,*/ isRunning } = useTimer({
    initialTime: 0,
    endTime: 100,
    interval: 50,
    step: 2,
  })

  const [isStarted, setStarted] = useState<boolean>(false)
  const [isShow, setShow] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [link, setLink] = useState<string>()
  // const linkRef = useRef<HTMLAnchorElement>(null)
  // const linkRef = useRef<any>(null)
  // const history = useHistory()

  const registerOrderHandler = useCallback(
    async (order: IOrder, successUrl: string, failureUrl?: string) => {
      try {
        const { formUrl } = await registerOrder(order, successUrl, failureUrl)
        setLink(formUrl)
      } catch (error) {
        setError(error.message)
      }
    },
    [registerOrder]
  )

  useEffect(() => {
    if (isStarted) {
      return
    }
    setStarted(true)
    start()
    registerOrderHandler(order, successUrl, failureUrl)
  }, [failureUrl, isStarted, order, registerOrderHandler, start, successUrl])

  useEffect(() => {
    // if (link && !isRunning) linkRef?.current?.click()
    if (link && !isRunning) {
      window.location.href = link
    }
  }, [isRunning, link])

  return (
    <Modal show={isShow} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>{paymentMethod.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            {paymentMethod.description && (
              <Alert variant="primary">{paymentMethod.description}</Alert>
            )}
            {paymentMethod.message && (
              <Alert variant="success">{paymentMethod.message}</Alert>
            )}
            <p>
              <FontAwesomeIcon icon={faCircleNotch} spin />
              &nbsp;Формирование платежа...
            </p>
            <ProgressBar
              animated
              variant="danger"
              now={time}
              className="my-4"
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {error ? (
          <Button onClick={setShow.bind(null, false)}>Закрыть</Button>
        ) : (
          <Button href={link} variant="primary" disabled={!link} block>
            Перейти к оплате
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
