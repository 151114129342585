import React from 'react'
import { first } from 'lodash'

import { GoodType } from '@monorepo/interfaces'
import { LightBox, Image, ImageProps } from '@monorepo/components/common'
import { ImagePlaceholder } from 'App/Components'
// import { getImagineUrl } from '@monorepo/core/helpers'

type GoodImageProps = Omit<ImageProps, 'src'> & {
  good: GoodType
  imageFilter?: string
  thumbImageFilter?: string
}

export const GoodImage: React.FC<GoodImageProps> = ({
  good,
  imageFilter = 'good_image',
  thumbImageFilter = 'good_thumb',
  ...props
}) => {
  const placeholder = <ImagePlaceholder {...props} />

  return !good.images || !good.thumbs ? (
    placeholder
  ) : (
    <LightBox images={good.images}>
      <Image {...props} src={good.thumbs[0]} unloader={placeholder} />
    </LightBox>
  )
}
