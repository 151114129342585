import React from 'react'
import cn from 'classnames'
import MDSpinner from 'react-md-spinner'

import style from './style.module.scss'

export interface LoaderProps {
  image?: any
  size?: number
  borderSize?: number
  text?: string
  className?: string
}

const Loader: React.FC<LoaderProps> = ({
  image,
  size,
  borderSize,
  text,
  className,
}) => (
  <div className={cn(className, style.container)}>
    <MDSpinner size={size} borderSize={borderSize} className={style.block} />
    {image && <span className={cn(style.block, style.image)}>{image}</span>}
    {text && <span className={cn(style.block, style.text)}>{text}</span>}
  </div>
)

export default Loader
