import React from 'react'
import cn from 'classnames'
import gravatar from 'gravatar'

import { User, useComponentVisible } from '@monorepo/hooks'
import { LoginModal } from './LoginModal'
import { UserMenu } from './UserMenu'

type PropsType = {
  //
}

export const UserActionsButton: React.FC<PropsType> = () => {
  const { user, loginHandler, logoutHandler, error } = User.useUser()

  const {
    componentRef: menuRef,
    isComponentVisible: isMenuVisible,
    toggleComponentHandler: toggleMenu,
  } = useComponentVisible(false)

  const {
    isComponentVisible: isModalVisible,
    hideComponentHandler: hideModal,
    toggleComponentHandler: toggleModal,
  } = useComponentVisible(false)

  return (
    <>
      <div
        id="header-login-865646"
        className="login-font-size-14 header-login hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-small box-align-left"
      >
        <div
          id="login-toggle-865646"
          className={cn('login-toggle', 'hbe-toggle', {
            'ien-euser-10': user.isGuest,
          })}
          // className={`login-toggle hbe-toggle ${
          //   user.isGuest ? 'ien-euser-10' : '' //'ien-euser-14'
          // }`}
        >
          {!user.isGuest && (
            <img
              width={30}
              height={30}
              className="rounded-circle"
              src={gravatar.url(user.profile.email!, { default: 'mp' })}
              alt=""
            />
          )}
          <div
            className="login-title"
            onClick={user.isGuest ? toggleModal : toggleMenu}
          >
            {user.isGuest ? <>Вход</> : <>{user.profile.firstName}</>}
          </div>
        </div>

        {user.isGuest || (
          <div ref={menuRef}>
            {isMenuVisible && (
              <UserMenu user={user} handleLogout={logoutHandler} show={true} />
            )}
          </div>
        )}
      </div>
      <LoginModal
        show={isModalVisible}
        onClose={hideModal}
        onLogin={loginHandler}
        error={error}
      />
    </>
  )
}
