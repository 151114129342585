import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
// import { find, first } from 'lodash'

import {
  getCarouselSelector,
  clearCarouselAction,
  fetchCarouselAction,
} from '@monorepo/core/features/Carousel'

export function useCarousel(name: string) {
  const dispatch = useDispatch()

  const fetchCarousel = useCallback(
    bindActionCreators(fetchCarouselAction, dispatch),
    []
  )

  const clearCarousel = useCallback(
    bindActionCreators(clearCarouselAction, dispatch),
    []
  )

  const { data: carouselItems, loading } = useSelector(getCarouselSelector)(
    name
  )

  useEffect(() => {
    fetchCarousel(name)
    return () => {
      clearCarousel(name)
    }
  }, [clearCarousel, fetchCarousel, name])

  return { carouselItems, loading }
}
