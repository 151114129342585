import React from 'react'

import { TopSection } from './TopSection'
import { BottomSection } from './BottomSection'

type MobileHeaderProps = {
  logoImage: string
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ logoImage }) => (
  <header
    id="et-mobile-2264"
    className="header et-mobile et-clearfix transparent-false sticky-true shadow-true shadow-sticky-true mobile-true tablet-portrait-true tablet-landscape-true desktop-false"
    style={{
      color: 'var(--text-color1)',
    }}
  >
    <TopSection />
    <BottomSection logoImage={logoImage} />
  </header>
)
