import {
  put,
  takeEvery,
  /*, putResolve, takeLatest, select*/
} from 'redux-saga/effects'
import { success /*, RequestAction */ } from 'redux-saga-requests'
// import { get } from 'lodash'

// import { CartItemRecord } from "../../../records"

// import { /*UPDATE_CART_ITEM,*/ ADD_TO_CART } from './constants'
import { CLEAR_USER, FETCH_USER } from '../user/constants'
import {
  //   clearCart,
  //   fetchCart,
  clearCartAction,
  populateCartAction /*, createCartItem, updateCartItem*/,
} from './actions'
// import { selectCartItemsMap } from "./selectors"

function* watchClearUser() {
  yield put(clearCartAction())
}

function* watchSuccessFetchUserRequest(action: any) {
  const items = action.data.cart
  yield put(populateCartAction(items))
}

// function* watchAddToCart(action) {
//   yield console.log({ action })
//   // yield yield yield delay(2000)
//   // yield console.log('complete')
// }

// function* watchAddToCart(action) {
//     const cartItem = new CartItemRecord(action.meta.cartItem)
//     // console.log(cartItem)
//     const cart = yield select(selectCartItemsMap)
//     // console.log(action, cart)

//     const currentCartItem = cart
//         .find(current => current.itemKey === cartItem.itemKey && current.reference === cartItem.reference)

//     if(currentCartItem) {

//         return yield putResolve(updateCartItem(cartItem.toJS(), { amount: currentCartItem.amount + cartItem.amount }))
//     } else {
//         try {
//             return yield putResolve(createCartItem(cartItem.toJS()))
//         } catch (error) {
//             console.log(error)
//         }
//     }
//     // console.log(action, rest)
// }

// function* watchUpdateCartItem(action) {
//     console.log(action)
//     yield 123
// }

export function* cartSaga() {
  yield takeEvery(CLEAR_USER, watchClearUser)
  yield takeEvery(success(FETCH_USER), watchSuccessFetchUserRequest)
  // yield takeEvery(ADD_TO_CART, watchAddToCart)
  // yield takeLatest(UPDATE_CART_ITEM, watchUpdateCartItem)
  // yield takeLatest(ADD_TO_CART, watchAddToCart)
}
