import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import { castArray, chain, chunk, findIndex } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { Catalog, useComponentVisible } from '@monorepo/hooks'
import { CompositButton } from 'App/Components'
import { CatalogType } from '@monorepo/interfaces'
import { CatalogItem } from './CatalogItem'

import './style.scss'

type PropsType = {
  //
}

type CatalogsCategory = {
  category: string
  catalogs: CatalogType[]
}

const defaultCategory = 'Остальные'

export const DepartamentsButton: React.FC<PropsType> = () => {
  const { catalogs: ilcatsCatalogs } = Catalog.Neoriginal.useCatalogsList()
  const {
    catalogs: catalogsPartsCatalogs,
  } = Catalog.CatalogsParts.useCatalogsList()

  const catalogsList = useMemo(
    () =>
      chain(ilcatsCatalogs)
        .filter('isActive')
        .reduce((acc, catalog) => {
          castArray(catalog.category || defaultCategory).forEach((category) => {
            const idx = findIndex(acc, { category })
            if (idx === -1) {
              acc.push({
                category,
                catalogs: [catalog],
              })
            } else {
              acc[idx].catalogs.push(catalog)
            }
          })
          return acc
        }, [] as CatalogsCategory[])
        .concat({
          category: 'Общие каталоги',
          catalogs: catalogsPartsCatalogs,
        })
        .value(),
    [catalogsPartsCatalogs, ilcatsCatalogs]
  )

  const [activeCategory, setActiveCategory] = useState(
    catalogsList[0] ? catalogsList[0].category : null
  )

  const {
    isComponentVisible,
    hideComponentHandler,
    toggleComponentHandler,
    componentRef,
  } = useComponentVisible(false)

  return (
    <div
      id="et-header-button-731793"
      className={cn(
        'et-header-button',
        'hbe',
        'hbe-icon-element',
        'hide-default-false',
        'hide-sticky-false',
        'hbe-left',
        'submenu-appear-fall',
        'submenu-toggle-hover',
        'submenu-shadow-true',
        'has-icon',
        { active: isComponentVisible }
      )}
    >
      <CompositButton
        onClick={toggleComponentHandler}
        variant="dark"
        style={{ width: 282, height: 48 }}
      >
        Каталоги{' '}
        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={isComponentVisible ? 180 : undefined}
        />
      </CompositButton>
      {isComponentVisible && (
        <div
          ref={componentRef}
          id="megamenu-562"
          className="sub-menu megamenu"
          data-width={1200}
          data-position="left"
        >
          <div className="vc_row wpb_row vc_row-fluid stretch_row_content">
            <div className="container et-clearfix">
              <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper">
                    <div
                      id="megamenu-tab-726989"
                      className="megamenu-tab et-clearfix small action-hover hover-fill"
                    >
                      <div className="tabset et-clearfix">
                        {catalogsList.map((group) => (
                          <div
                            key={group.category}
                            data-target="tab-item-batteries"
                            className={cn('false', 'tab-item', 'et-clearfix', {
                              active: activeCategory === group.category,
                            })}
                            onClick={setActiveCategory.bind(
                              null,
                              group.category
                            )}
                          >
                            <span className="txt">{group.category}</span>
                            <span className="icon fas fa-chevron-right" />
                          </div>
                        ))}
                      </div>
                      <div className="tabs-container et-clearfix">
                        {catalogsList.map((category) => (
                          <div
                            key={category.category}
                            className={cn('tab-content","et-clearfix', {
                              active: activeCategory === category.category,
                              'd-none': activeCategory !== category.category,
                            })}
                            style={{
                              maxWidth: 918,
                              maxHeight: '75vh',
                              overflowY: 'auto',
                              paddingLeft: 24,
                              paddingRight: 24,
                            }}
                          >
                            {chunk(category.catalogs, 4).map((row, idx) => (
                              <div
                                key={idx}
                                className="vc_row wpb_row vc_inner vc_row-fluid megamenu-category-image-tab vc_column-gap-24 vc_row-o-equal-height vc_row-o-content-bottom vc_row-flex vc-row-171370"
                              >
                                {row.map((catalog, idx) => (
                                  <div
                                    key={idx}
                                    className="wpb_column vc_column_container vc_col-sm-3"
                                  >
                                    <CatalogItem
                                      catalog={catalog}
                                      onClick={hideComponentHandler}
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
