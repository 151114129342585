import { useClient } from '@monorepo/core/api_module'

export interface IPriceInfoStep {
  name: string
  prevPrice?: number
  price?: number
}

export function useApi() {
  const client = useClient()

  async function fetchPriceInfo(itemKey?: string) {
    if (!itemKey) {
      return Promise.resolve(null)
    }
    const { data } = await client.get<IPriceInfoStep[]>(
      `/offer/price-calculation-info/${itemKey}`
    )
    return data
  }

  return { fetchPriceInfo }
}
