import React from 'react'
import cn from 'classnames'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import { Image, LightBox } from '@monorepo/components/common'
import { ImagePlaceholder } from 'App/Components'
import { BrandInfoModal } from './BrandInfoModal'
import { Warranty } from './warranty'
// import GoodLink from '../link'
// import GoodLightBox from '../LightBox'
// import BrandInfo from '../../brand/info'

// import getImagineUrl from '@monorepo/core/helpers/getImagineUrl'
import style from './style.module.scss'
import { IGood } from '@monorepo/interfaces'
import { getSearchUrl } from '@monorepo/core/helpers'

type GoodInfoCardProps = React.HTMLAttributes<HTMLDivElement> & {
  good: IGood
}
export const GoodInfoCardNew: React.FC<GoodInfoCardProps> = ({
  good,
  className,
}) => {
  return (
    <div className={cn(className, style.container, 'd-flex')}>
      <div className={cn(style.image, 'ml-2 mr-2')}>
        {good.images?.length && good.thumbs?.length ? (
          <LightBox images={good.images}>
            <Image
              height={75}
              width={75}
              src={good.thumbs[0]}
              className="img-thumbnail"
              unloader={<ImagePlaceholder />}
              lazy={{ once: true, debounce: true }}
            />
          </LightBox>
        ) : (
          <ImagePlaceholder height={75} width={75} className="img-thumbnail" />
        )}
      </div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="d-flex">
            <BrandInfoModal
              brand={good.brand}
              className="text-left font-weight-bold text-nowrap p-0 p-lg-2"
              tooltip={
                <>
                  Подробно о{' '}
                  <strong className="text-nowrap">«{good.brand.name}»</strong>
                </>
              }
            >
              {good.brand.name}
            </BrandInfoModal>

            {good.brand.warranty && (
              <Warranty
                text={good.brand.warranty}
                tooltip={good.brand.warrantyDescription}
                className="ml-3"
              />
            )}
          </div>
          <div>
            {/* <Button
              variant="link"
              className="text-left text-uppercase text-nowrap p-0 p-lg-2"
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id={uniqueId('tooltip-')}>
                    Подробнее о{' '}
                    <strong className="text-wrap">
                      «{good.brand.name} {good.number.toUpperCase()}»
                    </strong>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
            </Button> */}
            <LinkContainer to={getSearchUrl(good)}>
              <Button
                variant="link"
                className="text-left text-uppercase text-nowrap p-0 p-lg-2"
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id={uniqueId('tooltip-')}>
                      Найти{' '}
                      <strong className="text-wrap">
                        «{good.brand.name} {good.number.toUpperCase()}»
                      </strong>
                    </Tooltip>
                  }
                >
                  <>
                    <span className="d-none d-lg-inline">
                      <FontAwesomeIcon icon={faSearch} className="text-muted" />{' '}
                    </span>
                    {good.number}
                  </>
                </OverlayTrigger>
              </Button>
            </LinkContainer>
          </div>
        </div>
        <div className={cn(style.name, 'align-text-top text-break border-top')}>
          {good.name}
        </div>
      </div>
    </div>
  )
}
