import React from 'react'
import cn from 'classnames'

import { App, useComponentVisible } from '@monorepo/hooks'
import { Menu } from './Menu/Menu'

import style from './style.module.scss'

type MenuButtonProps = {
  //
}

export const MenuButton: React.FC<MenuButtonProps> = () => {
  const { menu } = App.useMenu('main')

  const {
    isComponentVisible,
    hideComponentHandler,
    toggleComponentHandler,
  } = useComponentVisible(false)

  return (
    <>
      <div
        onClick={toggleComponentHandler}
        className={cn(
          style.container,
          'mobile-container-toggle hbe hbe-icon-element hide-default-false hide-sticky-false hbe-left size-medium'
        )}
      >
        <div
          className={cn(style.toggle, 'mobile-toggle hbe-toggle ien-emenu-2')}
          data-close-icon="ien-eclose-3"
        />
      </div>
      <div
        className={cn('mobile-container-overlay', {
          active: isComponentVisible,
        })}
      />
      <Menu items={menu} onHide={hideComponentHandler} />
    </>
  )
}
