import { FETCH_ORDERS, CLEAR_ORDERS } from './constants'
import { RequestAction, QueryState } from 'redux-saga-requests'

import { OrderType, OrdersFilterType } from '@monorepo/interfaces'
import { DataRecord } from './records'

import { OrderRecordsCollection } from '@monorepo/core/records'

type DataType = Array<OrderType>
type StateType = QueryState<DataType>

export function fetchOrdersAction(filter: OrdersFilterType): RequestAction {
  return {
    type: FETCH_ORDERS,
    request: {
      url: '/orders',
      params: filter,
    },
    meta: {
      // asPromise: true,
      resetOn: [CLEAR_ORDERS],
      getData({ data }: StateType, action) {
        return DataRecord({ items: OrderRecordsCollection(action.data) })
      },
    },
  }
}

export function clearOrdersAction() {
  return {
    type: CLEAR_ORDERS,
  }
}
