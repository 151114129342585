import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { get } from 'lodash'

import { getResetPasswordSchema } from '@monorepo/validations'
import { resetPasswordAction } from '@monorepo/core/features/user'

type FormValuesType = {
  plainPassword: {
    first: string
    second: string
  }
}

export function useResetPasswordForm() {
  const { token } = useParams<{ token: string }>()

  const initialValues: FormValuesType = useMemo(
    () => ({
      plainPassword: {
        first: '',
        second: '',
      },
    }),
    []
  )

  const dispatch = useDispatch()
  const resetPassword = useCallback(
    bindActionCreators(resetPasswordAction, dispatch),
    []
  )

  const onSubmit = useCallback(
    async ({ plainPassword }: FormValuesType) => {
      try {
        return await resetPassword({
          token: token ?? '',
          plainPassword,
        })
      } catch (error) {
        const errorMessage = get(
          error,
          'error.response.data.errors[0]',
          'Произошла непредвиденная ошибка'
        )
        throw new Error(errorMessage)
      }
    },
    [resetPassword, token]
  )

  const validationSchema = useMemo(() => getResetPasswordSchema(), [])

  return { validationSchema, initialValues, onSubmit }
}
