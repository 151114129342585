import * as Yup from 'yup'

export const getCarSchema = () =>
  Yup.object().shape({
    mark: Yup.string()
      .label('Марка автомобиля')
      .min(3)
      .max(100)
      .required(),
    model: Yup.string()
      .label('Модель автомобиля')
      .min(3)
      .max(100)
      .required(),
    modification: Yup.string()
      .label('Модификация')
      // .min(3)
      .max(100),
    productionYear: Yup.number()
      .label('Год выпуска')
      .integer()
      .positive()
      .min(1900)
      .max(new Date().getFullYear())
      .required(),
    vin: Yup.string()
      .label('VIN код(Frame)')
      .vinNumber(),
    engineModel: Yup.string()
      .label('Модель двигателя')
      .min(2)
      .max(50),
    engineSize: Yup.number()
      .label('Объём двигателя')
      .integer()
      .positive(),
    enginePower: Yup.number()
      .label('Мощность двигателя')
      .integer()
      .positive(),
    description: Yup.string()
      .label('Дополнительная')
      .min(2)
      .max(1000),
  })
