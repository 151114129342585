import { requestsReducer } from 'redux-saga-requests'

import {
  CLEAR_CONTRACTS,
  FETCH_CONTRACTS,
  POPULATE_CONTRACTS,
} from './constants'
import { ContractRecordsCollection } from 'packages/core/records'

export const contractReducer = requestsReducer({
  multiple: true,
  actionType: FETCH_CONTRACTS,
  resetOn: [CLEAR_CONTRACTS],
  getDefaultData: () => ContractRecordsCollection(),
  mutations: {
    [POPULATE_CONTRACTS]: {
      updateData(state, { meta }) {
        return ContractRecordsCollection(meta.items)
      },
      local: true,
    },
  },
})
