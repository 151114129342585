import { Record, RecordOf } from 'immutable'

export type AuthResponseType = {
  token: string
  refresh_token: string
}

export type StatePropsType = {
  token: string | null
  refreshToken: string | null
  switchUser: string | null
}

export const StateRecord = Record<StatePropsType>({
  token: null,
  refreshToken: null,
  switchUser: null,
})

export type StateRecordType = RecordOf<StatePropsType>
