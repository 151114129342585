import React, { useCallback, useState } from 'react'
import BaseLightbox, { ViewType, Modal, ModalGateway } from 'react-images'
import { castArray, clamp, get, isFunction, isString, map, size } from 'lodash'

import style from './style.module.scss'

type LightBoxPropsType = {
  images: string | Array<string> | ViewType | Array<ViewType>
  currentIndex?: number
}

function useLightbox(
  images: string | Array<string> | ViewType | Array<ViewType>,
  initialIndex: number = 0
) {
  const [show, toggleShow] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const showHandler = useCallback(
    (index = initialIndex) => {
      setCurrentIndex(clamp(index, 0, size(images)))
      toggleShow(true)
    },
    [images, initialIndex]
  )
  const hideHandler = useCallback(toggleShow.bind(null, false), [])

  const views: Array<ViewType> = map(castArray(images), (image) => {
    return isString(image)
      ? {
          caption: get(image, 'caption', ''),
          source: get(image, 'src', image),
        }
      : image
  })

  return { show, currentIndex, views, showHandler, hideHandler }
}

export const LightBox: React.FC<LightBoxPropsType> = ({
  images,
  children,
  currentIndex: initialCurrentIndex = 0,
}) => {
  const { show, currentIndex, views, showHandler, hideHandler } = useLightbox(
    images,
    initialCurrentIndex
  )

  return (
    <>
      <ModalGateway>
        {show && (
          <Modal onClose={hideHandler} closeOnBackdropClick>
            <BaseLightbox
              views={views}
              currentIndex={currentIndex}
              frameProps={{ autoSize: 'height' }}
              styles={{
                view: (base) => ({
                  ...base,
                  '& img': {
                    maxHeight: '95vh !important',
                  },
                }),
              }}
            />
          </Modal>
        )}
      </ModalGateway>
      <>
        {isFunction(children) ? (
          views.map((view, idx) => (
            <div key={idx} className={style.container}>
              {children({
                image:
                  typeof view.source === 'object'
                    ? view.source.regular
                    : view.source,
                show: showHandler.bind(null, idx),
              })}
            </div>
          ))
        ) : (
          <div
            className={style.container}
            onClick={showHandler.bind(null, initialCurrentIndex)}
          >
            {children}
          </div>
        )}
      </>
    </>
  )
}
