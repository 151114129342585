import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Currency } from '@monorepo/components/common'
import { Cart, useComponentVisible } from '@monorepo/hooks'
import { CompositButton } from 'App/Components'
import { CartItemsList as BaseCartItemsList } from './CartItemsList'

import './style.scss'

const CartItemsList = styled<any>(BaseCartItemsList)`
  max-height: 25vh;
  overflow-y: auto;
`
type CartMenuPropsType = {
  //
}

export const CartMenu: React.FC<CartMenuPropsType> = () => {
  const {
    componentRef,
    isComponentVisible,
    toggleComponentHandler,
  } = useComponentVisible(false)

  const {
    cartItems,
    totalItems,
    totalPrice,
    removeCartItemHandler,
  } = Cart.useCart()

  const { formatMessage } = useIntl()

  return (
    <div
      id="header-cart-365707"
      className="header-cart hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-custom box-align-left"
    >
      <div
        id="cart-toggle-365707"
        onClick={toggleComponentHandler}
        className={cn(
          'cart-toggle',
          'hbe-toggle',
          totalItems > 0 ? 'ien-ecart-9' : 'ien-ecart-12',
          {
            active: isComponentVisible,
          }
        )}
        data-close-icon="ien-eclose-3"
      >
        {' '}
        <div className="cart-contents" title="Просмотр корзины">
          <span className="cart-title">Корзина</span>
          <span className="cart-total">
            <span className="woocommerce-Price-amount amount">
              <Currency value={totalPrice} />
            </span>
          </span>
          {totalItems > 0 && <span className="cart-info">{totalItems}</span>}
        </div>
      </div>
      <div
        id="cart-box-365707"
        className={cn('cart-box', { active: isComponentVisible })}
        ref={componentRef}
      >
        <div className="widget woocommerce widget_shopping_cart">
          <h2 className="widgettitle">Корзина</h2>
          <div className="widget_shopping_cart_content">
            {cartItems.length > 0 ? (
              <>
                <p className="woocommerce-mini-cart-info woocommerce-mini-cart__total total">
                  <span className="subtotal-label">В корзине </span>
                  <span className="woocommerce-Price-amount amount">
                    {formatMessage(
                      { id: 'component.cart.total_items' },
                      { amount: cartItems.length }
                    )}
                  </span>
                </p>
                <CartItemsList
                  items={cartItems}
                  onRemove={removeCartItemHandler}
                />
                <p className="woocommerce-mini-cart-info woocommerce-mini-cart__total total">
                  <span className="subtotal-label">Итого:</span>
                  <span className="woocommerce-Price-amount amount">
                    &nbsp;
                    <Currency value={totalPrice} />
                  </span>
                </p>
                <p className="woocommerce-mini-cart-info woocommerce-mini-cart__buttons buttons">
                  <CompositButton to="/cart">Просмотр корзины</CompositButton>
                  {/* <Link to="/cart" className="button wc-forward">
                    Просмотр корзины
                  </Link> */}
                  {/* <Link to="/" className="button checkout wc-forward">
                    Оформление заказа
                  </Link>{' '} */}
                </p>
              </>
            ) : (
              <p className="woocommerce-mini-cart-info woocommerce-mini-cart__empty-message">
                Ваша корзина пуста
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
