import { useQuery } from 'react-query'

import { useApi } from '../useApi'
import { PaymentMethodId } from '@monorepo/interfaces'

export function usePaymentMethodsList() {
  const { fetchPaymentMethods } = useApi()

  const { data, isFetching } = useQuery(
    'fetchPaymentMethodsList',
    fetchPaymentMethods,
    {
      refetchOnWindowFocus: false,
    }
  )

  const findById = (id: PaymentMethodId) =>
    data?.find((item) => String(item.id) === String(id))

  return { data, isFetching, findById }
}
