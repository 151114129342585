import { RequestAction } from 'redux-saga-requests'

import { CarType } from '@monorepo/interfaces'
import { StateType, updateCar } from '../records'

import { FETCH_CARS, UPDATE_CAR } from '../constants'

type CarChangesType = Omit<CarType, 'id' | 'isNew'>
type UpdateCarActionType = RequestAction & {
  meta: {
    car: CarType
    changes: CarChangesType
  }
}
type UpdateCarActionResponseType = UpdateCarActionType & {
  data: { id: string }
}
export function updateCarAction(
  car: CarType,
  changes: CarChangesType
): UpdateCarActionType {
  return {
    type: UPDATE_CAR,
    request: {
      url: '/cars',
      method: 'post',
      data: {
        ...changes,
        id: car.id,
      },
    },
    meta: {
      // asPromise: true,
      // asMutation: true,
      car,
      changes,
      mutations: {
        [FETCH_CARS]: {
          updateData(
            { data }: StateType,
            { meta, data: { id: newCarId } }: UpdateCarActionResponseType
          ) {
            const {
              car: { id: carId },
            } = meta
            return data.update('cars', cars =>
              updateCar(cars, carId, {
                id: newCarId,
                isNew: false,
              })
            )
          },
          updateDataOptimistic(
            { data }: StateType,
            { meta }: UpdateCarActionType
          ) {
            const {
              car: { id: carId },
              changes,
            } = meta

            return data.update('cars', cars => updateCar(cars, carId, changes))
          },
          revertData({ data }: StateType, { meta }: UpdateCarActionType) {
            const { car } = meta
            return data.update('cars', cars => updateCar(cars, car.id, car))
          },
          // local: true,
        },
      },
    },
  }
}
