import { RequestAction } from 'redux-saga-requests'

import { FETCH_PAGE, CLEAR_PAGE } from './constants'
import { PageBlockRecord, BlockStateRecord, StateType } from './records'

export function fetchPageAction(slug: string): RequestAction {
  return {
    type: FETCH_PAGE,
    request: {
      url: `/page/${slug}`,
    },
    meta: {
      slug,
      cache: 600,
      cacheKey: slug,
      getDefaultData: () => BlockStateRecord(),
      getData(state, action) {
        return state.data.set(action.meta.slug, PageBlockRecord(action.data))
      },
    },
  }
}

export function clearPageAction(slug: string): RequestAction {
  return {
    type: CLEAR_PAGE,
    request: false,
    meta: {
      mutations: {
        [FETCH_PAGE]: {
          updateData(state: StateType) {
            return state.data.remove(slug)
          },
          local: true,
        },
      },
    },
  }
}
