import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
import clamp from 'lodash/clamp'
import qs from 'qs'

import { IPagination } from '@monorepo/interfaces'

export function usePagination(meta: IPagination, pageProp = 'page') {
  const location = useLocation()
  const history = useHistory()
  const { [pageProp]: currentPage = 1, ...currentQuery } = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  )

  const hrefBuilder = useCallback(
    (page) => {
      const nextQuery = qs.stringify(
        {
          ...currentQuery,
          ...(page > 1 && { [pageProp]: page }),
        },
        { addQueryPrefix: true }
      )
      const url = [location.pathname, nextQuery, location.hash].join('')
      return url
    },
    [currentQuery, location.hash, location.pathname, pageProp]
  )

  const gotoUrl = useCallback(
    (url: string) => {
      history.push(url)
    },
    [history]
  )

  const onPageChange = useCallback(
    ({ selected }) => {
      gotoUrl(hrefBuilder(selected + 1))
    },
    [gotoUrl, hrefBuilder]
  )

  return {
    pageCount: meta.totalPages,
    forcePage: clamp((meta.currentPage ?? currentPage) - 1, 0, meta.totalPages),
    hrefBuilder,
    onPageChange,
  }
}
