import { List, Record, RecordOf } from 'immutable'

import { OrderType } from '@monorepo/interfaces'
import { makeListNew } from '../CollectionFactory'
import { OrderItemRecordsCollection } from './OrderItemRecord'

type OrderRecordPropsType = Omit<OrderType, 'items'> & {
  items: OrderItemRecordsCollection
}

const defaultValues: OrderRecordPropsType = {
  number: '',
  createdAt: undefined,
  customerComment: '',
  sum: 0,
  items: OrderItemRecordsCollection(),
}

const mapper = ({
  createdAt,
  items,
  ...props
}: OrderType): OrderRecordPropsType => {
  return {
    createdAt: createdAt ? new Date(createdAt) : undefined,
    items: OrderItemRecordsCollection(items),
    ...props,
  }
}

const makeOrder: Record.Factory<OrderRecordPropsType> = Record(
  defaultValues,
  'OrderRecord'
)

export type OrderRecord = RecordOf<OrderRecordPropsType>
export type OrderRecordsCollection = List<OrderRecord>

export const OrderRecord = (props: OrderType): OrderRecord =>
  makeOrder(mapper(props))

export const OrderRecordsCollection = makeListNew<OrderRecord, OrderType>(
  OrderRecord
)
