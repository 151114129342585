import { requestsReducer } from 'redux-saga-requests'
// import { keys, pick, forOwn } from 'lodash'

// import { CartItemRecord } from 'core/records-old'
import {
  CLEAR_CART,
  FETCH_CART,
  POPULATE_CART,
  // CREATE_CART_ITEM,
  // UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
} from './constants'
// import { CartItemsCollection } from './records'
import { StateType } from './types'
import { CartItemRecordsCollection } from 'packages/core/records'

// function findCartItemId(cart: any, cartItem: any) {
//   return cart.findKey(
//     (current: any) =>
//       current.itemKey === cartItem.itemKey &&
//       current.reference === cartItem.reference
//   )
// }

// function addToCart(cart: any, cartItem: any) {
//   return cart.withMutations((cart: any) => {
//     const id = findCartItemId(cart, cartItem)
//     !id
//       ? cart.set(cartItem.id, cartItem)
//       : updateCartItem(cart, cart.get(id), {
//           amount: cart.get(id).get('amount') + cartItem.get('amount'),
//         })
//   })
// }

// function updateCartItem(cart: any, cartItem: any, changes = {}) {
//   return cart.has(cartItem.id)
//     ? cart
//         .withMutations((cart: any) => {
//           cart.update(cartItem.id, (current: any) => {
//             forOwn(changes, (value: any, key: any) => {
//               if (current.has(key)) {
//                 current = current.set(key, value)
//               }
//             })
//             return current
//           })
//         })
//         .mapKeys((id: any, cartItem: any) => cartItem.id)
//     : cart
// }

// function populateCart(cartItems) {
//   return CartItemRecord.Collection(cartItems)
// }

export const cartReducer = requestsReducer({
  // multiple: true,
  actionType: FETCH_CART,
  resetOn: [CLEAR_CART],
  // getDefaultData: () => CartItemsCollection(),
  getDefaultData: () => CartItemRecordsCollection(),
  // getData: (state, action, config) => populateCart(action.payload.data),
  mutations: {
    //   [CREATE_CART_ITEM]: {
    //     updateDataOptimistic: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return addToCart(state.data, cartItem)
    //     },
    //     updateData: (state, action) => {
    //       return updateCartItem(state.data, action.meta.cartItem, {
    //         id: action.data.id,
    //       })
    //     },
    //     revertData: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return removeFromCart(state.data, cartItem)
    //     },
    //     getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
    //   },
    //   [UPDATE_CART_ITEM]: {
    //     updateDataOptimistic: (state, action) => {
    //       return updateCartItem(
    //         state.data,
    //         action.meta.cartItem,
    //         action.meta.changes
    //       )
    //     },
    //     revertData: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return updateCartItem(
    //         state.data,
    //         cartItem,
    //         pick(cartItem, keys(action.meta.changes))
    //       )
    //     },
    //   },
    [REMOVE_CART_ITEM]: {
      // updateDataOptimistic: (state, action) => {
      //   return removeFromCart(state.data, action.meta.cartItem)
      // },
      // revertData: (state, action) => {
      //   const cartItem = CartItemRecord(action.meta.cartItem)
      //   return addToCart(state.data, cartItem)
      // },
      updateData: (state: StateType, action) => {
        const cartItem = action.meta.cartItem
        return state.data.filter(item => item.id !== cartItem.id)
      },
      // local: true,
    },
    [POPULATE_CART]: {
      // updateData: (state, { meta }) => CartItemsCollection(meta.items),
      updateData: (state, { meta }) => CartItemRecordsCollection(meta.items),
      local: true,
    },
  },
})

// export default reducer
