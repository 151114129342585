import React from 'react'
import { chunk } from 'lodash'

import { App } from '@monorepo/hooks'
import { PhoneLink, EmailLink, WhatsAppLink } from 'App/Components'
import { LinksList } from './LinksList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

type MiddleSectionProps = {
  logoImage: string
}

export const MiddleSection: React.FC<MiddleSectionProps> = ({ logoImage }) => {
  // const { company, mainPhone } = App.useCompany()
  const { menu: quickLinks } = App.useMenu('footerLinks')

  const phoneNumber = '+7 (960) 133-1-133'
  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1559292623224 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-651680">
      <div className="container et-clearfix">
        <div className="logo-size-normal footer-contacts wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 text-align-none vc_col-has-fill vc-column-437649">
          <div
            className="vc_column-inner vc_custom_1559292758492"
            data-480-right="0"
            data-480-768-right="0"
            data-768-1024-right="35"
            data-1024-1280-right="50"
          >
            <div className="wpb_wrapper">
              <div
                id="et-image-589350"
                className="et-image alignnone curtain-left"
                data-curtain="false"
                data-animation-delay="0"
                data-parallax="false"
                data-move="false"
                data-coordinatex="0"
                data-coordinatey="0"
                data-speed="10"
              >
                <img width="342" height="52" src={logoImage} alt="MobiZap" />
              </div>
              <span className="et-gap et-clearfix et-gap-883750" />
              <div
                id="et-icon-box-893709"
                className="title-margin-0 et-icon-box et-item icon-position-left icon-alignment-left hover-none"
                data-parallax="false"
                data-move="false"
                data-coordinatex="0"
                data-coordinatey="0"
                data-speed="10"
              >
                <div className="et-icon-box-inner et-item-inner et-clearfix">
                  <div className="et-icon medium">
                    <span
                      className="el-icon fas fa-headset"
                      style={{ color: 'var(--color1)' }}
                    />
                  </div>
                  <div className="et-icon-content et-clearfix">
                    <div className="et-icon-box-title">
                      Есть вопросы? Пиши, звони!
                    </div>
                    <div className="et-icon-box-content">
                      {phoneNumber && (
                        <>
                          <WhatsAppLink
                            phone={phoneNumber}
                            className="d-inline text-reset"
                          >
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                          </WhatsAppLink>
                          <PhoneLink
                            className="d-inline text-reset ml-3"
                            phone={phoneNumber}
                          >
                            {phoneNumber}
                          </PhoneLink>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <span className="et-gap et-clearfix et-gap-883750" />
              {/* <h6
                className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type"
                id="et-heading-123899"
              >
                <span className="text-wrapper">
                  <span
                    className="text"
                    style={{ color: 'var(--text-color1)' }}
                  >
                    Наши координаты
                  </span>
                </span>
              </h6>
              <span className="et-gap et-clearfix et-gap-536909" />
              <div
                className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type"
                id="et-heading-126946"
              >
                <span className="text-wrapper">
                  <span className="text">
                    {company.address?.fullAddress}
                    <span className="et-gap et-clearfix et-gap-1" />
                    <EmailLink className="text-reset" email={company.email}>
                      {company.email}
                    </EmailLink>
                  </span>
                </span>
              </div>
              <span className="et-gap et-clearfix et-gap-663926" /> */}
              {/* <div
                id="et-social-links-397572"
                className="et-social-links styling-original-false"
              >
                <a className="ien-youtube" href="#link" target="_self"></a>
                <a className="ien-linkedin" href="#link" target="_self"></a>
                <a className="ien-facebook" href="#link" target="_self"></a>
                <a className="ien-instagram" href="#link" target="_self"></a>
                <a className="ien-twitter" href="#link" target="_self"></a>
                <a className="ien-skype" href="#link" target="_self"></a>
              </div> */}
              <span className="et-gap et-clearfix et-gap-913975 hide1280" />
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12 vc_col-xs-12 text-align-none vc-column-953833">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-24 vc_row-o-equal-height vc_row-o-content-top vc_row-flex vc-row-242325">
                {chunk(quickLinks, Math.ceil(quickLinks.length / 3)).map(
                  (items, idx) => (
                    <div
                      key={idx}
                      className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-4 vc_col-md-4 vc_col-xs-12"
                    >
                      <LinksList
                        title={idx === 0 ? 'Ссылки' : undefined}
                        items={items}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
