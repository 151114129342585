import { useEffect } from 'react'
import { useHistory } from 'react-router'

export function useAutoScrollToTop() {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen((location: any, action) => {
      const scrollToTop = location?.state?.scrollToTop ?? true

      if (action === 'PUSH' && !scrollToTop) {
        return
      }

      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])
  return void 0
}
