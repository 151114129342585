import { useMemo } from 'react'
import { template } from 'lodash'

import { ilcats } from '@monorepo/core/config'

import { initialData } from 'App/data/catalogs'
import { getImagineUrl } from '@monorepo/core/helpers'

function applyClientId(url: string, clientId: string): string {
  return template(url)({ clientId })
  // const parsed = parse(url)
  // const query = qs.parse(String(parsed.query), { ignoreQueryPrefix: true })

  // parsed.set(
  //   'query',
  //   qs.stringify({ ...query, clid: clientId }, { addQueryPrefix: true })
  // )
  // return parsed.toString()
}

export function useCatalogsList() {
  const clientId = ilcats.clientId

  const catalogs = useMemo(
    () =>
      initialData.ilcats.map(({ url, image, ...catalog }) => ({
        url: applyClientId(url, String(clientId)),
        image: image ? getImagineUrl(image, 'car_brand_cards') : undefined,
        ...catalog,
      })),
    [clientId]
  )

  return { catalogs }
}
