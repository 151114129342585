import React from 'react'

import { MenuItemType } from '@monorepo/interfaces'
import { LinkItem } from './LinkItem'

type LinksListProps = {
  title?: React.ReactNode
  items: MenuItemType[]
}

export const LinksList: React.FC<LinksListProps> = ({ title, items }) => (
  <div className="vc_column-inner">
    <div className="wpb_wrapper">
      {title && (
        <h6
          className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type"
          // id="et-heading-831981"
        >
          <span className="text-wrapper">
            <span className="text" style={{ color: 'var(--text-color1)' }}>
              {title}
            </span>
          </span>
        </h6>
      )}
      <span className="et-gap et-clearfix et-gap-299018" />
      <ul
        // id="et-icon-list-77177"
        className="font-weight-500 text-align-left category-icon-list et-icon-list small"
      >
        {items.map((item, idx) => (
          <LinkItem key={idx} item={item} />
        ))}
      </ul>
    </div>
  </div>
)
