import { combineReducers } from 'redux'

import { authReducer, KEY as authKEY } from './auth'
import { cartReducer, KEY as cartKey } from './cart'
import { contractReducer, KEY as contractKey } from './Contract'
import { companyReducer, KEY as companyKEY } from './Company'
import { menuReducer, KEY as menuKEY } from './Menu'
import { suggestionReducer, KEY as suggestionKey } from './searchField'

const reducers = {
  [authKEY]: authReducer,
  [cartKey]: cartReducer,
  [companyKEY]: companyReducer,
  [contractKey]: contractReducer,
  [menuKEY]: menuReducer,
  [suggestionKey]: suggestionReducer,
}

// export default reducers
export default { core: combineReducers(reducers) }
