// import { includes, map, pick } from 'lodash'
import { includes, get, pick } from 'lodash/fp'
import { Action } from 'redux'
import { RequestAction, RequestActionMeta } from 'redux-saga-requests'

import { OfferType, ContractType, CartItemType } from '@monorepo/interfaces'

import {
  // CLEAR_CART,
  // POPULATE_CART,
  // CREATE_CART_ITEM,
  ADD_TO_CART,
  UPDATE_CART_ITEM,
  // REMOVE_CART_ITEM,
  FETCH_CART,
  FETCH_OFFERS,
  CLEAR_OFFERS,
  POPULATE_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  CREATE_ORDER,
} from './constants'
// import { CartItemRecord } from 'core/records-old'
import {
  CartItemRecord,
  OfferRecordsCollection,
  // CartItemsCollection,
  // OffersCollection,
} from '@monorepo/core/records'

import { StateType } from './types'

// export function fetchCartAction(): RequestAction {
//   return {
//     type: FETCH_CART,
//     request: {
//       url: '/cart',
//     },
//     meta: {
//       getData: (state, action) => CartItemsCollection(action.data),
//     },
//   }
// }

export function clearCartAction(): Action {
  return {
    type: CLEAR_CART,
  }
}

export function populateCartAction(items: Array<CartItemType>): RequestAction {
  return {
    type: POPULATE_CART,
    request: false,
    meta: {
      items,
    },
  }
}

type AddToCartParamsType = {
  amount: number
  contract: ContractType
  reference: string
}

type AddToCartRequestParams = {
  itemKey: string
  price: number
  deliveryTime: number
  amount: number
  reference: string
  contractId: string
}

type AddToCartActionType = RequestAction & {
  meta: AddToCartParamsType
}

// const createKey = ({ itemKey, contract, reference }: CartItemRecord) =>
//   [itemKey, contract.id, reference].filter(Boolean).join('|')
// const createKey = ({ id }: CartItemRecord) => String(id)

export function addCartItemAction(
  offer: OfferType,
  { amount, contract, reference }: AddToCartParamsType
): AddToCartActionType {
  const params: AddToCartRequestParams = {
    itemKey: offer.itemKey,
    price: offer.price,
    deliveryTime: offer.deliveryTime,
    amount,
    reference,
    contractId: contract.id,
  }

  return {
    type: ADD_TO_CART,
    request: {
      url: '/v2/cart/add',
      method: 'POST',
      data: params,
    },
    meta: {
      offer,
      amount,
      contract,
      reference,
      asPromise: true,
      // asMutation: true,
      mutations: {
        getRequestKey: ({ meta }: AddToCartActionType) =>
          String(meta.offer.itemKey),
        [FETCH_CART]: {
          updateData: (state: StateType, { meta, data }: any) => {
            const cartItem = CartItemRecord(data)

            return state.data
              .toMap()
              .mapKeys((_key, item) => String(item.id))
              .merge({
                [String(cartItem.id!)]: cartItem,
              })
              .toList()
          },
        },
      },
    },
  }
}

// const addCart = (cart, data) => cart.push(CartItem(data))

// const updateCart = (cart, cartItemId, changes) =>
//   cart
//     .toMap()
//     .mapKeys((key, item) => item.id)
//     .update(cartItemId, item => item.merge(changes))
//     .toList()

// const removeCart = (cart, cartItemId) =>
//   cart
//     .toMap()
//     .mapKeys((key, item) => item.id)
//     .remove(cartItemId)
//     .toList()

export function updateCartItemAction(
  cartItem: CartItemType,
  changes: Partial<CartItemType>
): RequestAction {
  return {
    type: UPDATE_CART_ITEM,
    // request: false,
    request: {
      url: `/cart/update/${cartItem.id}`,
      method: 'post',
      data: changes,
    },
    meta: {
      cartItem,
      changes,
      // asPromise: true,
      takeLatest: true,
      mutations: {
        getRequestKey: (requestAction: RequestAction) => String(cartItem.id),
        [FETCH_CART]: {
          // local: true,
          updateData(state: StateType, { meta, data }: any) {
            const index = state.data.findIndex(
              (cartItem) => cartItem.id === meta.cartItem.id
            )

            return state.data.update(index, (cartItem) =>
              cartItem.merge(meta.changes)
            )
            // return state.data
          },
          // [FETCH_USER]: {
          //   updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
          //     return data.update('cart', cart =>
          //       updateCart(cart, cartItem.id, changes)
          //     )
          //   },
          //   revertData: ({ data }, { meta: { cartItem } }) => {
          //     return data.update('cart', cart =>
          //       updateCart(cart, cartItem.id, cartItem)
          //     )
          //   },
        },
      },
    },
  }
}

export function removeCartItemAction(cartItem: CartItemType): RequestAction {
  return {
    type: REMOVE_CART_ITEM,
    // request: false,
    request: {
      url: `/cart/remove/${cartItem.id}`,
    },
    meta: {
      cartItem,
      // local: true,
      //       asPromise: true,
      //       mutations: {
      //         getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
      //         [FETCH_USER]: {
      //           updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
      //             return data.update('cart', cart => removeCart(cart, cartItem.id))
      //           },
      //           revertData: ({ data }, { meta: { cartItem } }) => {
      //             return data.update('cart', cart => addCart(cart, cartItem))
      //           },
      //         },
      //       },
    },
  }
}

// export function createOrder({
//   cartItems,
//   deliveryType,
//   paymentMethod,
//   ...props
// }) {
//   return {
//     type: CREATE_ORDER,
//     request: {
//       url: '/orders/create',
//       method: 'post',
//       data: {
//         ...props,
//         contractId: cartItems[0].contract.id,
//         deliveryType: { id: '001' },
//         paymentMethod: { id: paymentMethod },
//         items: cartItems.map(item => pick(item, ['id'])),
//       },
//     },
//     meta: {
//       cartItems,
//       asPromise: true,
//       mutations: {
//         // getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
//         [FETCH_USER]: {
//           updateDataOptimistic: (
//             { data },
//             { meta: { cartItems, changes } }
//           ) => {
//             const idList = map(cartItems, 'id')

//             return data.update('cart', cart =>
//               cart.filterNot(cartItem => includes(idList, cartItem.id))
//             )
//           },
//           revertData: ({ data }, { meta: { cartItem } }) => {
//             return data.update('cart', cart => addCart(cart, cartItem))
//           },
//           // local: true,
//         },
//       },
//     },
//   }
// }

export function fetchCartOffersAction(): RequestAction {
  return {
    type: FETCH_OFFERS,
    request: {
      url: '/cart/offers',
    },
    meta: {
      resetOn: [CLEAR_OFFERS],
      getData: (_state, action) => OfferRecordsCollection(action.data),
    },
  }
}
export function clearCartOffersAction() {
  return {
    type: CLEAR_OFFERS,
  }
}
type CreateOrderActionProps = {
  contractId: string
  paymentMethod: string
  deliveryType: string
  customerComment?: string
  deliveryAddress?: string
  pickPoint?: string
  items: CartItemType[]
}
type CreateOrderAction = RequestAction & {
  meta: RequestActionMeta & {
    items: CartItemType[]
  }
}
export function createOrderAction({
  items,
  deliveryType,
  paymentMethod,
  customerComment,
  deliveryAddress,
  pickPoint,
  contractId,
  ...props
}: CreateOrderActionProps): RequestAction {
  return {
    type: CREATE_ORDER,
    // request: false,
    request: {
      url: '/orders/create',
      method: 'post',
      data: {
        contractId,
        deliveryType: { id: deliveryType },
        paymentMethod: { id: paymentMethod },
        deliveryAddress,
        pickPoint: pickPoint ? { id: pickPoint } : undefined,
        // items: items.map(({ id }) => ({ id })),
        items: items.map(pick('id')),
        customerComment,
      },
    },
    meta: {
      items,
      asPromise: true,
      mutations: {
        // getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
        [FETCH_CART]: {
          updateData({ data }: StateType, { meta }: CreateOrderAction) {
            const ids = meta.items.map(get('id'))
            // const ids = meta.items.map(item => item.id)
            return data.filterNot((item) => includes(item.id, ids))
          },
          // local: true,
        },
      },
    },
  }
}
