import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ApiProvider } from '@monorepo/core/api_module'
import { AuthProvider } from '@monorepo/core/auth_module'
import { UserProvider } from '@monorepo/core/user_module'

export { useClient } from '@monorepo/core/api_module'
export { useAuth } from '@monorepo/core/auth_module'
export { useUser } from '@monorepo/core/user_module'

const queryClient = new QueryClient()

type AppProviderProps = {
  //
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ApiProvider>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </ApiProvider>
    <ReactQueryDevtools initialIsOpen />
  </QueryClientProvider>
)
