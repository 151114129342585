import React, { useContext } from 'react'

import { AuthContext } from './auth.context'
import { useBaseAuth } from './useBaseAuth'

export const AuthProvider: React.FC = ({ children }) => {
  const authContext = useBaseAuth()
  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
