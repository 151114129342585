import { QueryState } from 'redux-saga-requests'
import { Record, RecordOf } from 'immutable'

import { OrderItemRecordsCollection } from '@monorepo/core/records'
import { OrderType } from '@monorepo/interfaces'

interface DataType {
  items: Array<OrderType>
}

interface DataRecordType extends Omit<DataType, 'items'> {
  items: any //OrderItemRecordsCollection
}

export const DataRecord = Record<DataRecordType>({
  items: OrderItemRecordsCollection(),
})

export type DataRecord = RecordOf<DataRecordType>

export type StateType = QueryState<DataRecord>
