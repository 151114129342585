import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'

export type AccountsProps = {
  accounts: Array<number>
}

export type OptionsProps = {
  clickmap: boolean
  trackLinks: boolean
  accurateTrackBounce: number
  webvisor: boolean
  defer: boolean
}

export interface YandexMetrikaProps {
  accounts: Array<number>
  options?: OptionsProps
}

// export type YandexMetrikaProps = AccountsProps & YMConfig

const YandexMetrika: React.FC<YandexMetrikaProps> = ({ accounts, options }) => (
  <YMInitializer accounts={accounts} options={options} />
)

export default YandexMetrika
