import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => [
  {
    path: '/blog',
    // component: lazyPage(() => import('../../containers/Blog/BlogPostsList')),
    component: lazyPage(() =>
      import('App/View/Blog').then(({ PostsList }) => PostsList)
    ),
    exact: true,
  },
  {
    path: '/blog/:slug',
    // component: Post,
    component: lazyPage(() => import('App/View/Blog').then(({ Post }) => Post)),
    exact: true,
  },
]
