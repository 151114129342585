import { MODULE as PARENT } from '../constants'

export const KEY = 'garage'
export const MODULE = [...[PARENT], KEY]

export const FETCH_CARS = `${MODULE.join('/')}-fetch`
export const CLEAR_CARS = `${MODULE.join('/')}-clear`
export const CREATE_CAR = `${MODULE.join('/')}-create-car`
export const UPDATE_CAR = `${MODULE.join('/')}-update-car`
export const REMOVE_CAR = `${MODULE.join('/')}-remove-car`
