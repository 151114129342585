import jQuery from 'jquery'

type jQueryStatic = typeof jQuery

declare global {
  interface Window {
    jQuery: jQueryStatic
    $: jQueryStatic
  }
}
window.jQuery = window.$ = jQuery

export default jQuery
