import { useQuery } from 'react-query'

import { useApi } from '../useApi'
import { OrderIdType } from '../interfaces'

export function useOrder(orderId: OrderIdType) {
  const { fetchOrderById } = useApi()

  return useQuery(
    ['fetchOrder', orderId],
    () => fetchOrderById(String(orderId)),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )
}
