import { useClient } from '@monorepo/core/api_module'
import { IDeliveryMethod, IVendor } from '@monorepo/interfaces'
import { IDeliveryStatistics, IPickPoint } from './interfaces'

export function useApi() {
  const client = useClient()

  async function fetchDeliveryMethods() {
    const { data } = await client.get<IDeliveryMethod[]>('/delivery/types')
    return data
  }

  async function fetchDeliveryStatistics(vendor: IVendor) {
    const { data } = await client.get<IDeliveryStatistics>(
      `/vendor/delivery/statistics/${vendor.id}`
    )
    return data
  }

  async function fetchPickPoints() {
    const { data } = await client.get<IPickPoint[]>('/delivery/pickpoints')
    return data
  }

  return { fetchDeliveryMethods, fetchDeliveryStatistics, fetchPickPoints }
}
