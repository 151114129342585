import { useQuery } from 'react-query'

import { useApi } from './useApi'

import { IBrand } from '@monorepo/interfaces'

export function useInfo(brand?: IBrand, enabled: boolean = true) {
  const { fetchBrandInfo } = useApi()

  return useQuery(
    ['fetchBrandInfo', brand?.id],
    () => fetchBrandInfo(brand?.id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      enabled: Boolean(brand) && enabled,
    }
  )
}
