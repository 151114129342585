import React from 'react'

import { ErrorLayout } from './ErrorLayout'

type Error400Props = {
  //
}

export const Error400: React.FC<Error400Props> = () => (
  <ErrorLayout code="404" title="Страница не найдена">
    <p>
      Неправильно набран адрес или такой страницы на сайте больше не существует.
    </p>
  </ErrorLayout>
)
