import React from 'react'
import cn from 'classnames'
import MDSpinner from 'react-md-spinner'

import style from './style.module.scss'

const Loader = ({ className, ...props }) => (
  <div {...props} className={cn(className, style.container)}>
    <MDSpinner />
  </div>
)

export default Loader
