import { useClient } from '@monorepo/core/api_module'
import { IPaymentMethod } from '@monorepo/interfaces'

export function useApi() {
  const client = useClient()

  async function fetchPaymentMethods() {
    const { data } = await client.get<IPaymentMethod[]>('/payment_methods')
    return data
  }

  return { fetchPaymentMethods }
}
