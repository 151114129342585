import React from 'react'
import { Form } from 'react-bootstrap'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCircleNotch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons' // import { delay } from 'lodash'
// import 'csshake'

import { UserCredentialsType } from '@monorepo/interfaces'
import { CompositButton, Link } from 'App/Components'

const SigninSchema = Yup.object().shape({
  username: Yup.string().label('E-Mail').email().required(),
  password: Yup.string().label('Пароль').required(),
})

type PropsType = {
  handleLogin(credientals: UserCredentialsType): void
  error?: string
  closeHandler?(): void
}

const initialCredientals: UserCredentialsType = {
  username: '',
  password: '',
}

export const LoginForm: React.FC<PropsType> = ({
  handleLogin,
  error,
  closeHandler,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [inputRef])

  return (
    <Formik
      validationSchema={SigninSchema}
      initialValues={initialCredientals}
      onSubmit={handleLogin}
    >
      {({ errors, touched, isValid, isSubmitting }) => (
        <div className="widget_fast_contact_widget">
          <Form
            as={FormikForm}
            className="et-mailchimp-form mt-3"
            name="et-mailchimp-form"
          >
            <Form.Group className="mt-4 mb-4">
              <Field
                type="email"
                name="username"
                placeholder="Email"
                as={Form.Control}
                autoFocus
              />
              <ErrorMessage name="username">
                {(msg) => <span className="alert warning visible">{msg}</span>}
              </ErrorMessage>
            </Form.Group>
            <Form.Group className="mt-4 mb-4 clearfix">
              <Field
                type="password"
                as={Form.Control}
                name="password"
                placeholder="Пароль"
              />
              <ErrorMessage name="password">
                {(msg) => <span className="alert warning visible">{msg}</span>}
              </ErrorMessage>
              <Link className="float-right mt-1" to="/account/reset-password">
                <small>Забыли пароль?</small>
              </Link>
            </Form.Group>

            <div>
              <span className={`alert error ${error ? 'visible' : ''}`}>
                {error}
              </span>
            </div>

            {/* <div style={{ height: 30 }}>
              {authError && (
                <div className="et-mailchimp-error alert final error visible">
                  {authError}
                </div>
              )}
            </div> */}
            <div className="send-div mt-4 clearfix">
              <Link
                className="float-left"
                to="/account/registration"
                onClick={closeHandler}
              >
                Зарегистрироваться
              </Link>
              <CompositButton
                className="float-right"
                type="submit"
                disabled={isSubmitting}
              >
                Войти&nbsp;
                <FontAwesomeIcon
                  icon={
                    isSubmitting
                      ? faCircleNotch
                      : error
                      ? faTimes
                      : faArrowRight
                  }
                  spin={isSubmitting}
                  fixedWidth
                />
              </CompositButton>
              {/* <Button
                className="float-right"
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Войти&nbsp;
                <FontAwesomeIcon
                  icon={
                    isSubmitting
                      ? faCircleNotch
                      : error
                      ? faTimes
                      : faArrowRight
                  }
                  spin={isSubmitting}
                  fixedWidth
                />
              </Button> */}
              {/* <div className="sending" /> */}
            </div>
            {/* <div className="et-mailchimp-success alert final success">
              You have successfully subscribed to the newsletter.
            </div> */}
          </Form>
        </div>
      )}
    </Formik>
  )
}
