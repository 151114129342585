import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Parallax } from 'react-parallax'

import { CompositButton } from 'App/Components'
import style from './style.module.scss'
// import backgroundImage from 'App/Resources/images/map_dark-1.png'

type TopSectionProps = {
  //
}

export const TopSection: React.FC<TopSectionProps> = () => {
  const history = useHistory()

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      history.push('/account/registration')
    },
    [history]
  )

  return (
    <Parallax
      blur={{ min: 0, max: 2 }}
      bgImage={require('App/Resources/images/map_dark-1.png')}
      // bgImageAlt=""
      strength={200}
      style={{
        backgroundColor: 'var(--color1)',
        // backgroundBlendMode: 'soft-light',
      }}
      bgImageStyle={{
        mixBlendMode: 'soft-light',
      }}
    >
      <div
        style={{ paddingTop: 40, paddingBottom: 40 }}
        className="vc_row wpb_row vc_row-fluid vc_custom_1558615059402~ vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-829993"
      >
        <div className="container et-clearfix">
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-1 text-align-center vc-column-519022">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="et-icon large et-icon-680651">
                  <span className="el-icon far fa-envelope-open" />
                </div>
                <span className="et-gap et-clearfix et-gap-439281" />
              </div>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 text-align-none vc-column-511745">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div
                  className="et-heading text-align-left tablet-text-align-center mobile-text-align-center animate-false no-animation-type"
                  id="et-heading-58211"
                >
                  <span className="text-wrapper">
                    <span className="text">Зарегистрируйся</span>
                  </span>
                </div>
                <div
                  className="et-heading text-align-left tablet-text-align-center mobile-text-align-center animate-false no-animation-type"
                  id="et-heading-375874"
                >
                  <span className="text-wrapper">
                    <span className="text">
                      И получи гарантированную скидку на первый заказ
                    </span>
                  </span>
                </div>
                <span className="et-gap et-clearfix et-gap-308481 hide1280"></span>
              </div>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 text-align-center vc-column-611134">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div
                  // id="et-mailchimp-576420"
                  className={cn(
                    style.formContainer,
                    'et-mailchimp widget_mailchimp'
                  )}
                >
                  <form className="mailchimp-form" onSubmit={handleSubmit}>
                    <div>
                      <input
                        type="text"
                        className="field"
                        name="email"
                        placeholder="Email"
                        // style={inputStyle}
                      />
                      {/* <span className="alert warning">
                          Invalid or empty email
                        </span> */}
                    </div>
                    <div className="send-div">
                      <CompositButton>Зарегистрироваться</CompositButton>
                      {/* <div className="sending" /> */}
                    </div>
                    {/* <div className="et-mailchimp-success alert final success">
                        You have successfully subscribed to the newsletter.
                      </div> */}
                    {/* <div className="et-mailchimp-error alert final error">
                        Something went wrong. Your subscription failed.
                      </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  )
}
// export const TopSection: React.FC<TopSectionProps> = () => (
//   <div className="vc_row wpb_row vc_row-fluid vc_custom_1558615059402 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-829993">
//     <div className="container et-clearfix">
//       <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-1 text-align-center vc-column-519022">
//         <div className="vc_column-inner ">
//           <div className="wpb_wrapper">
//             <div className="et-icon large et-icon-680651">
//               <span className="el-icon far fa-envelope-open" />
//             </div>
//             <span className="et-gap et-clearfix et-gap-439281" />
//           </div>
//         </div>
//       </div>
//       <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 text-align-none vc-column-511745">
//         <div className="vc_column-inner ">
//           <div className="wpb_wrapper">
//             <div
//               className="et-heading text-align-left tablet-text-align-center mobile-text-align-center animate-false no-animation-type"
//               id="et-heading-58211"
//             >
//               <span className="text-wrapper">
//                 <span className="text">Зарегистрируйся</span>
//               </span>
//             </div>
//             <div
//               className="et-heading text-align-left tablet-text-align-center mobile-text-align-center animate-false no-animation-type"
//               id="et-heading-375874"
//             >
//               <span className="text-wrapper">
//                 <span className="text">
//                   И получи гарантированную скидку на первый заказ
//                 </span>
//               </span>
//             </div>
//             <span className="et-gap et-clearfix et-gap-308481 hide1280"></span>
//           </div>
//         </div>
//       </div>
//       <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 text-align-center vc-column-611134">
//         <div className="vc_column-inner ">
//           <div className="wpb_wrapper">
//             <div
//               id="et-mailchimp-576420"
//               className="et-mailchimp widget_mailchimp"
//             >
//               <div className="mailchimp-form">
//                 {/* <form
//                   className="et-mailchimp-form"
//                   name="et-mailchimp-form"
//                   action="//wp-admin/admin-post.php"
//                   method="POST"
//                 > */}
//                 <div>
//                   <input
//                     type="text"
//                     className="field"
//                     name="email"
//                     placeholder="Email"
//                     style={inputStyle}
//                   />
//                   {/* <span className="alert warning">Invalid or empty email</span> */}
//                 </div>
//                 <div className="send-div">
//                   <CompositButton to="/account/registration">
//                     Зарегистрироваться
//                   </CompositButton>
//                   {/* <input
//                     type="submit"
//                     className="button"
//                     value="Зарегистрироваться"
//                     name="subscribe"
//                     to="/account/registration"
//                   /> */}
//                   {/* <div className="sending"></div> */}
//                 </div>
//                 {/* <div className="et-mailchimp-success alert final success">
//                   You have successfully subscribed to the newsletter.
//                 </div>
//                 <div className="et-mailchimp-error alert final error">
//                   Something went wrong. Your subscription failed.
//                 </div> */}
//                 {/* </form> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )
