// import * as Yup from 'yup'
import { object, string } from 'yup'

export const getResetPasswordRequestSchema = () =>
  object().shape({
    email: string()
      .label('E-Mail')
      .email()
      .required(),
  })
