// export const MODULE = 'search'

// export const KEY: string = 'offers'
// export const FETCH_OFFERS: string = `${MODULE.join('/')}`
// export const FETCH_REMOTE_OFFERS: string = `${MODULE}/${KEY}-remote`
// export const FETCH_ALL_OFFERS: string = `${MODULE}/${KEY}-fetch-all`
// export const CLEAR_ALL_OFFERS: string = `${MODULE}/${KEY}-clear-all`
// export const SHOW_MORE_OFFERS: string = `${MODULE}/${KEY}-show-more`
// export const SHOW_ALL_OFFERS: string = `${MODULE}/${KEY}-show-all`
// export const HIDE_OFFERS: string = `${MODULE}/${KEY}-hide`
import { MODULE as PARENT } from '@monorepo/core/features/constants'

export const KEY = 'offers'
export const MODULE = [...[PARENT], KEY]
export const FETCH_OFFERS = `${MODULE.join('/')}-fetch`
export const CLEAR_OFFERS = `${MODULE.join('/')}-clear`
export const FETCH_SOURCE = `${MODULE.join('/')}/source-fetch`
export const FETCH_API = `${MODULE.join('/')}/api-fetch`
export const FETCH_SUGGESTIONS = `${MODULE.join('/')}/suggestions-fetch`
export const CLEAR_SUGGESTIONS = `${MODULE.join('/')}/suggestions-clear`

export const SHOW_MORE_OFFERS = `${MODULE.join('/')}-show-more`
export const SHOW_ALL_OFFERS = `${MODULE.join('/')}-show-all`
export const HIDE_OFFERS = `${MODULE.join('/')}-hide`
