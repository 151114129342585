import { cloneElement, createElement, isValidElement } from 'react'
import { compose } from 'recompose'
import loadable from '@loadable/component'
import { withRouter, RouteProps } from 'react-router-dom'
import pMinDelay from 'p-min-delay'

import configurableComponent from '@monorepo/core/utils/configurableComponent'

// const pMinDelay = require('p-min-delay')

// const AsyncPage = loadable(
//   (props: { page: string }) => import(`./${props.page}`),
//   {
//     fallback: createElement('div', null, 'Loading...'),
//   }
// )

const [withConfig, configure] = configurableComponent({
  delay: 0,
  pageLoader: undefined,
})

export { configure }

const loaded: { [key: string]: React.ReactElement } = {}

// const lazyPage = (loader: any) =>
//   withRouter(({ children, location, delay = 0, pageLoader, ...props }: any) => {
//     const componentKey = location.pathname.toLowerCase()
//     if (!!loaded[componentKey]) return cloneElement(loaded[componentKey], props)
//     // return createElement(pageLoader)
//     const Component = loadable<any>(
//       () =>
//         delay > 0 && !loaded[componentKey]
//           ? pMinDelay(loader(), delay)
//           : loader(),
//       {
//         fallback: pageLoader ? createElement(pageLoader) : undefined,
//       }
//     )
//     // const Component = createElement(AsyncPage, {page: })
//     // Component.load().then(() => {})

//     return (loaded[componentKey] = createElement(Component, props, children))
//   })

const lazyPage = (loader: () => Promise<any>) =>
  withRouter(
    ({
      children,
      location,
      delay = 0,
      pageLoader,
      ...props
    }: RouteProps & {
      delay?: number
      pageLoader?: any //React.ReactNode | undefined
    }) => {
      const componentKey = location
        ? location.pathname.toLowerCase()
        : undefined

      if (
        componentKey &&
        loaded[componentKey] &&
        isValidElement(loaded[componentKey])
      ) {
        return cloneElement(loaded[componentKey], props)
      }
      // return pageLoader
      const Component = loadable<any>(
        () =>
          delay > 0 && (!componentKey || !loaded[componentKey])
            ? pMinDelay(loader(), delay)
            : loader(),
        {
          fallback: isValidElement(pageLoader) ? pageLoader : undefined,
        }
      )

      return componentKey
        ? (loaded[componentKey] = createElement(Component, props, children))
        : createElement(Component, props, children)
    }
  )

export default compose(withConfig, lazyPage)
