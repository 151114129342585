import { castArray, trimStart, memoize } from 'lodash'
import { imageURL } from '@monorepo/core/config'

const getImagineUrl = <T extends string | string[]>(
  image: T,
  filter: string
): T =>
  image instanceof Array
    ? (image.map((image: string) =>
        getImagineUrl(image, filter)
      ) as typeof image)
    : (`${imageURL}/${filter}/${trimStart(
        image as string,
        '/'
      )}` as typeof image)

export default memoize(
  getImagineUrl,
  (image, filter) => castArray(image).join('') + filter
)
