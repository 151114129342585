import { useQuery } from 'react-query'

import { PickPointId } from '../interfaces'
import { useApi } from '../useApi'

export const usePickPointsList = () => {
  const { fetchPickPoints } = useApi()

  const { data, isFetching } = useQuery(
    'fetchPickPointsList',
    fetchPickPoints,
    {
      refetchOnWindowFocus: false,
    }
  )

  const findById = (id: PickPointId) =>
    data?.find((item) => String(item.id) === String(id))

  return { data, isFetching, findById }
}
