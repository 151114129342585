import React from 'react'

type PhoneLinkPropsType = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
  phone: string
}

export const PhoneLink: React.FC<PhoneLinkPropsType> = ({
  phone,
  children,
  ...props
}) => (
  <a href={`tel:${phone.replace(/[^+0-9]?/g, '')}`} {...props}>
    {children ?? phone}
  </a>
)
