import React from 'react'
import cn from 'classnames'
import { Button, Col, Row } from 'react-bootstrap'

import { App, useComponentVisible } from '@monorepo/hooks'
import { Currency } from '@monorepo/components/common'

import style from './style.module.scss'

type ContractsSwitcherProps = {
  //
}

export const ContractsSwitcher: React.FC<ContractsSwitcherProps> = () => {
  const {
    isComponentVisible,
    componentRef,
    toggleComponentHandler,
  } = useComponentVisible(false)
  const {
    currentContract,
    orderContracts,
    selectContractHandler,
  } = App.useContract()

  if (!currentContract) {
    return null
  }
  return (
    <div
      ref={componentRef}
      // id="currency-switcher-821167"
      className={cn(
        style.container,
        'currency-font-size-14 currency-switcher hbe hide-default-false hide-sticky-false hbe-right box-align-right'
      )}
    >
      <div
        onClick={toggleComponentHandler}
        className={cn(style.currencyToggle, 'hbe-toggle', {
          active: isComponentVisible,
        })}
        // style={{ fontSize: 14 }}
      >
        {currentContract.balance < 0 ? 'Долг' : 'Баланс'}:
        <span className="highlighted-currency">
          <Currency value={Math.abs(currentContract.balance)} />
        </span>
      </div>
      <Row
        className={cn('d-shadow', style.currencyList, {
          [style.active]: isComponentVisible,
        })}
        // style={{ width: '300px' }}
      >
        <Col id="alg_currency_selector">
          {orderContracts.map((item, idx) => (
            <Button
              key={idx}
              className="text-left text-monospace- text-muted-"
              variant="link"
              onClick={selectContractHandler.bind(null, item)}
            >
              {item.name}
              <br />
              <small>
                {item.balance < 0 ? 'Задолженность' : 'Баланс'}&nbsp;
                <Currency value={item.balance} />
              </small>
            </Button>
          ))}
        </Col>
      </Row>
    </div>
  )
}
