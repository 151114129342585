import { List, Record, RecordOf } from 'immutable'
import { parseISO } from 'date-fns'

import { PageBlockType } from '@monorepo/interfaces'
import { makeListNew } from '@monorepo/core/records/CollectionFactory'

export type PageBlockRecordPropsType = PageBlockType & {}

const defaultValues: PageBlockRecordPropsType = {
  id: undefined,
  title: '',
  description: '',
  slug: '',
  content: '',
  image: undefined,
  createdAt: undefined,
  updatedAt: undefined,
}

export type PageBlockRecord = RecordOf<PageBlockRecordPropsType>
export type PageBlockRecordsCollection = List<PageBlockRecord>

const makePageBlockRecord: Record.Factory<PageBlockRecordPropsType> = Record(
  defaultValues,
  'PageBlockRecord'
)

const mapper = ({
  createdAt,
  updatedAt,
  ...props
}: Partial<PageBlockType>): Partial<PageBlockRecordPropsType> => ({
  createdAt: createdAt ? parseISO(createdAt.toString()) : undefined,
  updatedAt: updatedAt ? parseISO(updatedAt.toString()) : undefined,
  ...props,
})

export const PageBlockRecord = (
  props: Partial<PageBlockType> = {}
): PageBlockRecord => makePageBlockRecord(mapper(props))

export const PageBlockRecordsCollection = makeListNew<
  PageBlockRecord,
  PageBlockType
>(PageBlockRecord)
