// import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'

import { FETCH_ORDERS } from './constants'
import { DataRecord } from './records'
import { createSelector } from 'reselect'

export const ordersSelector = createSelector(
  getQuery<DataRecord>({
    type: FETCH_ORDERS,
    defaultData: DataRecord(),
  }),
  ({ data, ...state }) => ({ data: data.toJS(), ...state })
  // state => {
  //   return state
  //   // return { data /*: data.toJS()*/, ...state }
  // }
)
