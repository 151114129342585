import { requestsReducer } from 'redux-saga-requests'
import { get } from 'lodash'

import {
  AUTHENTICATE,
  CLEAR_TOKENS,
  SET_TOKENS,
  SET_SWITCH_USER,
} from './constants'
import { StateRecord } from './records'
import { SetTokensActionType } from './actions'

export const authReducer = requestsReducer(
  {
    actionType: AUTHENTICATE,
    resetOn: [CLEAR_TOKENS],
    getDefaultData: () => StateRecord(),
    //     getData: (state, action, config) => {
    //       const { token, refresh_token: refreshToken } = action.data
    //       return new ReducerRecord({
    //         token,
    //         refreshToken,
    //       })
    //     },
    //     getError: (state, { error }, config) => {
    //       const status = get(error, 'response.status')
    //       return status === 401
    //         ? 'Неверный логин или пароль'
    //         : 'Произошла непревиденная ошибка'
    //     },
    getData(state, { data }) {
      return StateRecord(data)
    },
    getError: (state, { error }): string => {
      const status = get(error, 'response.status')
      return status === 401
        ? 'Неверный логин или пароль'
        : 'Произошла непревиденная ошибка'
    },
    mutations: {
      [SET_TOKENS]: {
        updateData(_, action) {
          const payload = (action as SetTokensActionType).meta.payload
          return StateRecord(payload)
        },
        local: true,
      },
      [SET_SWITCH_USER]: {
        updateData: ({ data }, { meta }) => {
          return data.merge({ switchUser: meta.payload })
        },
        local: true,
      },
    },
  }
  //   // createReducer({ data: new ReducerRecord() }, actionHandlers)
)
// export default reducer
