import React from 'react'

import { MainHeader } from './MainHeader'
import { MobileHeader } from './MobileHeader'

type PropsType = {
  logoImage: string
}

export const Header: React.FC<PropsType> = ({ logoImage }) => (
  <>
    <MainHeader logoImage={logoImage} />
    <MobileHeader logoImage={logoImage} />
  </>
)
