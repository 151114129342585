import * as yup from 'yup'

export const getChangeUserPasswordSchema = () => {
  return yup.object().shape({
    password: yup
      .string()
      .label('Текущий пароль')
      // .min(6)
      .matches(/[0-9a-zA-Z]/)
      .required(),
    plainPassword: yup.object().shape({
      first: yup
        .string()
        .label('Новый пароль')
        .min(6)
        .matches(/[0-9a-zA-Z]/)
        .required(),
      second: yup
        .string()
        .label('Подтверждение нового пароля')
        .sameAs(yup.ref('first'), 'Пароли должны совпадать'),
    }),
  })
}
