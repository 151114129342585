import * as Yup from 'yup'

import { OfferType } from '@monorepo/interfaces'

export const getAddToCartSchema = ({ offer }: { offer: OfferType }) =>
  Yup.object().shape({
    amount: Yup.number()
      .label('Количество')
      .min(offer.minAmount)
      .max(offer.amount)
      .required(),
    contract: Yup.string().required().label('Договор'),
    reference: Yup.string().label('Reference'),
  })
