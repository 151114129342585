import { connect } from 'react-redux'
import ym from 'react-yandex-metrika'
import {
  branch,
  compose,
  lifecycle,
  mapProps,
  renderNothing,
  withHandlers,
  withProps,
} from 'recompose'
import { isEmpty, pick } from 'lodash'
import { createStructuredSelector } from 'reselect'
import { createBrowserHistory } from 'history'

import { metrics } from '@monorepo/core/config'
import { userSelector } from '@monorepo/core/features/user'
import { UserType } from '@monorepo/interfaces'

import YandexMetrika, {
  OptionsProps,
  YandexMetrikaProps,
} from './YandexMetrika'

const history = createBrowserHistory()

const ymConfig: OptionsProps = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: 5,
  webvisor: true,
  defer: true,
}

type ContainerProps = {
  history: any
  user: { data: UserType }
} & YandexMetrikaProps

type ContainerHandlers = {
  urlChangeHandler: () => void
  applyUser: () => void
}

const mapStateToProps = createStructuredSelector({
  user: userSelector,
})

const withConnect = connect(mapStateToProps)

const enhancer = compose<YandexMetrikaProps, {}>(
  withConnect,
  withProps<{ history: any } & YandexMetrikaProps, {}>({
    accounts: metrics.yandex.accounts,
    options: ymConfig,
    history,
  }),
  branch(
    ({ accounts }: YandexMetrikaProps) => isEmpty(accounts),
    renderNothing
  ),
  withHandlers<ContainerProps, ContainerHandlers>({
    urlChangeHandler: ({ history }) => () => {
      const { pathname, search } = history.location
      ym('hit', `${pathname}${search}`)
    },
    applyUser: ({ user }) => () => {
      const {
        isGuest,
        id,
        profile: { isWholesale },
      } = user.data
      if (!isGuest) {
        ym('setUserID', id)
        ym('userParams', { id, isWholesale })
      }
    },
  }),
  lifecycle<any, any, any>({
    componentDidMount() {
      this.props.history.listen(this.props.urlChangeHandler)
      this.props.applyUser()
    },
    componentDidUpdate() {
      this.props.applyUser()
    },
  }),
  mapProps<YandexMetrikaProps, any>((props) =>
    pick(props, 'accounts', 'options')
  )
)
export default enhancer(YandexMetrika)
