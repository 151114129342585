// import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
// import { isImmutable } from 'immutable'
import { get /*find, toLower*/ } from 'lodash'

// import makeStateSelector from '../../utils/makeStateSelector'
import { MODULE } from './constants'
import {
  // ReducerRecord,
  // StateRecord,
  // StateRecordType,
  StateType,
} from './records'

// const stateSelector = makeStateSelector(MODULE, ReducerRecord)

// export const querySelector = createSelector(
//   stateSelector,
//   ({ data }: any) => data.query
// )

export const stateSelector = getQuery<StateType>({
  requestSelector: (state: any) => {
    const { data, ...rest } = get(state, MODULE, {})

    return {
      ...rest,
      data: data.toJS(),
    }
  },
})

// const requestStateSelector = createSelector(
//   getQuery<StateRecordType>({
//     type: FETCH_SUGGESTIONS,
//   }),
//   ({ data, ...state }) => {
//     return {
//       ...state,
//       data: (data || StateRecord()).toJS(),
//     }
//   }
// )

// const stateSelector = createSelector()

// export const resultSelector = createSelector(stateSelector, state => state)

// export const resultSelectorOld = createSelector(
//   stateSelector,
//   ({ data, ...state }) => ({
//     ...state,
//     data: isImmutable(data) ? data.valueSeq().toJS() : [],
//   })
// )

// export const selectSuggest = createSelector(
//   resultSelector,
//   querySelector,
//   ({ data: goods }, query) =>
//     find(goods, good => {
//       return toLower(query) === toLower(good.name)
//     }) || query
// )
