import React, { useContext } from 'react'

import { UserContext } from './user.context'
import { useCurrentUser } from './useCurrentUser'

export const UserProvider: React.FC = ({ children }) => {
  const userContext = useCurrentUser()
  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}
