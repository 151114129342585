import qs from 'qs'

import { AbstractProduct } from '@monorepo/hooks/Order'
import { IGood } from '@monorepo/interfaces'

type UrlType = 'search' | 'catalog'

export function getSearchUrl(good: AbstractProduct | IGood) {
  let { brandName, brand, number } = good

  if (typeof brand?.name !== 'undefined') {
    brandName = brand.name
  }
  return `/search?${qs.stringify({ brandName, number })}`
}

export function getCatalogUrl(good: AbstractProduct | IGood) {
  let { brandName, brand, number } = good

  if (typeof brand?.name !== 'undefined') {
    brandName = brand.name
  }
  return `/goods/${brandName}/${number}`
}

export function getUrl(
  good: AbstractProduct | IGood,
  type: UrlType = 'search'
) {
  switch (type) {
    case 'search':
      return getSearchUrl(good)
    case 'catalog':
      return getCatalogUrl(good)
    default:
      return getSearchUrl(good)
  }
}
