import { List, Map, RecordOf } from 'immutable'
import { get, isFunction } from 'lodash'

export const makeList = <T>(factory: (props: T) => RecordOf<T>) => (
  items: Array<T> = [],
  itemProps?: Partial<T>
): List<RecordOf<T>> =>
  List(items.map((item: T) => factory(Object.assign({}, item, itemProps))))

export const makeMap = <T>(
  factory: (props: T) => RecordOf<T>,
  idProperty: keyof T | ((item: T) => string)
) => (
  items: Array<T> = [],
  itemProps?: Partial<T>
): Map<typeof idProperty, RecordOf<T>> =>
  Map(
    items.map((item: any, index: number) => [
      isFunction(idProperty)
        ? idProperty(item)
        : get(item, idProperty || 'id', index),
      factory(Object.assign({}, item, itemProps)),
    ])
  )

export function makeMapNew<TOuter, TInner>(
  factory: (props: TInner) => TOuter,
  idProperty?: keyof TInner | ((item: TInner) => string)
  // idProperty?: string | ((item: TInner) => string)
) {
  return (
    items: Array<TInner> = [],
    itemProps?: Partial<TInner>
  ): Map<string, TOuter> =>
    Map(
      items.map((item, index) => [
        isFunction(idProperty)
          ? idProperty(item)
          : get(item, idProperty || 'id', index),
        factory(Object.assign({}, item, itemProps)),
      ])
    )
}

export function makeListNew<TOuter, TInner>(
  factory: (props: TInner) => TOuter
) {
  return (
    items: Array<TInner> = [],
    itemProps?: Partial<TInner>
  ): List<TOuter> =>
    List(items.map((item) => factory(Object.assign({}, item, itemProps))))
}
