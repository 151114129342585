import React from 'react'
import { FormattedMessage } from 'react-intl'

export const messages = {
  mixed: {
    default: (values: any) => (
      <FormattedMessage id="yup.mixed.default" values={values} />
    ),
    required: (values: any) => (
      <FormattedMessage id="yup.mixed.required" values={values} />
    ),
  },
  number: {
    min: (values: any) => (
      <FormattedMessage id="yup.number.min" values={values} />
    ),
    max: (values: any) => (
      <FormattedMessage id="yup.number.max" values={values} />
    ),
    multiplicity: (values: any) => (
      <FormattedMessage id="yup.number.multiplicity" values={values} />
    ),
  },
  string: {
    min: (values: any) => (
      <FormattedMessage id="yup.string.min" values={values} />
    ),
    max: (values: any) => (
      <FormattedMessage id="yup.string.max" values={values} />
    ),
    phoneNumber: (values: any) => (
      <FormattedMessage id="yup.string.phoneNumber" values={values} />
    ),
    vinNumber: (values: any) => (
      <FormattedMessage id="yup.string.vinNumber" values={values} />
    ),
    validate: (values: any) => (
      <FormattedMessage id="yup.mixed.validate" values={values} />
    ),
  },
}
