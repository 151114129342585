import { useQuery } from 'react-query'

import { useApi } from './useApi'

import { IOffer } from '@monorepo/interfaces'

export function useInfo(offer?: IOffer, enabled: boolean = true) {
  const { fetchPriceInfo } = useApi()

  return useQuery(
    ['fetchPriceInfo', offer?.itemKey],
    () => fetchPriceInfo(offer?.itemKey),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      enabled: Boolean(offer) && enabled,
    }
  )
}
