import {
  delay,
  // call,
  put,
  putResolve,
  // select,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects'
import { success } from 'redux-saga-requests'
import { replace } from 'connected-react-router'
import { get } from 'lodash'

import { LOGOUT, authenticateAction } from '../auth'
// import { authenticateAction, logoutAction } from '../auth'
import {
  // FETCH_USER,
  // LOGIN_USER,
  // LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
} from './constants'
import { clearUserAction } from './actions'
// import { userSelector } from './selectors'

function* watchLogout() {
  yield put(clearUserAction())
}

// function* watchSuccessFetchUser() {
//   yield delay(10)
//   yield put(applyUserRoles())
// }

// function* watchLoginUser({ meta: { credientals, onSuccess, onFailure } }) {
//   try {
//     yield putResolve(authenticateAction(credientals))
//     yield putResolve(fetchUserAction())
//     const user = yield select(userSelector)
//     yield call(onSuccess, user.data)
//   } catch (error) {
//     yield call(onFailure)
//   }
// }

function* watchUserRegistration({ meta: { profile } }) {
  const credientals = {
    username: profile.email,
    password: profile.plainPassword.first,
  }
  try {
    yield putResolve(authenticateAction(credientals))
    yield delay(100)
    yield put(replace('/'))
  } catch (error) {}
}

// function* watchLogoutUser() {
//   yield putResolve(logoutAction())
// }

function* watchSuccessResetPassword({ data, meta }) {
  const username = get(data, 'user.email')
  const password = get(meta, 'password')

  if (username && password) {
    const credientals = { username, password }
    yield delay(100)
    yield put(authenticateAction(credientals))
    yield put(replace('/'))
  }
}
function* watchSuccessResetPasswordRequest({ data, meta }) {
  yield delay(100)
  yield put(replace('/'))
}

// function* watchSuccessAuthentication() {
//   yield put(fetchUserAction())
// }

export default function* saga() {
  yield takeLeading(LOGOUT, watchLogout)
  // yield takeLatest(success(FETCH_USER), watchSuccessFetchUser)
  // yield takeLatest(LOGIN_USER, watchLoginUser)
  // yield takeLeading(LOGOUT_USER, watchLogoutUser)
  yield takeLatest(success(REGISTER_USER), watchUserRegistration)
  yield takeLatest(success(RESET_PASSWORD), watchSuccessResetPassword)
  yield takeLatest(
    success(RESET_PASSWORD_REQUEST),
    watchSuccessResetPasswordRequest
  )

  // yield takeLatest(success(AUTHENTICATE), watchSuccessAuthentication)
}
