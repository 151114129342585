import React from 'react'
import cn from 'classnames'

import style from './style.module.scss'

type PageTitleProps = React.HTMLAttributes<HTMLElement> & {
  subTitle?: React.ReactNode
}

export const PageTitle: React.FC<PageTitleProps> = ({
  className,
  children,
  subTitle,
}) => (
  // <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none vc-column-712819">
  //   <div className="vc_column-inner ">
  //     <div className="wpb_wrapper">
  <>
    <h1
      className={cn(
        'et-heading',
        'text-align-left',
        'tablet-text-align-inherit',
        'mobile-text-align-inherit',
        'animate-false',
        'no-animation-type',
        className,
        style.title
      )}
    >
      <span className="text-wrapper">
        <span className="text">{children}</span>
      </span>
    </h1>
    {subTitle && (
      <p className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type">
        <span className="text-wrapper">
          <span className="text">{subTitle}</span>
        </span>
      </p>
    )}
    <div className="et-separator et-clearfix animate-false solid left horizontal et-separator-89771">
      <div className="line" />
    </div>
  </>
  //     </div>
  //   </div>
  // </div>
)
