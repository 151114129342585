import React from 'react'

interface AutoSubmitProps<T> {
  values: T
  isValid: boolean
  dirty: boolean
  onChange(values: T): void
  submitForm(): void
}

export const AutoSubmit: React.FC<AutoSubmitProps<any>> = <
  T extends AutoSubmitProps<T>
>({
  values,
  isValid,
  dirty,
  onChange,
  submitForm,
}: T) => {
  React.useEffect(() => {
    isValid ? submitForm() : onChange(values)
  }, [values, submitForm, isValid, onChange, dirty])

  return null
}
