import React from 'react'

import { CartItemType } from '@monorepo/interfaces'
import { Image, LightBox } from '@monorepo/components/common'
import { Link, ImagePlaceholder } from 'App/Components'
import Currency from '@monorepo/components/common/Currency/Currency'
// import { getImagineUrl } from '@monorepo/core/helpers'

type PropsType = {
  item: CartItemType
  onRemove(): void
}

export const CartItem: React.FC<PropsType> = ({ item, onRemove }) => (
  <li className="woocommerce-mini-cart-item mini_cart_item">
    <button
      onClick={onRemove}
      className="remove default-button"
      aria-label="Удалить позицию?"
    >
      ×
    </button>{' '}
    <div className="cart-product-body et-clearfix">
      <div className="cart-product-image">
        {item.good.images && item.good.images.length > 0 ? (
          <LightBox images={item.good.images}>
            <Image
              height={75}
              width={75}
              src={item.good.thumbs}
              className="img-thumbnail"
              lazy={false}
              unloader={<ImagePlaceholder />}
            />
          </LightBox>
        ) : (
          <ImagePlaceholder height={75} width={75} className="img-thumbnail" />
        )}
      </div>
      <div className="cart-product-content">
        <h6 className="cart-product-title">
          <Link to="/">{item.good.name}</Link>
        </h6>
        <div className="cart-product-data">
          <ul className="cart-data">
            <li>
              {/* <span className="attribute">Кол-во:</span> */}
              {/* <span> */}
              {item.amount} ×&nbsp;
              <span className="woocommerce-Price-amount amount">
                <Currency value={item.price} />
                {/* <span className="woocommerce-Price-currencySymbol">£</span>
                  191.77 */}
              </span>
              {/* </span> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
)
