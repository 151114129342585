import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import { OfferType } from '@monorepo/interfaces'
import { FETCH_INFO, CLEAR_INFO } from './constants'
import { StateType } from './types'

type FetchPriceInfoActionProps = {
  offer: OfferType
}
export function fetchPriceInfoAction({
  offer,
}: FetchPriceInfoActionProps): RequestAction {
  return {
    type: FETCH_INFO,
    request: {
      url: `/offer/price-calculation-info/${offer.itemKey}`,
    },
    meta: {
      resetOn: [CLEAR_INFO],
      cache: 60,
      cacheKey: String(offer.itemKey),
      getData: (_state, action) => {
        return action.data as StateType
      },
    },
  }
}

export function clearPriceInfoAction(): Action {
  return {
    type: CLEAR_INFO,
  }
}
