import { useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  recentPostSelector,
  clearPostsAction,
  fetchRecentPostsAction,
} from '@monorepo/core/features/BlogPost'

export function useRecentPosts(count?: number) {
  const dispatch = useDispatch()

  const fetchRecentPosts = useCallback(
    bindActionCreators(fetchRecentPostsAction, dispatch),
    []
  )

  const clearPosts = useCallback(
    bindActionCreators(clearPostsAction, dispatch),
    []
  )

  const { loading, data: recentPosts } = useSelector(recentPostSelector)

  useEffect(() => {
    fetchRecentPosts({ count })
    return () => {
      clearPosts()
    }
  }, [clearPosts, count, fetchRecentPosts])

  return {
    recentPosts,
    loading,
  }
}
