import { call, put, select, putResolve, takeEvery } from 'redux-saga/effects'
import { getItem, setItem, removeItem } from 'redux-effects-localstorage'
import { success /*, sendRequest*/ } from 'redux-saga-requests'
// import { Redirect, useLocation, useParams } from 'react-router'
import qs from 'qs'
import { get, has, startsWith, trim } from 'lodash'
import {
  replace,
  LOCATION_CHANGE,
  getLocation,
  getSearch,
} from 'connected-react-router'

import {
  AUTHENTICATE,
  INITIALIZE,
  SET_TOKENS,
  TOKEN,
  REFRESH_TOKEN,
  SWITCH_USER,
  LOGOUT,
  CLEAR_TOKENS,
} from './constants'
import {
  setTokensAction,
  clearTokensAction,
  switchUserAction,
  logoutAction,
} from './actions'
import { fetchUserAction, userSelector } from '../user'
import { switchUserSelector } from './selectors'

function* watchInitialize() {
  const token = yield putResolve(getItem(TOKEN))

  // if (token) {
  const refreshToken = yield putResolve(getItem(REFRESH_TOKEN))
  yield put(setTokensAction({ token, refreshToken }))

  const search = yield select(getSearch)
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  const user = get(query, SWITCH_USER)

  if (user) {
    yield put(switchUserAction(user))
  }

  try {
    yield putResolve(fetchUserAction())
  } catch (error) {}
  // }

  yield call(watchLoginPage)
}

function* watchSetTokens(action) {
  const { token, refreshToken } = action.meta.payload

  if (token) {
    yield put(setItem(TOKEN, token))
  }
  if (refreshToken) {
    yield put(setItem(REFRESH_TOKEN, refreshToken))
  }
}

function* watchClearTokens() {
  yield put(removeItem(TOKEN))
  yield put(removeItem(REFRESH_TOKEN))
}

function* watchSuccessAuthentication(action) {
  const { token, refresh_token: refreshToken } = action.data
  yield put(setTokensAction({ token, refreshToken }))

  yield putResolve(fetchUserAction())

  yield call(watchLoginPage)
}

function* watchLogout() {
  yield put(clearTokensAction())
}
const switchUserKey = '_switch_user'

function* watchLocationChange(action) {
  const switchUser = yield select(switchUserSelector)
  const location = yield select(getLocation)

  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  if (startsWith(trim(location.pathname, '/'), 'account/logout')) {
    yield put(logoutAction())
    return
  }

  if (switchUser && !has(query, switchUserKey)) {
    Object.assign(query, {
      [switchUserKey]: switchUser,
    })

    const search = qs.stringify(query, { addQueryPrefix: true })
    Object.assign(location, {
      query,
      search,
    })
    yield put(replace(location))
  }
}

function* watchLoginPage() {
  const location = yield select(getLocation)
  const { data: user } = yield select(userSelector)

  if (
    startsWith(trim(location.pathname, '/'), 'account/login') &&
    !user.isGuest
  ) {
    const referrer = get(location.state, 'referrer', '/')

    yield put(replace(referrer))
  }
}

export function* authSaga() {
  yield takeEvery(INITIALIZE, watchInitialize)
  yield takeEvery(SET_TOKENS, watchSetTokens)
  yield takeEvery(LOGOUT, watchLogout)
  yield takeEvery(CLEAR_TOKENS, watchClearTokens)
  yield takeEvery(success(AUTHENTICATE), watchSuccessAuthentication)
  yield takeEvery(LOCATION_CHANGE, watchLocationChange)
}
