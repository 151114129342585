import React from 'react'

import { Link } from 'App/Components'
import cn from 'classnames'

import style from './style.module.scss'

export type CompositButtonVariantType = 'red' | 'light' | 'dark'
export type CompositButtonSizeType = 'small' | 'medium' | 'large'

type CompositButtonProps = Partial<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> & {
  to?: string
  variant?: CompositButtonVariantType
  size?: CompositButtonSizeType
}

export const CompositButton: React.FC<CompositButtonProps> = ({
  to,
  children,
  style: componentStyle,
  className,
  size = 'medium',
  variant = 'red',
  ...props
}) => {
  const inner = (
    <>
      <span className={cn(style.hover, style[`${variant}Hover`])} />
      <span className={cn(style.regular, style[`${variant}Regular`])} />
      <span className="text">{children}</span>
    </>
  )

  return to ? (
    <Link
      className={cn(
        className,
        style.container,
        style.hoverFill,
        size,
        'et-button icon-position-left modal-false hover-fill click-none'
      )}
      style={componentStyle}
      to={to}
    >
      {inner}
    </Link>
  ) : (
    <button
      {...props}
      className={cn(
        className,
        style.container,
        style.hoverFill,
        size,
        'et-button icon-position-left modal-false hover-fill click-none'
      )}
      style={componentStyle}
    >
      {inner}
    </button>
  )
}
