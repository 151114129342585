import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import {
  Pagination as BasePagination,
  PaginationPropsType,
} from '@monorepo/components/common'
import { IPagination } from '@monorepo/interfaces'

type PropsType = Partial<PaginationPropsType> & {
  meta: IPagination
  // totalPages: number
  pageRangeDisplayed?: number
  marginPagesDisplayed?: number
}

export const Pagination: React.FC<PropsType> = ({ meta, ...props }) => (
  <BasePagination
    {...props}
    meta={meta}
    containerClassName="pagination"
    activeClassName="active"
    pageClassName="page-item m-1"
    pageLinkClassName="page-link d-shadow"
    previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
    previousClassName="page-item m-1"
    previousLinkClassName="page-link d-shadow"
    nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
    nextClassName="page-item m-1"
    nextLinkClassName="page-link d-shadow"
    breakLabel="&nbsp;"
    breakClassName="page-item page-item--dots"
    breakLinkClassName="pagination__dots"
    // totalPages={meta.totalPages}
  />
)
