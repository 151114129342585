import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import {
  debounce,
  filter,
  intersection,
  isEqual,
  keys,
  pick,
  reduce,
} from 'lodash'

import {
  cartSelector,
  addCartItemAction,
  updateCartItemAction,
  removeCartItemAction,
} from '@monorepo/core/features/cart'
import { CartItemType } from '@monorepo/interfaces'

export function useCart() {
  const dispatch = useDispatch()

  const { data: cartItems } = useSelector(cartSelector)

  const selectedCartItems = useMemo(() => filter(cartItems, 'isSelected'), [
    cartItems,
  ])

  const totalItems = useMemo(
    () => reduce(cartItems, (acc, item) => acc + item.amount, 0) || 0,
    [cartItems]
  )

  const totalPrice = useMemo(
    () =>
      reduce(cartItems, (acc, item) => acc + item.amount * item.price, 0) || 0,
    [cartItems]
  )

  const selectedTotalPrice = useMemo(
    () =>
      reduce(
        filter(cartItems, 'isSelected'),
        (totalPrice, item) => totalPrice + item.amount * item.price,
        0
      ) || 0,
    [cartItems]
  )

  const addCartItemHandler = useCallback(
    bindActionCreators(addCartItemAction, dispatch),
    []
  )

  const updateCartItemHandler = (
    cartItem: CartItemType,
    changes: Partial<CartItemType>
  ) => {
    const propNames = intersection(keys(changes), keys(cartItem))
    const prevValues = pick(cartItem, propNames)
    const nextValues = pick(changes, propNames)
    const hasChanges = !isEqual(prevValues, nextValues)

    if (hasChanges) {
      try {
        dispatch(updateCartItemAction(cartItem, nextValues))
      } catch (error) {}
    }
  }

  const removeCartItemHandler = useCallback(
    bindActionCreators(removeCartItemAction, dispatch),
    []
  )

  return {
    cartItems,
    selectedCartItems,
    totalItems,
    totalPrice,
    selectedTotalPrice,
    addCartItemHandler,
    updateCartItemHandler: debounce(updateCartItemHandler, 1000),
    removeCartItemHandler,
  }
}
