import { useCallback, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { OrdersFilterType } from '@monorepo/interfaces'
import {
  fetchOrdersAction,
  clearOrdersAction,
  ordersSelector,
} from '@monorepo/core/features/Orders'

export function useOrdersList(initialFilter: OrdersFilterType = {}) {
  const [filter, setFilter] = useState<OrdersFilterType>(initialFilter)

  const dispatch = useDispatch()
  const { data, loading } = useSelector(ordersSelector)
  const { items: orders } = data

  const fetchOrders = useCallback(
    bindActionCreators(fetchOrdersAction, dispatch),
    []
  )

  const clearOrders = useCallback(
    bindActionCreators(clearOrdersAction, dispatch),
    []
  )

  useEffect(() => {
    fetchOrders(filter)
    return function () {
      clearOrders()
    }
  }, [clearOrders, fetchOrders, filter])

  return { orders, loading, setFilter }
}
