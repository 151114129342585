import React from 'react'
import cn from 'classnames'
import { useField } from 'formik'
import ReactDadataBox from 'react-dadata-box'

const token = '755d75a73c0df1f7088976ef2c2f562ff113ab80'

type FetchType =
  | 'address'
  | 'party'
  | 'bank'
  | 'email'
  | 'fio'
  | 'fms_unit'
  | undefined

type DadataFieldProps = {
  // name?: string
  // value: any
  // as?: any
  // className?: string
  fetchType?: FetchType
  // allowClear?: boolean
  // disabled?: boolean
} & any

export const DadataField: React.FC<DadataFieldProps> = ({
  allowClear,
  className,
  fetchType,
  disabled,
  ...props
}) => {
  const [field, , { setValue }] = useField(props)

  return (
    <ReactDadataBox
      allowClear={allowClear}
      {...props}
      {...field}
      type={fetchType}
      onChange={suggestion => {
        setValue(suggestion.value)
      }}
      token={token}
      query={props.value}
      customInput={({ onChange, ...props }) => (
        <input
          disabled={disabled}
          {...props}
          onChange={event => {
            setValue(event.target.value)
            onChange(event)
          }}
          className={cn(className, { 'pr-4': allowClear })}
        />
      )}
    />
  )
}
