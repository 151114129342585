import React from 'react'
import cn from 'classnames'
import gravatar from 'gravatar'

import { Link } from 'App/Components'
import { UserType } from '@monorepo/interfaces'

type UserButtonProps = {
  user: UserType
}

export const UserButton: React.FC<UserButtonProps> = ({ user }) => (
  <div
    id="header-icon-40835"
    className="header-icon hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right size-medium"
  >
    <Link
      to={user.isGuest ? '/account/login' : '/cabinet'}
      className={cn('hbe-toggle', 'hicon', 'enova', {
        'ien-euser-10': user.isGuest,
      })}
      // className={`hbe-toggle hicon enova ${
      //   user.isGuest ? 'ien-euser-10' : 'ien-euser-14'
      // }`}
    >
      {!user.isGuest && (
        <img
          width={30}
          height={30}
          className="rounded-circle"
          style={{ marginBottom: 3 }}
          src={gravatar.url(user.profile.email!, { default: 'mp' })}
          alt=""
        />
      )}
    </Link>
  </div>
)
