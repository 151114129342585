import React, { createContext, useContext, useMemo } from 'react'
import { Formik, FormikConfig, useFormikContext } from 'formik'

import { CartItemType } from '@monorepo/interfaces'
import { useOrderForm, FormValuesType } from './hooks/useOrderForm'
import { usePaymentMethodsList } from '@monorepo/hooks/Payment'
import { IPaymentMethod } from '@monorepo/interfaces'
import { useDeliveryMethodsList } from '@monorepo/hooks/Delivery'

interface IPlaceOrderContext {
  currentPaymentMethod?: IPaymentMethod
  paymentMethodsList?: IPaymentMethod[]
  currentDeliveryMethod?: any
  deliveryMethodsList?: any[]
}

const PlaceOrderContext = createContext<IPlaceOrderContext>({})

type OptionsType = {
  onSuccess?(): void
  onError?(): void
}

type PlaceOrderProviderProps = Partial<FormikConfig<any>> & {
  items: CartItemType[]
  options?: OptionsType
}

export const OrderProvider: React.FC<PlaceOrderProviderProps> = ({
  children,
  items,
  options,
}) => {
  const { bind } = useOrderForm(items, options)

  return (
    <Formik {...bind}>
      <FormContainer>{children}</FormContainer>
    </Formik>
  )
}

type FormContainerProps = {}

const FormContainer: React.FC<FormContainerProps> = ({ children }) => {
  const { values } = useFormikContext<FormValuesType>()
  const {
    findById: findPaymentMethodById,
    data: paymentMethodsList,
  } = usePaymentMethodsList()
  const {
    findById: findDeliveryMethodById,
    data: deliveryMethodsList,
  } = useDeliveryMethodsList()

  const ctxValues = useMemo(() => {
    const currentPaymentMethod = findPaymentMethodById(values.paymentMethod)
    const currentDeliveryMethod = findDeliveryMethodById(values.deliveryType)
    return {
      currentPaymentMethod,
      paymentMethodsList,
      currentDeliveryMethod,
      deliveryMethodsList,
    }
  }, [
    deliveryMethodsList,
    findDeliveryMethodById,
    findPaymentMethodById,
    paymentMethodsList,
    values.deliveryType,
    values.paymentMethod,
  ])

  return (
    <PlaceOrderContext.Provider value={ctxValues}>
      {children}
    </PlaceOrderContext.Provider>
  )
}

export function useForm() {
  return {
    ...useContext(PlaceOrderContext),
    form: useFormikContext<FormValuesType>(),
  }
}
