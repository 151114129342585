import { mapProps } from 'recompose'
import { isEmpty, replace } from 'lodash'

import Currency from './Currency'

function normalizeFormat(name) {
  const re = /[^а-яa-z]/gi
  return isEmpty(name) ? undefined : replace(name, re, '').toUpperCase()
}

export default mapProps(({ format, ...props }) => {
  return {
    ...props,
    format: normalizeFormat(format),
  }
})(Currency)
