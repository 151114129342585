import React from 'react'
import cn from 'classnames'

import { MenuType } from '@monorepo/interfaces'
import { Link, CompositButton } from 'App/Components'
// import { Menu } from '../Navigation/Menu'

import style from './style.module.scss'

type PropsType = {
  menu: MenuType
}

export const VehiclesButton: React.FC<PropsType> = ({ menu }) => (
  <div
    // id="et-header-button-749434"
    className={cn(
      style.container,
      'et-header-button hbe hbe-icon-element hide-default-false hide-sticky-false hbe-right submenu-appear-move submenu-toggle-hover submenu-shadow-true~ has-icon'
    )}
  >
    <CompositButton
      to="/cabinet"
      variant="dark"
      style={{ width: 220, height: 48 }}
    >
      <span className="icon fas fa-car" /> Кабинет
    </CompositButton>
    <div
      className="sub-menu megamenu d-shadow"
      data-width={30}
      data-position="right"
    >
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1556196258476 vc_row-has-fill stretch_row_content">
        <div className="tabset et-clearfix">
          {menu?.map((item, idx) => (
            <Link to={item.url} key={idx} external={item.external}>
              <div
                data-target="tab-item-batteries"
                className="false tab-item et-clearfix active"
              >
                <span className="txt">{item.name}</span>
                {/* <span className="icon fas fa-chevron-right" /> */}
              </div>
            </Link>
          ))}
        </div>
        {/* <div className="container et-clearfix">
          <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div
                  id="widget-user-vehicle-1"
                  className="widget widget_user_vehicle_widget"
                >
                  <div className="add-vehicle" data-user={1} data-shop>
                    <form name="et-add-vehicle" className="et-add-vehicle">
                      <div className="vehicle-header visible">
                        <h5>Vehicles</h5>
                        <p>Click to find parts for the selected vehicle:</p>
                        <Link to="/" className="save-vehicle">
                          Save vehicles to dashboard
                        </Link>
                      </div>
                      <ul className="user-vehicle-list">
                        <li data-car="181:236:2017:43">
                          <span className="remove ien-eclose-3" />
                          <Link to="/">
                            <h6>2017 Nissan Versa</h6>
                            <span>1.6 S 4dr Sedan (1.6L 4cyl 4A)</span>
                          </Link>
                        </li>{' '}
                      </ul>
                      <button
                        type="submit"
                        className="et-button button add-more-vehicle visible"
                      >
                        Add more vehicles
                      </button>
                      <div className="vehicle-form">
                        <div className="make filter-holder">
                          <select name="make">
                            <option className="default">Make</option>
                            <option value={78}>Audi</option>
                            <option value={82}>BMW</option>
                            <option value={97}>Cadillac</option>
                            <option value={103}>Chevrolet</option>
                            <option value={132}>Ford</option>
                            <option value={143}>Honda</option>
                            <option value={146}>Infinity</option>
                            <option value={153}>KIA</option>
                            <option value={162}>Lexus</option>
                            <option value={171}>Mazda</option>
                            <option value={174}>Mercedes Benz</option>
                            <option value={175}>Mitsubishi</option>
                            <option value={181}>Nissan</option>
                            <option value={217}>Subaru</option>
                            <option value={228}>Toyota</option>
                            <option value={237}>Volkswagen</option>
                          </select>
                          <span className="alert error">Choose make</span>
                        </div>
                        <div className="model filter-holder">
                          <select name="model">
                            <option className="default">Model</option>
                          </select>
                          <span className="alert error">Choose model</span>
                        </div>
                        <div className="year filter-holder">
                          <select name="year">
                            <option className="default">Year</option>
                          </select>
                          <span className="alert error">Choose year</span>
                        </div>
                        <div className="trim filter-holder">
                          <select name="trim">
                            <option className="default">Trim</option>
                          </select>
                          <span className="alert error">Choose trim</span>
                        </div>
                        <div>
                          <input
                            type="submit"
                            className="add-vehicle-button"
                            defaultValue="Add vehicle"
                            name="submit"
                          />
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="action"
                        defaultValue="widget_user_vehicle_update"
                      />
                      <input
                        type="hidden"
                        id="widget_user_vehicle_update_nonce"
                        name="widget_user_vehicle_update_nonce"
                        defaultValue="7369cdca70"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
)
