import { all, select, call, put } from 'redux-saga/effects'
import {
  createRequestInstance,
  watchRequests,
  getRequestInstance,
  sendRequest,
} from 'redux-saga-requests'
import { createDriver } from 'redux-saga-requests-axios'
import axios from 'axios'
import { get, set, memoize } from 'lodash'
import jwt, { TokenExpiredError } from 'jsonwebtoken'
import Fingerprint2 from 'fingerprintjs2'

import { apiURL } from '@monorepo/core/config'
import userSaga from './user/saga'
import { authSaga } from './auth'
import { cartSaga } from './cart'
import { contractSaga } from './Contract'
// import { searchSaga } from './SearchEngine'
// import { saga as cartSaga } from './app/cart'
// import { saga as contractSaga } from './app/contract'
import { saga as searchFieldSaga } from './searchField'
import { setTokensAction, clearTokensAction } from './auth/actions'
import {
  tokenSelector,
  refreshTokenSelector,
  switchUserSelector,
} from './auth/selectors'

import { deprecatedSagas } from '@monorepo/deprecated/core/features'

const axiosInstance = axios.create({
  // timeout: 5000,
  baseURL: apiURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

function expiredToken(token) {
  const payload = jwt.decode(token)
  const expiredAt = get(payload, 'exp', 0)
  return Date.now() / 1000 > expiredAt
}

function isApiAction(action) {
  return !get(action, 'meta.driver')
}

const getFingerprint = memoize(async () => {
  const components = await Fingerprint2.getPromise({})
  const values = components.map(({ value }) => value)
  const fingerprint = Fingerprint2.x64hash128(values.join(''), 31)

  return fingerprint
})

function* onRequestSaga(request, action) {
  if (!isApiAction(action)) {
    return request
  }
  const headers = get(request, 'headers', {})
  const token = yield select(tokenSelector)
  const switchUser = yield select(switchUserSelector)
  const fingerprint = yield call(getFingerprint)

  if (fingerprint) {
    set(headers, 'X-User', fingerprint)
  }

  if (token) {
    set(headers, 'Authorization', `Bearer ${token}`)
  }

  if (switchUser) {
    set(headers, 'x-switch-user', switchUser)
  }

  return {
    ...request,
    headers,
  }
}

function* onErrorSaga(error, action) {
  if (get(error, 'response.status') === 401 && isApiAction(action)) {
    const token = yield select(tokenSelector)

    if (!token || expiredToken(token)) {
      const refreshToken = yield select(refreshTokenSelector)
      const requestInstance = yield getRequestInstance()

      try {
        if (!refreshToken) {
          throw new TokenExpiredError()
        }
        const { data } = yield call(requestInstance.post, '/token/refresh', {
          refresh_token: refreshToken,
        })
        yield put(
          setTokensAction({
            token: data.token,
            refreshToken: data.refresh_token,
          })
        )

        return yield call(sendRequest, action, { silent: true })
      } catch (error) {
        // yield put(clearTokensAction())
        // return { error }
      }
    }
    yield put(clearTokensAction())
  }
  return { error }
}

export default function* rootSaga() {
  yield createRequestInstance({
    driver: {
      default: createDriver(axiosInstance),
      remote: createDriver(axios.create()),
    },
    onRequest: onRequestSaga,
    onError: onErrorSaga,
  })
  yield all([
    watchRequests(),
    authSaga(),
    userSaga(),
    cartSaga(),
    // searchSaga(),
    searchFieldSaga(),
    contractSaga(),
    ...deprecatedSagas.map((saga) => saga()),
  ])
}
