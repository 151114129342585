import React from 'react'
import cn from 'classnames'

import { Image } from '@monorepo/components/common'
import { CatalogType } from '@monorepo/interfaces'
import { Link, ImagePlaceholder } from 'App/Components'

import style from './style.module.scss'

// export type CatalogItemType = {
//   name: string
//   url: string
//   image?: any
//   category?: string | string[]
// }

type CatalogItemProps = React.HTMLAttributes<HTMLAnchorElement> & {
  catalog: CatalogType
}

export const CatalogItem: React.FC<CatalogItemProps> = ({
  catalog,
  onClick,
}) => (
  <div className={cn('vc_column-inner', style.container)}>
    <div className="wpb_wrapper">
      <Link to={catalog.mountPoint ?? catalog.url} onClick={onClick}>
        <div
          className="et-image aligncenter curtain-left d-flex justify-content-center align-items-center p-4"
          style={{
            height: 150,
            width: 150,
          }}
        >
          {catalog.image ? (
            <Image
              width={150}
              height={150}
              src={catalog.image}
              alt={catalog.name}
              // lazy={{ overflow: true }}
              lazy={false}
            />
          ) : (
            <ImagePlaceholder width={100} height={100} />
          )}
        </div>
      </Link>
      <Link
        className="d-block w-100 text-center font-weight-bold p-3"
        to={catalog.url}
        onClick={onClick}
      >
        {catalog.name}
        {/* <span className="hover" /> */}
        {/* <span className="regular" /> */}
        {/* <span className="text">{catalog.name}</span> */}
      </Link>
    </div>
  </div>
)
