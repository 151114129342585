import { createSelector } from 'reselect'
import { getQuery, QueryState } from 'redux-saga-requests'
import { memoize } from 'lodash'

import { DataType } from './types'
import { DataRecord } from './records'
import { FETCH_CAROUSEL } from './constants'
import { GoodType } from '@monorepo/interfaces'

const selectState = getQuery<DataType>({
  type: FETCH_CAROUSEL,
  defaultData: DataRecord(),
})

// export const carouselSelector = createSelector(
//   selectState,
//   ({ data, ...state }) =>
//     memoize((name: string) =>
//       (data || Map()).has(name) ? (data || Map()).get(name).toJS() : undefined
//     )
// )

export const getCarouselSelector = createSelector<
  any,
  any,
  (name: string) => QueryState<GoodType[]>
>(selectState, ({ data, ...state }) =>
  memoize((name: string) => ({
    ...state,
    data: data.has(name) ? data.get(name)!.toJS() : [],
  }))
)
