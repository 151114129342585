import { useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  setQueryAction,
  selectGoodAction,
  clearGoodsAction,
  stateSelector,
} from '@monorepo/core/features/searchField'

export function useGoodSuggestion() {
  const {
    data: { query, suggestions },
    loading,
  } = useSelector(stateSelector)

  const dispatch = useDispatch()

  const setQuery = useCallback(bindActionCreators(setQueryAction, dispatch), [])
  const handleSelect = useCallback(
    bindActionCreators(selectGoodAction, dispatch),
    []
  )
  const handleClear = useCallback(
    bindActionCreators(clearGoodsAction, dispatch),
    []
  )

  const handleChange = useCallback(
    async (query: string) => {
      try {
        await setQuery(query)
      } catch (error) {}
    },
    [setQuery]
  )

  return {
    query,
    loading,
    suggestions,
    handleChange,
    handleSelect,
    handleClear,
  }
}
