import React from 'react'
import { Placemark } from 'react-yandex-maps'

import { PickPointType } from '@monorepo/interfaces'
import { createIcon } from './create-icon'

type PickPointPlacemarkItemProps = {
  item: PickPointType
  isActive?: boolean
  onSelect?(): void
}

export const PickPointPlacemarkItem: React.FC<PickPointPlacemarkItemProps> = ({
  item,
  isActive = false,
  onSelect,
}) => (
  <Placemark
    geometry={[item.coords!.lat, item.coords!.lon]}
    properties={{
      // balloonContent
      // balloonContentHeader
      // balloonContentBody
      // balloonContentFooter
      [isActive ? 'iconContent' : 'iconCaption']: item.name,
      hintContent: `${item.address}<br /><strong>${item.name}</strong>`,
    }}
    options={{
      preset: isActive
        ? createIcon('red', 'stretchy', 'icon')
        : createIcon('blue', 'delivery', 'icon'),
    }}
    onClick={onSelect}
  />
)
