import React from 'react'

import { TopSection } from './TopSection'
import { MiddleSection } from './MiddleSection'
import { BottomSection } from './BottomSection'

type PropsType = {
  logoImage: string
}

export const MainHeader: React.FC<PropsType> = ({ logoImage }) => (
  <header
    id="et-desktop-31"
    className="header et-desktop et-clearfix transparent-false sticky-true shadow-true shadow-sticky-false mobile-false tablet-portrait-false tablet-landscape-false desktop-true"
    style={{
      color: 'var(--text-color1)',
    }}
  >
    <TopSection />
    <MiddleSection logoImage={logoImage} />
    <BottomSection />
  </header>
)
