import { get } from 'lodash'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import {
  faYoutube,
  faFacebookF,
  faTwitter,
  faInstagram,
  faVk,
} from '@fortawesome/free-brands-svg-icons'

import { SocialLinkType } from '@monorepo/interfaces'

const icons: {
  [name: string]: IconDefinition
} = {
  youtube: faYoutube,
  facebook: faFacebookF,
  twitter: faTwitter,
  instagram: faInstagram,
  vk: faVk,
}

export function getNetworkIcon(link: SocialLinkType): IconDefinition {
  return get(icons, link.name.toLocaleLowerCase())
}
