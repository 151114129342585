import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { get, filter, find } from 'lodash'

import { MODULE } from './constants'
import { ContractType } from '@monorepo/interfaces'
import { StateType } from './types'

export const contractsSelector = getQuery<ContractType[]>({
  requestSelector: (state: StateType) => {
    const { data, ...rest } = get(state, MODULE, {})

    return {
      ...rest,
      data: data.toJS() as ContractType[],
    }
  },
})

export const currentContractSelector = createSelector(
  contractsSelector,
  ({ data }) => find(data, 'isCurrent')
)

export const orderContractsSelector = createSelector(
  contractsSelector,
  ({ data }) => filter(data, 'isForOrder')
)
