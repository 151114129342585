import React from 'react'
import { FormattedNumber } from 'react-intl'

const defaultProps = {
  currency: 'RUB',
  style: 'currency',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const Currency = ({ ...props }) => (
  <FormattedNumber {...defaultProps} {...props} />
)

export default Currency
