import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import {
  PaginationOld as BasePagination,
  PaginationPropsType,
} from '@monorepo/components/common'

type PropsType = Partial<PaginationPropsType> & {
  totalPages: number
  pageRangeDisplayed: number
  marginPagesDisplayed: number
}

export const PaginationOld: React.FC<PropsType> = (props) => (
  <BasePagination
    containerClassName="pagination"
    activeClassName="active"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
    previousClassName="page-item"
    previousLinkClassName="page-link page-link--with-arrow"
    nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
    nextClassName="page-item"
    nextLinkClassName="page-link page-link--with-arrow"
    breakLabel="&nbsp;"
    breakClassName="page-item page-item--dots"
    breakLinkClassName="pagination__dots"
    {...props}
  />
)
