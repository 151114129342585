import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/interfaces'
import { useComponentVisible } from '@monorepo/hooks'
import { Link } from 'App/Components'
import { MenuItemsList } from '../MenuItemsList'

type MenuItemProps = {
  item: MenuItemType
}

export const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const { isComponentVisible, toggleComponentHandler } = useComponentVisible(
    false
  )

  const toggleHandler = React.useCallback(
    event => {
      event.preventDefault()
      toggleComponentHandler()
    },
    [toggleComponentHandler]
  )

  return (
    <li
      className={cn(
        'menu-item',
        'menu-item-type-taxonomy',
        'menu-item-object-product_cat',
        { 'menu-item-has-children': !!item.menu }
      )}
    >
      <Link to={item.url}>
        <span className="txt">{item.name}</span>
        <span
          className={cn('arrow-down', { active: isComponentVisible })}
          onClick={toggleHandler}
        />
      </Link>
      {item.menu && (
        <MenuItemsList
          items={item.menu}
          className={cn('sub-menu', { 'd-block': isComponentVisible })}
        />
      )}
    </li>
  )
}
