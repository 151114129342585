import { useClient } from '@monorepo/core/api_module'
import { ICompany } from '@monorepo/interfaces'

export function useApi() {
  const client = useClient()

  async function fetchOffices() {
    const { data } = await client.get<ICompany[]>('/offices')
    return data
  }

  return { fetchOffices }
}
