import { useMemo, useCallback } from 'react'

import { OfferType, ContractType } from '@monorepo/interfaces'
import { getAddToCartSchema } from '@monorepo/validations'

import { useCart } from './useCart'
import { useContract } from '../App'

type FormValuesType = {
  amount: number
  contract: ContractType
  reference: string
}

export function useAddToCartForm(offer: OfferType) {
  const { currentContract } = useContract()

  const initialValues: FormValuesType = useMemo(
    () => ({
      amount: offer.minAmount,
      contract: currentContract as ContractType,
      reference: '',
    }),
    [currentContract, offer.minAmount]
  )

  const validationSchema = useMemo(() => getAddToCartSchema({ offer }), [offer])
  const { addCartItemHandler } = useCart()

  const onSubmit = useCallback(addCartItemHandler.bind(null, offer), [])

  return {
    validationSchema,
    initialValues,
    onSubmit,
  }
}
