import React from 'react'
import cn from 'classnames'

import style from './style.module.scss'

import { App } from '@monorepo/hooks'
import { Link } from 'App/Components'
import { MenuItemsList } from './MenuItemsList'

import logoImage from 'App/Resources/images/Autoset_logo_1000_w.png'
import { MenuType } from '@monorepo/interfaces'

type MenuProps = {
  items: MenuType
  onHide?: () => void
}

export const Menu: React.FC<MenuProps> = ({ items, onHide }) => {
  const { company } = App.useCompany()

  return (
    <div
      className={cn(
        style.container,
        'mobile-menu-main-container mobile-container effect-left'
      )}
    >
      <div id="header-logo-880392" className="hbe header-logo hbe-left">
        <Link to="/" title={company.name}>
          <img className="logo" src={logoImage} alt={company.name} />
        </Link>
      </div>
      <div
        id="mobile-container-close-856662"
        className="mobile-container-close hbe hbe-icon-element hbe-right size-medium"
      >
        <div
          id="mobile-close-856662"
          className="mobile-close hbe-toggle ien-eclose-3"
          onClick={onHide}
        />
      </div>
      {/* <span className="et-gap et-clearfix et-gap-549073" />
        <div
          className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type"
          id="et-heading-501901"
        >
          <span className="text-wrapper">
            <span className="text">Автозапчасти</span>
          </span>
        </div> */}
      <span className="et-gap et-clearfix et-gap-549073" />
      <div
        id="mobile-menu-container-324351"
        className="mobile-menu-container hbe text-align-left"
      >
        <MenuItemsList
          items={items}
          // id="mobile-menu-324351"
          className={cn(
            style.itemsContainer,
            'mobile-menu hbe-inner et-clearfix'
          )}
        />
      </div>
    </div>
  )
}
