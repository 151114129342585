import React from 'react'
import { Clusterer, ClustererProps, YMapsApi } from 'react-yandex-maps'

import { PickPointType } from '@monorepo/interfaces'
import { PickPointPlacemarkItem } from './PickPointPlacemarkItem'
import { createIcon } from './create-icon'

export type PickPointPlacemarksProps = ClustererProps & {
  ymaps?: YMapsApi
  items: PickPointType[]
  value?: PickPointType
  onChange?(item?: PickPointType): void
}

export const PickPointPlacemarks: React.FC<PickPointPlacemarksProps> = ({
  ymaps,
  items,
  value,
  onChange,
  ...props
}) => (
  <Clusterer
    options={{
      preset: createIcon('inverted', 'gray', 'cluster', 'icons'),
      groupByCoordinates: false,
    }}
    {...props}
  >
    {items.map((item, idx) => (
      <PickPointPlacemarkItem
        key={idx}
        item={item}
        isActive={value && item.id === value?.id}
        onSelect={onChange?.bind(null, item)}
      />
    ))}
  </Clusterer>
)
