import React from 'react'
import cn from 'classnames'

import style from './style.module.scss'

type SectionProps = {
  title?: React.ReactNode
  description?: React.ReactNode
}

export const Section: React.FC<SectionProps> = ({
  title,
  description,
  children,
}) => (
  <div
    className={cn(
      'vc_row wpb_row vc_row-fluid vc_column-gap-24',
      style.container
    )}
  >
    <div className="container et-clearfix">
      <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
        <div className="vc_column-inner ">
          <div className="wpb_wrapper">
            {title && (
              <h4
                className={cn(
                  style.title,
                  'et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type'
                )}
              >
                <span className="text-wrapper">
                  <span className="text">{title}</span>
                </span>
              </h4>
            )}
            {description && (
              <p
                className={cn(
                  style.description,
                  'et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type'
                )}
                id="et-heading-629235"
              >
                <span className="text-wrapper">
                  <span className="text">{description}</span>
                </span>
              </p>
            )}
            <span className="et-gap et-clearfix et-gap-880736 hide768" />
            <span className="et-gap et-clearfix et-gap-200657 hide767" />
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
)
