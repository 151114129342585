import { requestsReducer } from 'redux-saga-requests'

import { SetQueryActionType, SelectGoodActionType } from './actions'
import { StateRecord, StateRecordType, SuggestionsCollection } from './records'

import {
  SET_QUERY,
  SET_CURRENT,
  CLEAR_SUGGESTIONS,
  FETCH_SUGGESTIONS,
} from './constants'

export const suggestionReducer = requestsReducer({
  actionType: FETCH_SUGGESTIONS,
  resetOn: [CLEAR_SUGGESTIONS],
  getDefaultData: () => StateRecord(),
  getData: ({ data }, action) => {
    const nextState = (data as StateRecordType).set(
      'suggestions',
      SuggestionsCollection(action.data)
    )

    return nextState
  },
  mutations: {
    [SET_QUERY]: {
      updateData({ data }, action) {
        const { meta } = action as SetQueryActionType
        return (data as StateRecordType).set('query', meta.query)
      },
      local: true,
    },
    [SET_CURRENT]: {
      updateData({ data }, action) {
        const { meta } = action as SelectGoodActionType
        return (data as StateRecordType).set('current', meta.current)
      },
      local: true,
    },
  },
})
