import React from 'react'

import { App } from '@monorepo/hooks'
import { Image } from '@monorepo/components/common'
import { Link } from 'App/Components'

type LogoProps = {
  logoImage: string
}

export const Logo: React.FC<LogoProps> = ({ logoImage }) => {
  const { company } = App.useCompany()

  return (
    <div id="header-logo-115569" className="hbe header-logo hbe-left">
      <Link to="/" title={company.name}>
        <Image
          className="logo"
          src={logoImage}
          alt={company.name}
          loader={false}
          lazy={false}
        />
        <Image
          className="sticky-logo"
          src={logoImage}
          alt={company.name}
          loader={false}
          lazy={false}
        />
      </Link>
    </div>
  )
}
