import { useClient } from '@monorepo/core/api_module'

import { IBrand, BrandIdType } from '@monorepo/interfaces'

export function useApi() {
  const client = useClient()

  async function fetchBrandInfo(id: BrandIdType) {
    const { data } = await client.get<IBrand>(`/v2/brands/${id}`)
    return data
  }

  return { fetchBrandInfo }
}
