import { useSelector } from 'react-redux'

import { tokenSelector } from '@monorepo/core/features/auth'

export const useBaseAuth = () => {
  const token = useSelector(tokenSelector)

  const isAuthenticated = Boolean(token)

  function login(login: string, password: string) {}
  function logout() {}

  return { isAuthenticated, login, logout }
}
