import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { get } from 'lodash'

import { MODULE } from './constants'
import { StateRecordType } from './records'

type AuthResponseType = {
  token: string
  refresh_token: string
}

export const stateSelector = getQuery<StateRecordType>({
  requestSelector: (state: any) => {
    const { data, ...rest } = get(state, MODULE, {})

    return {
      ...rest,
      data: data?.toJS(),
    }
  },
})

// export const stateSelector = createSelector(
//   getQuery<StateRecordType>({ type: AUTHENTICATE }),
//   ({ data, ...state }) => ({
//     data: (data || StateRecord()).toJS(),
//     ...state,
//   })
// )

export const tokenSelector = createSelector(
  stateSelector,
  ({ data }) => data.token
)

export const refreshTokenSelector = createSelector(
  stateSelector,
  ({ data }) => data.refreshToken
)

export const errorSelector = createSelector(
  stateSelector,
  (state) => state.error
)

export const switchUserSelector = createSelector(
  stateSelector,
  ({ data }) => data?.switchUser
)
