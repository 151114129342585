import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import { ContractType } from '@monorepo/interfaces'

import {
  FETCH_CONTRACTS,
  CLEAR_CONTRACTS,
  POPULATE_CONTRACTS,
  SET_CURRENT_CONTRACT,
} from './constants'
import { StateType } from './types'

export function populateContractsAction(
  items: Array<ContractType>
): RequestAction {
  return {
    type: POPULATE_CONTRACTS,
    request: false,
    meta: {
      items,
      resetOn: [CLEAR_CONTRACTS],
    },
  }
}

export function clearContractsAction(): Action {
  return {
    type: CLEAR_CONTRACTS,
  }
}

export function setCurrentContractAction(
  contract: ContractType
): RequestAction {
  return {
    type: SET_CURRENT_CONTRACT,
    request: {
      url: `/contracts/${contract.id}/select`,
    },
    meta: {
      asMutation: true,
      mutations: {
        [FETCH_CONTRACTS]: {
          updateData(state: StateType) {
            return state.data.map(item =>
              item.set('isCurrent', item.get('id') === contract.id)
            )
          },
        },
      },
    },
  }
}
