// import invariant from 'invariant'
import { Map } from 'immutable'
import { QueryState } from 'redux-saga-requests'
import { Record, RecordOf } from 'immutable'

import { OfferRecordsCollection, GoodRecord } from 'packages/core/records'
import { GoodType, OfferType } from '@monorepo/interfaces'

export interface DataType {
  requestParams?: {
    brandName: string
    number: string
    crosses?: boolean
  }
  loaded: boolean
  good?: GoodType
  offers: OfferType[]
  remote: string[]
  showState: { [key: string]: true | number }
  apiState: { [key: string]: 'loading' | 'loaded' }
}

interface DataRecordType
  extends Omit<DataType, 'good' | 'offers' | 'showState' | 'apiState'> {
  good?: GoodRecord
  offers: OfferRecordsCollection
  showState: Map<string, true | number>
  apiState: Map<string, 'loading' | 'loaded'>
}

export const DataRecord = Record<DataRecordType>({
  requestParams: undefined,
  loaded: false,
  good: undefined,
  offers: OfferRecordsCollection(),
  remote: [],
  showState: Map(),
  apiState: Map(),
})

export type DataRecord = RecordOf<DataRecordType>

export type StateRecord = QueryState<DataRecord>
export type StateType = QueryState<DataType>

// export function updateCar(
//   carsList: CarRecordsCollection,
//   carId: string | undefined,
//   changes: Partial<CarType>
// ): CarRecordsCollection {
//   invariant(!!carId, 'Car id is not specified')

//   const car = carsList.find(car => car.id === carId)

//   invariant(
//     car && carsList.includes(car),
//     `Car with id ${carId} is not found in cars list`
//   )

//   const idx = carsList.indexOf(car)

//   return carsList.update(idx, CarRecord(changes), car => car.merge(changes))
// }

// export function removeCar(
//   carsList: CarRecordsCollection,
//   carId: string | undefined
// ) {
//   const idx = carsList.findIndex(car => car.id === carId)

//   return idx === -1 ? carsList : carsList.remove(idx)
// }
