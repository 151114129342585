import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import cn from 'classnames'

import style from './style.module.scss'

type WarrantyPropsType = React.HTMLAttributes<HTMLDivElement> & {
  text: React.ReactNode
  tooltip: React.ReactNode
}

export const Warranty: React.FC<WarrantyPropsType> = ({
  text,
  tooltip,
  className,
}) => (
  <OverlayTrigger
    overlay={<Tooltip id={uniqueId('tooltip-')}>{tooltip}</Tooltip>}
  >
    <div
      className={cn(
        style.container,
        'd-flex jusyify-content-center align-items-center',
        className
      )}
    >
      <span className={cn(style.text, 'text-uppercase px-2 py-1')}>{text}</span>
    </div>
  </OverlayTrigger>
)
