import React from 'react'
import {
  createPath,
  parsePath,
  Location,
  LocationDescriptorObject,
} from 'history'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { isExternalLink } from '@monorepo/core/helpers'

type StateType = {
  scrollToTop?: boolean
  [key: string]: any
}
type HrefType = LocationDescriptorObject<StateType> | string

type LocalLinkProps = RouterLinkProps & {}
const LocalLink = ({ children, ...props }: LocalLinkProps) => {
  return <RouterLink {...props}>{children}</RouterLink>
}

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {}
const ExternalLink = ({ children, ...props }: ExternalLinkProps) => (
  <a {...props} target="_blank" rel="nofollow noopener">
    {children}
  </a>
)

type LinkProps = React.HTMLAttributes<HTMLElement> & {
  external?: boolean
  to: HrefType
  scrollToTop?: boolean
}

export const Link = ({ external, to, scrollToTop, ...props }: LinkProps) => {
  const location =
    typeof to === 'string' ? (parsePath(to) as Location<StateType>) : to

  const href = createPath(location)
  const isExternal = external ?? isExternalLink(href)

  if (!isExternal && typeof scrollToTop !== 'undefined') {
    location.state = { ...location.state, scrollToTop }
  }

  return isExternal ? (
    <ExternalLink href={href} />
  ) : (
    <LocalLink to={location} {...props} />
  )
}
